import React, { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import useWidgetHelper from "../../hooks/use-widget-helper";
import Style from "./AddNewWidgetModal.module.css";
import GeneralizedModal from "../../../../components/modal/GeneralizedModal";
import useCustomizableDashboardStateContext from "../../contexts/customizable-dashboard-state-context";

export const AddNewWidgetModal = () => {
  const { getAvailableWidgets } = useWidgetHelper();

  const {
    isAddWidgetModalVisible,
    setIsAddWidgetModalVisible,
    currentViewTabIndex,
    editingLayouts,
    setEditingLayouts
  } = useCustomizableDashboardStateContext();
  const [selectedWidget, setSelectedWidget] = useState(null);

  useEffect(() => {
    setSelectedWidget(null);
  }, [isAddWidgetModalVisible]);

  const widgetLists = useMemo(() => {
    const displayedWidgetIdList = (editingLayouts?.[currentViewTabIndex]?.layout || []).map(({ i }) => {
      return i;
    });

    return getAvailableWidgets().filter((eachWidget) => {
      return !displayedWidgetIdList.includes(eachWidget.id);
    });
  }, [editingLayouts, currentViewTabIndex]);

  return (
    <GeneralizedModal
      showModal={isAddWidgetModalVisible}
      title="Add New Widget"
      modalBodyComponent={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <div className={Style.modal_content}>
          <p className={Style.description_text}>Please select from the list below to add the widget into the view</p>
          <div className={Style.list_container}>
            {widgetLists.map((eachWidget) => {
              return (
                <button
                  key={eachWidget.id}
                  type="button"
                  className={`${Style.list_item} ${selectedWidget?.id === eachWidget.id && Style.list_item_selected}`}
                  onClick={() => {
                    setSelectedWidget(eachWidget);
                  }}
                >
                  {eachWidget.label}
                </button>
              );
            })}
          </div>
        </div>
      }
      modalFooterButtons={[
        {
          id: "cancel-button",
          title: "Cancel",
          onClick: () => {
            setIsAddWidgetModalVisible(false);
          }
        },
        {
          id: "submit-button",
          title: "Add",
          onClick: () => {
            const newEditingLayout = _.cloneDeep(editingLayouts);
            newEditingLayout[currentViewTabIndex].layout.push({
              ...selectedWidget.control,
              x: (newEditingLayout[currentViewTabIndex].layout.length * 2) % 10,
              y: Infinity // puts it at the bottom
            });
            setEditingLayouts(newEditingLayout);
            setIsAddWidgetModalVisible(false);
          },
          disabled: !selectedWidget,
          className: Style.add_button
        }
      ]}
    />
  );
};
