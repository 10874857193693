import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import TextInput from "../add-page-component/add-page-inputs/TextInput";
import DatePickerInput from "../add-page-component/add-page-inputs/DatePickerInput";
import TabListComponent from "../tab-list-component/TabListComponent";
import LoadingCircle from "../loading/LoadingCircle";
import Selector from "./Selector";
import InfoCard from "./InfoCard";
import Style from "./AddInventoryV2Components.module.css";

const useTextInputStyle = makeStyles({
  root: {
    width: ({ width }) => {
      return width || "73px";
    },
    height: "42.4px",
    border: "1.5px solid #E2E2EA",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF"
  },
  focused: {
    border: "1.5px solid #258FE0",
    borderRadius: "3px"
  }
});

export const PrintContent = ({
  isPrintEnabled,
  singlePrint,
  quantity,
  onChangeQuantity,
  printers,
  selectedPrinter,
  onSelectPrinter,
  contentLoading,
  printerError,
  inputStyle,
  disableQuantity,
  printerLabel
}) => {
  return (
    <>
      {!singlePrint && (
        <>
          <p className={Style.info_content_label}>
            Number of Tags
            <span className={Style.asterisk}> *</span>
          </p>
          <div className={Style.text_input_container}>
            <TextInput
              id="qty"
              numberOnly
              placeholder="--"
              value={quantity}
              disabled={disableQuantity}
              variant="standard"
              InputProps={{ classes: inputStyle, disableUnderline: true }}
              inputProps={{
                style: {
                  padding: 10
                }
              }}
              onChange={(_, value) => {
                onChangeQuantity(parseInt(value));
              }}
            />
          </div>
        </>
      )}
      {isPrintEnabled && (
        <>
          <p className={Style.info_content_label}>
            {printerLabel}
            <span className={Style.asterisk}> *</span>
          </p>
          <div className={Style.dropdown_input_container}>
            <Selector
              disableTabFocus
              options={printers}
              onSelect={(printer) => {
                onSelectPrinter(printer);
              }}
              value={selectedPrinter?.name}
              placeholder="Select a printer"
              error={printerError}
            />
          </div>
          <div className={Style.info_card}>
            {contentLoading ? (
              <LoadingCircle
                shouldCenter
                isSmall
              />
            ) : (
              <InfoCard info={selectedPrinter} />
            )}
          </div>
        </>
      )}
    </>
  );
};

const AddContent = ({
  stations,
  selectedStation,
  onSelectStation,
  tag,
  onChangeTag,
  inputStyle,
  onScanTag,
  isTagInputEditable,
  contentLoading,
  loadingMessage,
  setLoadingMessage
}) => {
  return (
    <>
      <p className={Style.info_content_label}>Station *</p>
      <div className={Style.dropdown_input_container}>
        <Selector
          options={stations}
          onSelect={(station) => {
            if (!station) {
              onSelectStation({});
              return;
            }
            onSelectStation(station);
          }}
          value={Object.keys(selectedStation || {}).length ? selectedStation : undefined}
          placeholder="Select a reader"
        />
      </div>
      <p className={Style.info_content_label}>Tag *</p>
      <div className={Style.add_content}>
        <div className={Style.text_input_container}>
          <TextInput
            id="tag"
            placeholder="RFID Tag #"
            value={tag}
            variant="standard"
            disabled={!isTagInputEditable}
            InputProps={{ classes: inputStyle, disableUnderline: true }}
            inputProps={{
              style: {
                padding: 10
              }
            }}
            onChange={(_, value) => {
              onChangeTag(value);
            }}
          />
        </div>
        <div
          className={Style.scan_button}
          disabled={contentLoading || !stations.length}
          onClick={() => {
            setLoadingMessage("Scanning for RFID Tag...");
            onScanTag();
          }}
        >
          Scan
        </div>
      </div>
      {contentLoading && (
        <div>
          <LoadingCircle
            shouldCenter
            isSmall
            message={loadingMessage}
            messageStyle="loading_message"
          />
        </div>
      )}
    </>
  );
};

const AdditionalInformation = ({
  isPrintEnabled,
  singlePrint,
  enableOnboardingToLocation,
  onboardingToLocationRequired,
  hideSelectOnboardingLocationInput,
  locations,
  selectedLocation,
  onSelectLocation,
  onboardingModeTabs,
  selectedOnboardingMode,
  quantity,
  onChangeQuantity,
  isQuantityDisabled,
  onItemPropertyChange,
  itemProperties,
  stations,
  selectedStation,
  onSelectStation,
  tag,
  onChangeTag,
  onScanTag,
  isTagInputEditable,
  printers,
  selectedPrinter,
  onSelectPrinter,
  contentLoading,
  printerError
}) => {
  const qtyInputStyle = useTextInputStyle();
  const inputStyle = useTextInputStyle({ width: "235px" });
  const tagInputStyle = useTextInputStyle({ width: "270px" });

  const [loadingMessage, setLoadingMessage] = useState("");

  const showLocationSelector =
    enableOnboardingToLocation && selectedOnboardingMode === "print" && !hideSelectOnboardingLocationInput;

  return (
    <div className={Style.additional_information_container}>
      <p className={Style.info_content_title}>Additional Information</p>
      <div>
        {showLocationSelector && (
          <div className={Style.location_selector_container}>
            <p className={Style.info_content_label}>
              Location
              {onboardingToLocationRequired && <span className={Style.asterisk}> *</span>}
            </p>
            <Selector
              options={locations}
              onSelect={(location) => {
                onSelectLocation(location);
              }}
              value={Object.keys(selectedLocation || {}).length ? selectedLocation : undefined}
              placeholder="Select a location"
            />
          </div>
        )}
        <div className={Style.main_container}>
          {onboardingModeTabs.length > 1 && (
            <div className={Style.left_container}>
              <TabListComponent
                tabStructure={onboardingModeTabs}
                focusedTab={selectedOnboardingMode}
                tabStyle={Style.tab_style}
                tabListStyle={Style.tab_list_style}
                focusedTabStyle={Style.focused_tab_style}
              />
            </div>
          )}
          <div className={Style.right_container}>
            {!contentLoading &&
              Object.keys(itemProperties).map((id) => {
                const { label, value, type } = itemProperties[id];
                let ComponentToRender;
                switch (type) {
                  case "date":
                  case "datepicker":
                    ComponentToRender = DatePickerInput;
                    break;
                  default:
                    ComponentToRender = TextInput;
                    break;
                }
                return (
                  <div key={id}>
                    <p className={Style.info_content_label}>{label}</p>
                    <div className={Style.text_input_container}>
                      <ComponentToRender
                        id={id}
                        placeholder="--"
                        value={value}
                        variant="standard"
                        inputVariant="standard"
                        InputProps={{ classes: inputStyle, disableUnderline: true }}
                        inputProps={{
                          style: {
                            padding: 10
                          }
                        }}
                        onChange={(_, incomingValue) => {
                          onItemPropertyChange(id, incomingValue);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            {selectedOnboardingMode === "print" ? (
              <PrintContent
                isPrintEnabled={isPrintEnabled}
                singlePrint={singlePrint}
                quantity={quantity}
                onChangeQuantity={onChangeQuantity}
                printers={printers}
                selectedPrinter={selectedPrinter}
                onSelectPrinter={onSelectPrinter}
                contentLoading={contentLoading}
                printerError={printerError}
                inputStyle={qtyInputStyle}
                disableQuantity={isQuantityDisabled}
              />
            ) : (
              <AddContent
                stations={stations}
                selectedStation={selectedStation}
                onSelectStation={onSelectStation}
                tag={tag}
                onChangeTag={onChangeTag}
                inputStyle={tagInputStyle}
                onScanTag={onScanTag}
                isTagInputEditable={isTagInputEditable}
                contentLoading={contentLoading}
                loadingMessage={loadingMessage}
                setLoadingMessage={setLoadingMessage}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AdditionalInformation.defaultProps = {
  isPrintEnabled: false,
  isQuantityDisabled: false,
  onItemPropertyChange: () => {},
  itemProperties: {},
  enableOnboardingToLocation: false,
  onboardingToLocationRequired: false,
  hideSelectOnboardingLocationInput: false,
  selectedLocation: {},
  selectedOnboardingMode: {},
  quantity: 0,
  selectedStation: {},
  tag: "",
  selectedPrinter: {},
  contentLoading: false,
  singlePrint: false,
  printerError: false
};

AdditionalInformation.propTypes = {
  isPrintEnabled: PropTypes.bool,
  singlePrint: PropTypes.bool,
  isQuantityDisabled: PropTypes.bool,
  onItemPropertyChange: PropTypes.func,
  itemProperties: PropTypes.shape({}),
  enableOnboardingToLocation: PropTypes.bool,
  onboardingToLocationRequired: PropTypes.bool,
  hideSelectOnboardingLocationInput: PropTypes.bool,
  locations: PropTypes.array.isRequired,
  selectedLocation: PropTypes.object,
  onSelectLocation: PropTypes.func.isRequired,
  onboardingModeTabs: PropTypes.array.isRequired,
  selectedOnboardingMode: PropTypes.object,
  quantity: PropTypes.number,
  onChangeQuantity: PropTypes.func.isRequired,
  stations: PropTypes.array.isRequired,
  selectedStation: PropTypes.object,
  onSelectStation: PropTypes.func.isRequired,
  tag: PropTypes.string,
  onChangeTag: PropTypes.func.isRequired,
  onScanTag: PropTypes.func.isRequired,
  isTagInputEditable: PropTypes.func.isRequired,
  printers: PropTypes.array.isRequired,
  selectedPrinter: PropTypes.object,
  onSelectPrinter: PropTypes.func.isRequired,
  contentLoading: PropTypes.bool,
  printerError: PropTypes.bool
};

PrintContent.defaultProps = {
  isPrintEnabled: false,
  singlePrint: false,
  quantity: 0,
  onChangeQuantity: () => {},
  printers: [],
  selectedPrinter: {},
  onSelectPrinter: () => {},
  contentLoading: true,
  printerError: false,
  inputStyle: {},
  disableQuantity: false,
  printerLabel: "Printer"
};

PrintContent.propTypes = {
  isPrintEnabled: PropTypes.bool,
  singlePrint: PropTypes.bool,
  quantity: PropTypes.number,
  onChangeQuantity: PropTypes.func,
  printers: PropTypes.array,
  selectedPrinter: PropTypes.object,
  onSelectPrinter: PropTypes.func,
  contentLoading: PropTypes.bool,
  printerError: PropTypes.bool,
  inputStyle: PropTypes.object,
  disableQuantity: PropTypes.bool,
  printerLabel: PropTypes.string
};

export default AdditionalInformation;
