import React, { useState, useEffect, useMemo } from "react";
import queryString from "query-string";
import { useXemelgoClient } from "../../services/xemelgo-service";
import usePackageDetailConfigContext, {
  PackageDetailConfigContextProvider
} from "./contexts/package-detail-config-context";
import { getLogo } from "../../common/Utilities";
import { getBase64ImageFromS3 } from "../../utils/get-base-64-image-from-s3/getBase64ImageFromS3";
import {
  getIsTrackingSessionEnded,
  getLocationHistory,
  getDetailScreenData,
  getStatusList
} from "./helpers/PackageDetailFeatureHelper";
import Style from "./PackageDetailFeature.module.css";
import { ReactComponent as AssetIcon } from "../../assets/icons/asset.svg";
import { Modal } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import SearchDropdown from "../../components/SearchDropdown/SearchDropdown";
import { multipleSortComparison } from "../../utils";
import DetailScreen from "../../components/DetailScreen";
import HistoryTable from "./components/history-table";
import xemelgoStyle from "../../styles/variable";

export const PackageDetailFeature = () => {
  const [fullItemData, setFullItemData] = useState(null);
  const [locationTreeMap, setLocationTreeMap] = useState({});
  const [itemClient] = useState(useXemelgoClient().getItemClient());
  const [locationClient] = useState(useXemelgoClient().getLocationClient());
  const [publishClient] = useState(useXemelgoClient().getPublishClient());
  const [images, setImages] = useState([]);
  const [locationToAdd, setLocationToAdd] = useState({});
  const [loading, setLoading] = useState(true);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    isLoading: isConfigLoading,
    imageAttributes,
    imageBucket,
    itemAttributes,
    locationCategories = [],
    detailCard,
    locationIdentifierToStatusColorMap,
    locationHistoryTable,
    recipientProperty
  } = usePackageDetailConfigContext();

  const isTrackingSessionEnded = useMemo(() => {
    return getIsTrackingSessionEnded(fullItemData);
  }, [fullItemData]);

  const locationHistory = useMemo(() => {
    if (fullItemData) {
      return getLocationHistory(fullItemData, fullItemData[recipientProperty]);
    }
    return [];
  }, [fullItemData]);

  const detailScreenData = useMemo(() => {
    const { attributes } = detailCard;

    if (fullItemData) {
      return getDetailScreenData(fullItemData, attributes, locationCategories, isTrackingSessionEnded);
    }

    return {};
  }, [detailCard, fullItemData, isTrackingSessionEnded]);

  const statusList = useMemo(() => {
    return getStatusList(fullItemData, locationTreeMap, locationIdentifierToStatusColorMap, isTrackingSessionEnded);
  }, [locationIdentifierToStatusColorMap, locationTreeMap, fullItemData, isTrackingSessionEnded]);

  const locations = useMemo(() => {
    const associatedWithSession =
      fullItemData?.["associatedWithSession#edge"]?.[0]?.["associatedWithSession#node"] || [];
    const { id: lastDetectedLocationId = "" } =
      associatedWithSession?.[0]?.includesEvent?.[isTrackingSessionEnded ? 2 : 0]?.detectedAt?.[0] || {};
    const filteredLocations = Object.values(locationTreeMap)
      .filter((location) => locationCategories.includes(location.category) && location.id !== lastDetectedLocationId)
      .sort(multipleSortComparison([{ id: "name" }]));
    let newLocations = [];
    filteredLocations.map((each) => {
      return newLocations.push({
        key: each.id,
        label: each.name,
        value: each.id
      });
    });
    return newLocations;
  }, [locationTreeMap, locationCategories, fullItemData]);

  const onLoad = async (itemId, attributesToQuery) => {
    const [newItemData, newLocationTreeMap] = await Promise.all([
      itemClient.getItemDetailWithTrackingSessionEvent(itemId, attributesToQuery),
      locationClient.getLocationTree(["identifier"])
    ]);
    const logo = await getLogo();
    setLocationTreeMap(newLocationTreeMap);
    setFullItemData(newItemData);
    setImages([logo]);
    setLoading(false);
  };

  const fetchImages = async () => {
    try {
      const imagePromises = [];
      let filePaths = imageAttributes.reduce((accumulator, attribute) => {
        if (fullItemData[attribute]?.length) {
          accumulator.push(fullItemData[attribute]);
        }
        return accumulator;
      }, []);

      filePaths = filePaths.flat().sort().reverse();
      filePaths.forEach((key) => {
        imagePromises.push(getBase64ImageFromS3(key, imageBucket));
      });
      if (imagePromises.length) {
        const newImages = await Promise.all(imagePromises);
        setImages(newImages);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (!isConfigLoading && fullItemData) {
      fetchImages();
    }
  }, [isConfigLoading, imageAttributes, imageBucket, fullItemData]);

  useEffect(() => {
    if (window.location.search && !isConfigLoading) {
      const values = queryString.parse(window.location.search);
      const { itemId } = values;
      onLoad(itemId, itemAttributes);
    }
  }, [window.location.search, isConfigLoading]);

  const addLocation = async () => {
    const associatedWithSession =
      fullItemData?.["associatedWithSession#edge"]?.[0]?.["associatedWithSession#node"]?.[0] || {};
    await publishClient.userEvent([associatedWithSession.id], locationToAdd.value);
    const values = queryString.parse(window.location.search);
    const { itemId } = values;
    setIsSubmitting(false);
    setShowLocationModal(false);
    setLocationToAdd({});
    setLoading(true);
    onLoad(itemId, itemAttributes);
  };

  return (
    <>
      <DetailScreen
        title="Packages"
        borderColor={xemelgoStyle.theme.APP_BLUE}
        iconBorderColor={xemelgoStyle.theme.APP_LIGHTBLUE}
        data={detailScreenData}
        TitleIconComponent={() => {
          return <AssetIcon color={xemelgoStyle.theme.APP_BLUE} />;
        }}
        isLoading={isConfigLoading || loading}
        disableEdit
        images={images}
        statusList={statusList}
        BottomButtonComponent={() => {
          return (
            !isTrackingSessionEnded && (
              <div className={Style.location_button_container}>
                <button
                  type="submit"
                  className={Style.location_button}
                  onClick={() => {
                    setShowLocationModal(true);
                  }}
                >
                  Update Status
                </button>
              </div>
            )
          );
        }}
      >
        {!(isConfigLoading || loading) && (
          <HistoryTable
            headers={locationHistoryTable.attributes}
            data={locationHistory}
          />
        )}
      </DetailScreen>
      <Modal
        show={showLocationModal}
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputLabel>Select a location</InputLabel>
          <SearchDropdown
            selectedItem={locationToAdd || {}}
            showIcon
            options={locations || []}
            onItemSelected={(event) => {
              setLocationToAdd(event);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={Style.cancel_button}
            onClick={() => {
              setLocationToAdd({});
              setShowLocationModal(false);
            }}
          >
            Cancel
          </button>
          <button
            disabled={Object.keys(locationToAdd).length === 0 || isSubmitting}
            type="button"
            className={Style.save_button}
            onClick={() => {
              setIsSubmitting(true);
              addLocation();
            }}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default () => {
  return (
    <PackageDetailConfigContextProvider>
      <PackageDetailFeature />
    </PackageDetailConfigContextProvider>
  );
};
