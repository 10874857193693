export const CUSTOMIZABLE_DASHBOARD_EVENT = "Customizable Dashboard";

export const CUSTOMIZABLE_DASHBOARD_STEPS = {
  ENTRY: "Entry",
  DATA_PRE_FETCH_SUCCESS: "Data Pre-Fetch Success",
  DATA_FETCHING_FAILURE: "Data Fetching Failure",
  USER_PREFERENCE_FETCH_SUCCESS: "User Preference Fetching Success",
  USER_PREFERENCE_FETCH_FAILURE: "User Preference Fetching Failure",
  USER_PREFERENCE_UPDATE_SUCCESS: "User Preference Update Success",
  USER_PREFERENCE_UPDATE_FAILURE: "User Preference Update Failure"
};
