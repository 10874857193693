import React, { useState } from 'react';
import Style from './SummaryCardComponent.module.css';

const SummaryCardComponent = (props) => {
  const [jsonData] = useState(props.payload);

  const ReportName = (props) => {
    return <>
      <div className={Style.label_text}>Report Name:</div>
      <div className={Style.value_text}>{props.reportName}</div>
    </>
  };

  const SelectFields = (props) => {
    if (props.fields?.length === 0) {
      return <></>
    }
    return <>
      <div className={Style.label_text}>Selected Fields:</div>
      <div>
        {props.fields && props.fields.map((field, index) => {
          return <div className={Style.value_text} key={index}>{field.alias}</div>
        })}
      </div>
    </>
  };

  const FilterFields = (props) => {
    if (props.filters?.length === 0) {
      return <></>
    }
    return <>
      <div className={Style.label_text}>Filters:</div>
      <div>
        {props.filters && props.filters.map((filter, index) => {
          return <div className={Style.value_text} key={index}>{filter.field} {filter.comparisonType} {filter.filterValue}</div>
        }
        )}
      </div>
    </>
  };

  const AggregatedFields = (props) => {
    if (props.aggregation_options?.length === 0) {
      return <></>
    }
    return <>
      <div className={Style.label_text}>Aggregated Fields:</div>
      <div>
        {props.aggregation_options && props.aggregation_options.map((aggregation, index) => {
          return <div className={Style.value_text} key={index}>{aggregation.operation}({aggregation.field}) as {aggregation.alias}</div>
        })}
      </div>
    </>
  };

  const GroupByFields = (props) => {
    if (props.group_by_options?.length === 0) {
      return <></>
    }
    return <>
      <div className={Style.label_text}>Group By:</div>
      <div>
        {props.group_by_options && props.group_by_options.map((group_by, index) => {
          return <div className={Style.value_text} key={index}>{group_by.field}</div>
        })}
      </div>
    </>
  };

  const SortFields = (props) => {
    if (props.sort_options?.length === 0) {
      return <></>
    }
    return <>
      <div className={Style.label_text}>Sort By:</div>
      <div>
        {props.sort_options && props.sort_options.map((sort, index) => {
          return <div className={Style.value_text} key={index}>{sort.field} {sort.direction}</div>
        })}
      </div>
    </>
  };

  const LimitFields = (props) => {
    return <>
      <div className={Style.label_text}>Limit:</div>
      <div className={Style.value_text}>{props.limit}</div>
    </>
  };

  return (
    <div >
      <h3>Summary</h3>
      <ul>
        {jsonData.summary &&
          <div className={Style.summary_card}>
            <ReportName reportName={jsonData.reportName} />
            <SelectFields fields={jsonData.summary.fields} />
            <FilterFields filters={jsonData.summary.filters} />
            <AggregatedFields aggregation_options={jsonData.summary.aggregation_options} />
            <GroupByFields group_by_options={jsonData.summary.group_by_options} />
            <SortFields sort_options={jsonData.summary.sort_options} />
            <LimitFields limit={jsonData.summary.limit} />
          </div>
        }
      </ul>
    </div>
  );
};

export default SummaryCardComponent;