import React, { useState, useEffect, useMemo, useCallback, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";
import Style from "./DetailScreen.module.css";
import Skeleton from "react-loading-skeleton";
import ChartSwiper from "../chartSwiper";
import { SwiperSlide } from "swiper/react";
import { getItemFromS3URI, getFormattedDate, convertMillisecondsToUnitsOfTime } from "common/Utilities";
import "react-loading-skeleton/dist/skeleton.css";
import EditButton from "components/EditButton/EditButton";
import ImageUploadAndDisplayComponent from "components/image-upload-and-display-component/ImageUploadAndDisplayComponent";
import xemelgoStyle from "styles/variable";

const Tab = ({ pathname, title, active }) => {
  return (
    <Link
      className={`${Style.tab_link} ${active && Style.tab_link_active}`}
      to={(location) => {
        return { pathname, search: location.search };
      }}
      replace
    >
      {title}
    </Link>
  );
};

const DetailScreen = ({
  children,
  borderColor,
  iconBorderColor,
  tabs,
  data,
  title,
  TitleIconComponent,
  onEditSave,
  images,
  imageFileToUpload,
  setImageFileToUploadFn,
  isLoading,
  DropdownComponent,
  disableEdit,
  statusList,
  BottomButtonComponent
}) => {
  const location = useLocation();
  const [isEditMode, setIsEditMode] = useState(false);
  const [dataFields, setDataFields] = useState({});

  useEffect(() => {
    setDataFields({ ...data });
  }, [data, isEditMode]);

  const renderImage = useCallback(() => {
    return images.length > 1 ? (
      <ChartSwiper containerClassName={Style.swiper_container}>
        {images.map((image, index) => {
          return (
            <SwiperSlide
              key={index}
              className={Style.image_slide}
            >
              <div className={Style.image_container}>
                <img
                  loading="eager"
                  className={`${Style.detail_image}`}
                  alt="Can't load the image"
                  src={image}
                />
              </div>
            </SwiperSlide>
          );
        })}
      </ChartSwiper>
    ) : (
      <img
        loading="eager"
        className={`${Style.detail_image}`}
        alt="Can't load the image"
        src={images[0]}
      />
    );
  }, [images]);

  const renderStatus = useCallback(() => {
    if (statusList.length > 0) {
      return (
        <div className={Style.status_group}>
          {statusList.map((status, index) => {
            return (
              <div
                key={index}
                style={{ backgroundColor: status.color || xemelgoStyle.theme.APP_BLUE }}
                className={Style.status}
              >
                <p className={Style.status_text}>{status.label}</p>
              </div>
            );
          })}
        </div>
      );
    }
  }, [statusList]);

  const renderPrimaryFields = (attributeKey, attributeData) => {
    return (
      <Fragment key={attributeKey}>
        <p className={`${Style.primary_label}`}>{`${attributeData.label}:`}</p>
        <div className={`${Style.primary_value_container}`}>
          <AutoSizeTextArea
            maxRows={2}
            numberOnly={attributeData.type === "number"}
            readOnly={!attributeData.editable || !isEditMode || attributeData.type === "date"}
            value={
              isEditMode || attributeData.value
                ? attributeData.type !== "date"
                  ? attributeData.value
                  : getFormattedDate(attributeData.value)
                : "-"
            }
            onChangeText={(newText) => {
              setDataFields({
                ...dataFields,
                [attributeKey]: { ...attributeData, value: newText }
              });
            }}
          />
        </div>
      </Fragment>
    );
  };

  const renderLink = (value, linkText = "Link") => {
    if (!value) {
      return "-";
    }

    const url = new URL(value);
    const { protocol = "" } = url;
    if (protocol.toLowerCase().includes("s3")) {
      return (
        <p
          onClick={async () => {
            await getItemFromS3URI(value);
          }}
          className={Style.link}
        >
          {linkText}
        </p>
      );
    } else {
      return (
        <a href={value}>
          <p className={Style.link}>{linkText}</p>
        </a>
      );
    }
  };

  const renderFields = (attributeKey, attributeData) => {
    let value = attributeData.value || "-";
    if (attributeData.type === "date") {
      value = getFormattedDate(attributeData.value);
    } else if (attributeData.value && attributeData.convertFromMilliseconds) {
      value = isEditMode
        ? convertMillisecondsToUnitsOfTime(attributeData.value).replace(/\D/g, "")
        : convertMillisecondsToUnitsOfTime(attributeData.value);
    }
    return (
      <Fragment key={attributeKey}>
        <p className={`${Style.normal_label}`}>{`${attributeData.label}:`}</p>
        <div className={`${Style.normal_value_container}`}>
          {attributeData.type == "link" ? (
            renderLink(attributeData.value, attributeData.linkText)
          ) : (
            <>
              <AutoSizeTextArea
                numberOnly={attributeData.type === "number"}
                maxRows={2}
                readOnly={!attributeData.editable || !isEditMode || attributeData.type === "date"}
                value={value}
                onChangeText={(newText) => {
                  setDataFields({
                    ...dataFields,
                    [attributeKey]: { ...attributeData, value: newText }
                  });
                }}
              />
              <div className={Style.sub_text}>{attributeData.subText}</div>
            </>
          )}
        </div>
      </Fragment>
    );
  };

  const TabsList = useMemo(() => {
    if (tabs?.length) {
      return () => {
        return (
          <div className={`${Style.tabs} ${Style.flex_row}`}>
            {tabs.map(({ pathname, title }) => {
              return (
                <Tab
                  key={pathname}
                  pathname={pathname}
                  title={title}
                  active={location.pathname === pathname}
                />
              );
            })}
            <span className={`${Style.tabs_empty_space}`} />
          </div>
        );
      };
    }
    return null;
  }, [location]);

  const onImageAdd = (imageList) => {
    setImageFileToUploadFn(imageList);
  };

  const ImageDisplay = () => {
    return isEditMode ? (
      <ImageUploadAndDisplayComponent
        imageFileToUpload={imageFileToUpload}
        onChange={onImageAdd}
        maxNumberOfFiles={1}
        acceptedFileTypes={["jpg", "png", "jpeg"]}
      />
    ) : (
      renderImage()
    );
  };

  return (
    <div
      className={`${Style.grid} ${Style.page_frame}`}
      style={{
        borderTop: `12px solid ${borderColor}`
      }}
    >
      <div className={Style.title_container}>
        <div
          className={Style.title_icon}
          style={{ color: iconBorderColor }}
        >
          <TitleIconComponent />
        </div>
        <p className={Style.title}>{title}</p>
      </div>
      <div className={`${Style.page_content_container} ${Style.grid}`}>
        <div className={`${Style.left_content_container} ${Style.grid}`}>
          {!disableEdit && (
            <div className={`${Style.edit_button_container} ${Style.flex_row}`}>
              <EditButton
                show={!isLoading}
                editMode={isEditMode}
                onEditClick={() => {
                  setIsEditMode(true);
                }}
                onDiscardClick={() => {
                  setDataFields({ ...data });
                  setImageFileToUploadFn(null);
                  setIsEditMode(false);
                }}
                onSaveClick={() => {
                  setDataFields({ ...data });
                  onEditSave(dataFields);
                  setIsEditMode(false);
                }}
              />
            </div>
          )}
          {isLoading ? (
            <div
              className={Style.detail_image}
              style={{ minHeight: 250, position: "relative" }}
            >
              <Skeleton className={Style.image_loading} />
            </div>
          ) : (
            <>
              <ImageDisplay />
              {renderStatus()}
            </>
          )}
          <div className={`${Style.primary_fields} ${Style.grid}`}>
            {isLoading ? (
              <div className={`${Style.primary_loading}`}>
                <Skeleton height={28} />
              </div>
            ) : (
              <>
                {Object.keys(dataFields)
                  .filter((eachKey) => {
                    return dataFields[eachKey].primary;
                  })
                  .sort((aKey, bKey) => {
                    return dataFields[aKey].index - dataFields[bKey].index;
                  })
                  .map((eachKey) => {
                    return renderPrimaryFields(eachKey, dataFields[eachKey]);
                  })}
                {DropdownComponent && !isEditMode && (
                  <div className={`${Style.dropdown_container} ${Style.flex_row}`}>
                    <DropdownComponent />
                  </div>
                )}
              </>
            )}
          </div>
          <div className={`${Style.normal_fields} ${Style.grid}`}>
            {isLoading ? (
              <div className={`${Style.normal_loading}`}>
                <Skeleton
                  height={28}
                  count={3}
                />
              </div>
            ) : (
              Object.keys(dataFields)
                .filter((eachKey) => {
                  return !dataFields[eachKey].primary;
                })
                .sort((aKey, bKey) => {
                  return dataFields[aKey].index - dataFields[bKey].index;
                })
                .map((eachKey) => {
                  return renderFields(eachKey, dataFields[eachKey]);
                })
            )}
          </div>
          {!isLoading && BottomButtonComponent && <BottomButtonComponent />}
        </div>
        <div className={`${Style.right_content_container} ${Style.grid}`}>
          {TabsList && <TabsList />}
          <div className={`${Style.tab_content}`}>
            <div style={{ height: "100%" }}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

DetailScreen.defaultProps = {
  borderColor: xemelgoStyle.theme.XEMELGO_BLUE,
  iconBorderColor: xemelgoStyle.theme.XEMELGO_LIGHTBLUE,
  tabs: [],
  images: [],
  statusList: [],
  statusList: []
};

DetailScreen.propTypes = {
  children: PropTypes.any,
  borderColor: PropTypes.string,
  iconBorderColor: PropTypes.string,
  tabs: PropTypes.array,
  data: PropTypes.object,
  title: PropTypes.string,
  TitleIconComponent: PropTypes.any,
  onEditSave: PropTypes.func,
  images: PropTypes.array,
  imageFileToUpload: PropTypes.array,
  setImageFileToUploadFn: PropTypes.func,
  isLoading: PropTypes.bool,
  DropdownComponent: PropTypes.any,
  disableEdit: PropTypes.bool,
  statusList: PropTypes.array,
  BottomButtonComponent: PropTypes.any
};

export default DetailScreen;
