import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import useCustomizableDashboardDataSourceContext from "../customizable-dashboard-data-source-context";

const CustomizableDashboardStateContext = React.createContext();

const initialState = {
  isEditMode: false,
  currentViewTabIndex: 0,
  editingLayouts: [],
  isAddWidgetModalVisible: false
};

export const useCustomizableDashboardStateContext = () => {
  return useContext(CustomizableDashboardStateContext);
};

export const CustomizableDashboardStateContextProvider = ({ children }) => {
  const { storedLayouts } = useCustomizableDashboardDataSourceContext();
  const [isEditMode, setIsEditMode] = useState(initialState.isEditMode);
  const [currentViewTabIndex, setCurrentViewTabIndex] = useState(initialState.currentViewTabIndex);
  const [editingLayouts, setEditingLayouts] = useState(initialState.editingLayouts);
  const [isAddWidgetModalVisible, setIsAddWidgetModalVisible] = useState(initialState.isAddWidgetModalVisible);

  useEffect(() => {
    if (!isEditMode) {
      const newEditingLayout = _.cloneDeep(storedLayouts);
      setEditingLayouts(newEditingLayout);
    }
  }, [storedLayouts, isEditMode]);

  return (
    <CustomizableDashboardStateContext.Provider
      value={{
        isEditMode,
        setIsEditMode,
        currentViewTabIndex,
        setCurrentViewTabIndex,
        editingLayouts,
        setEditingLayouts,
        isAddWidgetModalVisible,
        setIsAddWidgetModalVisible
      }}
    >
      {children}
    </CustomizableDashboardStateContext.Provider>
  );
};
