import React, { useState, useEffect } from "react";
import TrackPageComponent from "../../components/TrackPageComponents/track-page-component/TrackPageComponentV2";
import LoadingCircle from "../../components/loading/LoadingCircle";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { getItemFromS3URI, pluralizeWord, convertMillisecondsToUnitsOfTime } from "../../common/Utilities";
import xemelgoStyle from "../../styles/variable";
import { ReactComponent as ItemTypesMangementIcon } from "../../assets/icons/item-types-management.svg";
import ItemTypeManagementStyle from "./ItemTypesManagement.module.css";

const APP_ID = "itemTypesManagement";
const TRACK_PAGE_CONFIG = "trackPage";
const mainColor = xemelgoStyle.theme.XEMELGO_BLUE;

const ItemTypesManagementTrackPageFeature = ({ history }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const TrackPageConfig = configProvider.getValue(TRACK_PAGE_CONFIG, "object");

  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [listViewStructureList, setListViewStructureList] = useState([]);
  const [itemTypeLabel, setItemTypeLabel] = useState("Item Type");
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(true);

  const renderLink = (value, linkText) => {
    if (value == "-") {
      return value;
    }

    const url = new URL(value);
    const { protocol = "" } = url;
    if (protocol.toLowerCase().includes("s3")) {
      return (
        <p
          onClick={async () => {
            setLoading(true);
            await getItemFromS3URI(value);
            setLoading(false);
          }}
          className={ItemTypeManagementStyle.link}
        >
          {linkText}
        </p>
      );
    } else {
      return (
        <a
          href={value}
          className={ItemTypeManagementStyle.link}
        >
          {linkText}
        </a>
      );
    }
  };

  const onLoad = async () => {
    const {
      listViewConfig = {},
      itemTypeAttributes = [],
      itemTypeLabel: newItemTypeLabel = "Item Type"
    } = TrackPageConfig || {};
    const newListViewStructure = Object.keys(listViewConfig)
      .sort((a, b) => {
        return listViewConfig[a].index - listViewConfig[b].index;
      })
      .map((eachKey) => {
        const { label, title, index, default: defaultSort, type, linkText = "Link" } = listViewConfig[eachKey];
        let renderComponent;
        switch (type) {
          case "link":
            renderComponent = (value) => {
              return renderLink(value, linkText);
            };
            break;
          default:
            break;
        }
        return { id: eachKey, type, title, default: defaultSort, index, label, renderComponent };
      });

    try {
      const itemTypesResult = await itemTypeClient.listItemTypes(itemTypeAttributes);

      // set undefined attributes to be null for rendering '-' on ListView
      const processItemTypesResult = itemTypesResult.map((eachItemType) => {
        // searchString is used for filter, it contains all values showing in each row separate by space
        const ret = { ...eachItemType, searchString: "" };
        Object.keys(listViewConfig).forEach((eachKey) => {
          let currentValue = eachItemType[eachKey] || eachItemType.customFields[eachKey] || null;
          if (currentValue && listViewConfig[eachKey].convertFromMilliseconds) {
            currentValue = convertMillisecondsToUnitsOfTime(currentValue);
          }
          ret[eachKey] = currentValue;

          // adding value to searchString
          if (currentValue) {
            ret.searchString += `${currentValue}`;
          }
        });
        return ret;
      });

      setDataList([
        {
          id: "item-type-management",
          metricsList: [],
          subHeaderList: [],
          hide: false,
          dataList: processItemTypesResult
        }
      ]);
    } catch (error) {
      setDataList([]);
    }
    setItemTypeLabel(newItemTypeLabel);
    setListViewStructureList(newListViewStructure);
    setLoading(false);
  };

  useEffect(() => {
    onLoad();
  }, []);

  const listViewFilterFunc = (inputValue, eachData) => {
    if (!inputValue) {
      return true;
    }
    const keywords = inputValue.toLowerCase().trim().split(" ");
    let matched = true;

    keywords.forEach((eachKeyword) => {
      if (!eachData.searchString.toLowerCase().includes(eachKeyword)) {
        matched = false;
      }
    });
    return matched;
  };

  if (loading) {
    return <LoadingCircle />;
  }
  return (
    <TrackPageComponent
      numOfItemsPerPage={25}
      shouldUseDashForNullListViewContent
      title={`Manage ${pluralizeWord(itemTypeLabel)}`}
      titleIcon={
        <ItemTypesMangementIcon
          width={28}
          height={28}
          style={{ color: mainColor }}
        />
      }
      subTitle={`${dataList[0]?.dataList?.length || 0} ${pluralizeWord(itemTypeLabel)}`}
      mainColor={xemelgoStyle.theme.XEMELGO_BLUE}
      buttonColor={xemelgoStyle.theme.APP_BLUE}
      secondaryColor={xemelgoStyle.theme.XEMELGO_LIGHTBLUE}
      renderDataList={dataList}
      listViewMode
      listViewStructure={listViewStructureList}
      listViewFilterFunc={listViewFilterFunc}
      getListViewLinkDetails={({ id }) => {
        return { route: `/item-types-management/detail?id=${id}`, isReplace: false };
      }}
      additionalButtonsStructureList={[
        {
          id: "add-item-type",
          title: `Add ${itemTypeLabel}`,
          linkRoute: `${history.location.pathname}/create`
        }
      ]}
      moreButtonsList={[
        {
          id: "bulk-update",
          title: "Bulk Update",
          linkRoute: `${history.location.pathname}/bulk-update`
        }
      ]}
    />
  );
};

export default ItemTypesManagementTrackPageFeature;
