import React from "react";
import PropTypes from "prop-types";
import { ModalForm } from "../modal-form";
import OnboardModalHeader from "./components/onboard-modal-header";
import OnboardModalFooter from "./components/onboard-modal-footer";
import Style from "./OnboardItemModal.module.css";
import "./ModalFormStyle.css";

export const OnboardItemModal = ({
  title,
  titleIconComponent,
  children,
  onSubmit,
  onClose,
  isPrintEnabled,
  submitDisabled,
  headerMessage,
  error,
  modalContainerClassName
}) => {
  return (
    <ModalForm
      scrollable
      show
      size="xl"
      title={
        <OnboardModalHeader
          title={title}
          titleIconComponent={titleIconComponent}
          onClose={onClose}
          error={error}
          headerMessage={headerMessage}
        />
      }
      body={children}
      footer={
        <OnboardModalFooter
          onClose={onClose}
          onSubmit={onSubmit}
          submitDisabled={submitDisabled}
          submitLabel={`Confirm${isPrintEnabled ? " and Print" : ""}`}
        />
      }
      className={`${Style.onboard_modal_container} ${modalContainerClassName}`}
      prefix="onboard-modal"
    />
  );
};

OnboardItemModal.defaultProps = {
  children: null,
  isPrintEnabled: false,
  submitDisabled: true,
  error: false,
  headerMessage: "",
  modalContainerClassName: ""
};

OnboardItemModal.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  titleIconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isPrintEnabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  headerMessage: PropTypes.string,
  error: PropTypes.bool,
  modalContainerClassName: PropTypes.string
};
