import React, { useMemo, useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import PropTypes from "prop-types";
import Style from "./MetricsGrouping.module.css";

const SLIDES_PER_PAGE = 3;

export const MetricsGrouping = ({ title, subtitle, children }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const { numOfPages, numOfBlank } = useMemo(() => {
    const numOfSlides = React.Children.toArray(children).filter((child) => {
      return React.isValidElement(child);
    }).length;

    return {
      numOfPages: Math.ceil(numOfSlides / SLIDES_PER_PAGE),
      numOfBlank: SLIDES_PER_PAGE - (numOfSlides % SLIDES_PER_PAGE || SLIDES_PER_PAGE)
    };
  }, [children]);

  const renderEmptySlides = useCallback(() => {
    return [...Array(numOfBlank)].map((_, index) => {
      return <SwiperSlide key={index} />;
    });
  }, [numOfBlank]);

  const { disablePrevButton, disableNextButton } = useMemo(() => {
    return {
      disablePrevButton: currentSlideIndex === 0,
      disableNextButton:
        numOfPages * SLIDES_PER_PAGE - SLIDES_PER_PAGE <= currentSlideIndex &&
        currentSlideIndex <= numOfPages * SLIDES_PER_PAGE - 1
    };
  }, [numOfPages, currentSlideIndex]);

  return (
    <div className={`${Style.flex_column} ${Style.metric_group}`}>
      <div className={`${Style.flex_row} ${Style.label_container}`}>
        <p className={Style.group_label}>{title}</p>
        <div className={`${Style.flex_row} ${Style.sub_label_container}`}>{subtitle}</div>
      </div>
      <div className={`${Style.flex_row} ${Style.swiper_container}`}>
        {numOfPages > 1 && (
          <div
            className={`${title}-swiper-button-prev  ${Style.carousel_navigation_button} ${
              disablePrevButton && Style.carousel_navigation_button_disabled
            }`}
          >
            <ChevronLeft />
          </div>
        )}
        <div className={Style.carousel_container}>
          <Swiper
            onActiveIndexChange={({ activeIndex }) => {
              setCurrentSlideIndex(activeIndex);
            }}
            resizeObserver
            navigation={
              numOfPages > 1 && {
                prevEl: `.${title}-swiper-button-prev`,
                nextEl: `.${title}-swiper-button-next`
              }
            }
            pagination
            slidesPerGroup={SLIDES_PER_PAGE}
            slidesPerView={SLIDES_PER_PAGE}
            modules={[Navigation, Pagination]}
            className={`${Style.flex_row} ${Style.carousel}`}
          >
            {children}
            {renderEmptySlides()}
          </Swiper>
        </div>
        {numOfPages > 1 && (
          <div
            className={`${title}-swiper-button-next ${Style.carousel_navigation_button} ${
              disableNextButton && Style.carousel_navigation_button_disabled
            }`}
          >
            <ChevronRight />
          </div>
        )}
      </div>
    </div>
  );
};

MetricsGrouping.defaultProps = {
  title: "",
  subtitle: "",
  children: null
};

MetricsGrouping.propTypes = {
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.object])
};
