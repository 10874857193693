import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import AutoSizeTextArea from "../AutoSizeTextArea/AutoSizeTextArea";
import { AddPageInputTypeMap } from '../../common/Utilities';
import CheckBoxGroup from '../add-page-component/add-page-inputs/CheckBoxGroup';
import SearchDropdown from '../SearchDropdown/SearchDropdown';
import DateTimePicker, { DatePicker } from '../DateTimePicker/DateTimePicker';
import Style from "./MultiInputForm.module.css";

const MultiInputForm = ({ formFields, onChange, isLoading, containerClassName, fieldContainerClassName }) => {
  const inputTypeControl = (field) => {
    const { type, value, isDisabled, isReadOnly, errorMessage, timeFormat, options } = field;
    switch (type) {
      case AddPageInputTypeMap.CHECK_BOX_GROUP:
        return (
          <CheckBoxGroup
            value={{ ...value, disabled: isReadOnly || isDisabled }}
            options={options}
            onChange={(_, newValue) => {
              onChange(field.id, newValue);
            }}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
      case AddPageInputTypeMap.DATE_TIME_PICKER:
        if (isDisabled || isReadOnly) {
          return (
            <AutoSizeTextArea
              value={moment(value).format(timeFormat || "MM/DD/yyyy hh:mm a")}
              disabled={isDisabled}
              readOnly={isReadOnly}
              backgroundColor={isReadOnly ? "transparent" : ""}
              error={!!errorMessage}
              errorMessage={errorMessage}
            />
          );
        }
        return (
          <DateTimePicker
            format={timeFormat}
            value={value}
            disabled={isDisabled}
            readOnly={isReadOnly}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onTimeChange={(newTime) => {
              onChange(field.id, newTime);
            }}
          />
        );
      case AddPageInputTypeMap.DATE_PICKER:
        if (isDisabled || isReadOnly) {
          return (
            <AutoSizeTextArea
              value={moment(value).format(timeFormat || "MM/DD/yyyy")}
              disabled={isDisabled}
              readOnly={isReadOnly}
              backgroundColor={isReadOnly ? "transparent" : ""}
              error={!!errorMessage}
              errorMessage={errorMessage}
            />
          );
        }
        return (
          <DatePicker
            format={timeFormat}
            value={value}
            disabled={isDisabled}
            readOnly={isReadOnly}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onTimeChange={(newTime) => {
              onChange(field.id, newTime);
            }}
          />
        );
      case AddPageInputTypeMap.COMMENT:
        return (
          <AutoSizeTextArea
            maxRows={4}
            minRows={4}
            newLineAllowed
            value={value}
            onChangeText={(newText) => {
              onChange(field.id, newText);
            }}
            disabled={isDisabled}
            readOnly={isReadOnly}
            backgroundColor={isReadOnly ? "transparent" : ""}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
      case AddPageInputTypeMap.SEARCH_DROP_DOWN:
      case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
        return (
          <SearchDropdown
            selectedItem={value || {}}
            options={options}
            disabled={isDisabled}
            readOnly={isReadOnly}
            error={!!errorMessage}
            errorMessage={errorMessage}
            onItemSelected={(newItem) => {
              onChange(field.id, newItem);
            }}
            inputBackgroundColor={isReadOnly ? "transparent" : ""}
          />
        );
      case AddPageInputTypeMap.INPUT:
      default:
        const { numberOnly } = field;
        return (
          <AutoSizeTextArea
            value={value}
            onChangeText={(newText) => {
              onChange(field.id, newText);
            }}
            numberOnly={numberOnly}
            disabled={isDisabled}
            readOnly={isReadOnly}
            backgroundColor={isReadOnly ? "transparent" : ""}
            error={!!errorMessage}
            errorMessage={errorMessage}
          />
        );
    }
  };

  const renderSkeleton = () => {
    const itemSkeletons = [];
    for (let i = 0; i < 3; i++) {
      itemSkeletons.push(
        <div
          key={`skeleton-${i}`}
          className={fieldContainerClassName}
        >
          <Skeleton
            width={90}
            height={14}
          />
          <Skeleton
            width={235}
            height={37}
          />
        </div>
      );
    }

    return <div className={`${Style.form_section_container} ${containerClassName}`}>{itemSkeletons}</div>;
  };

  const renderField = (field) => {
    if (field.isHidden) {
      return null;
    }

    return (
      <div
        key={`${field.id}-${field.type}`}
        className={fieldContainerClassName}
      >
        <div className={Style.input_text_container}>
          <p className={Style.input_title}>{field.label}</p>
          {field.isRequired && <p className={Style.required_asterisk}>*</p>}
        </div>
        {inputTypeControl(field)}
      </div>
    );
  };

  if (isLoading) {
    return renderSkeleton();
  }

  return (
    <div className={`${Style.form_section_container} ${containerClassName}`}>
      {formFields.map((field) => {
        return renderField(field);
      })}
    </div>
  );
};

MultiInputForm.defaultProps = {
  formFields: [],
  containerClassName: "",
  fieldContainerClassName: ""
};

MultiInputForm.propTypes = {
  formFields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, value: PropTypes.any }),
    ]),
    type: PropTypes.oneOf(Object.values(AddPageInputTypeMap)).isRequired,
    isHidden: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string
      })
    ),
    numberOnly: PropTypes.bool
  })),
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  containerClassName: PropTypes.string,
  fieldContainerClassName: PropTypes.string
};

export default MultiInputForm;
