import React from 'react';

let authHeader = "";

const setBearerToken = (token) => {
  authHeader = token;
}

class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    const lowerCaseMessage = message.toLowerCase();


      this.actionProvider.handleQuestion(message, authHeader);
    

  }
}

export default MessageParser;
export { setBearerToken };


// const MessageParser = ({ children, actions }) => {

//   // Question parser logic here
//   const parse = (message) => {
//     if (message.includes('hi') || message.includes("hello")) {
//       actions.handleGreeting();
//     } else if (message.includes('javascript')) {
//       actions.handleJavascriptList();
//     }
//     else {
//       actions.handleQuestion(message, authHeader);
//     }

//   };

//   return (
//     <div>
//       {React.Children.map(children, (child) => {
//         return React.cloneElement(child, {
//           parse: parse,
//           actions: {},
//         });
//       })}
//     </div>
//   );
// };

// export default MessageParser;
// export { setBearerToken };

