import { SOLUTION_ID, SETTING_ID } from "../../constants/tabConfigConstants";

const getWorkOrderRoute = (defaultWorkOrderTab) => {
  switch (defaultWorkOrderTab) {
    case "all-parts":
      return "/work-order?group=all-parts";
    case "location":
      return "/work-order?group=location";
    default:
      return "/work-order";
  }
};

export const solutionMenuOptionsFactory = (customerConfig, solutionId, title) => {
  const isAssetTabNested = customerConfig.webClient?.uiPreferences?.nestedAssetTab;
  const isWorkOrderTabNested = customerConfig.webClient?.uiPreferences?.nestedWorkOrderTab;

  switch (solutionId) {
    case SOLUTION_ID.ORDER:
      const defaultWorkOrderTab =
        customerConfig.solutionConfiguration.uiPreferences?.trackPageConfiguration?.defaultTab;
      const workOrderRoute = getWorkOrderRoute(defaultWorkOrderTab);
      return isWorkOrderTabNested
        ? {
            id: "work-order",
            title: title || "Work Orders Parent",
            action: (tabInfo) => {
              tabInfo.children[tabInfo.defaultChild].tabRef.current.click();
            },
            children: [
              {
                id: SOLUTION_ID.ORDER_TRACKING,
                title: "Tracking",
                href: workOrderRoute,
                default: true
              },
              {
                id: SOLUTION_ID.MANAGEMENT_DASHBOARD,
                title: "Management Dashboard",
                href: "/management-dashboard"
              }
            ]
          }
        : {
            id: "work-order",
            title: title || "Work Orders"
          };
    case SOLUTION_ID.INVENTORY:
      const reportGroups = customerConfig.webClient.appConfigurationMap.reports?.reportGroups;
      const isInventoryHierarchyEnabled = reportGroups?.Inventory?.some((report) => {
        return report.id === "Removed Report";
      });

      return isInventoryHierarchyEnabled
        ? {
            id: SOLUTION_ID.INVENTORY,
            title: title || "Inventory",
            action: (tabInfo) => {
              tabInfo.children[tabInfo.defaultChild].tabRef.current.click();
            },
            children: [
              {
                id: SOLUTION_ID.INVENTORY_TRACKING,
                title: "Tracking",
                href: "/inventory",
                default: true
              },
              {
                id: SOLUTION_ID.INVENTORY_APPROVAL_MANAGEMENT,
                title: "Approval Management",
                href: "/inventory/approvals"
              }
            ]
          }
        : {
            id: SOLUTION_ID.INVENTORY,
            title: title || "Inventory",
            href: "/inventory"
          };
    case SOLUTION_ID.GOODS_RECEIPT:
      return {
        id: SOLUTION_ID.GOODS_RECEIPT,
        title: title || "Goods receipt",
        href: "/goods-receipt"
      };

    case SOLUTION_ID.ASSET:
      return isAssetTabNested
        ? {
            id: SOLUTION_ID.ASSET,
            title: title || "Asset",
            action: (tabInfo) => {
              tabInfo.children[tabInfo.defaultChild].tabRef.current.click();
            },
            children: [
              {
                id: SOLUTION_ID.ASSET_TRACKING,
                title: "Tracking",
                href: "/asset",
                default: true
              },
              {
                id: SOLUTION_ID.ASSET_MAP,
                title: "Dashboard",
                href: "/asset-map"
              }
            ]
          }
        : {
            id: SOLUTION_ID.ASSET,
            title: title || "Asset"
          };

    case SOLUTION_ID.CREATE_ASSET:
      return {
        id: SOLUTION_ID.CREATE_ASSET,
        title: title || "Asset",
        href: "/asset/create"
      };
    case SOLUTION_ID.ASSET_MAP:
      if (isAssetTabNested) {
        break;
      }
      return {
        id: SOLUTION_ID.ASSET_MAP,
        title: title || "Asset Map",
        href: "/asset-map"
      };

    case SOLUTION_ID.SHIPMENTS:
      return {
        id: SOLUTION_ID.SHIPMENTS,
        title: title || "Shipments",
        href: "/shipments"
      };

    case SOLUTION_ID.PURCHASE_ORDER:
      return {
        id: SOLUTION_ID.PURCHASE_ORDER,
        title: title || "Purchase Orders",
        href: "/purchase-order"
      };
    case SOLUTION_ID.MANAGEMENT_DASHBOARD:
      if (isWorkOrderTabNested) {
        break;
      }
      return {
        id: SOLUTION_ID.MANAGEMENT_DASHBOARD,
        title: title || "Management Dashboard",
        href: "/management-dashboard"
      };
    case SOLUTION_ID.SCHEDULING_MANAGEMENT:
      return {
        id: SOLUTION_ID.SCHEDULING_MANAGEMENT,
        title: title || "Scheduling Management",
        href: "/scheduling-management"
      };
    case SOLUTION_ID.PACKAGE:
      return {
        id: SOLUTION_ID.PACKAGE,
        title: title || "Package",
        href: "/package"
      };
    case SOLUTION_ID.DASHBOARD:
      return {
        id: SOLUTION_ID.DASHBOARD,
        title: title || "Dashboard",
        href: "/landing"
      };
    default:
      return null;
  }
};

export const settingsMenuOptionsFactory = (settingId, title) => {
  switch (settingId) {
    case SETTING_ID.REPORT:
      return {
        id: SETTING_ID.REPORT,
        title: title || "Reports",
        href: "/reports"
      };

    case SETTING_ID.ITEM_TYPES_MANAGEMENT:
      return {
        id: SETTING_ID.ITEM_TYPES_MANAGEMENT,
        title: title || "Item Types Management",
        href: "/item-types-management"
      };

    case SETTING_ID.MY_FACILITY:
      return {
        id: SETTING_ID.MY_FACILITY,
        title: title || "My Facility",
        href: "/my-facility"
      };

    case SETTING_ID.USERS:
      return {
        id: SETTING_ID.USERS,
        title: title || "Users",
        href: "/users"
      };

    case SETTING_ID.ALERTS:
      return {
        id: SETTING_ID.ALERTS,
        title: title || "Alerts",
        href: "/alerts"
      };

    default:
      return null;
  }
};
