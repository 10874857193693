import React, { useMemo, Fragment, useRef } from "react";
import PropTypes from "prop-types";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Checkbox } from "../checkbox-component/Checkbox";
import Style from "./ListTable.module.css";

/**
 * A component for displaying a list of data as a table
 * @param {any[]} header An array of headers to display at the top of the table
 * @param {any[]} data An array of data row objects to display in the table
 * @param {string} [headerContainerClassName] The name of a css class to apply to the headers
 * @param {string} [itemsContainerClassName] The name of a css class to apply to the data rows
 * @param {() => {}} [renderHeader] A function to use to render the headers, if not provided
 * the header element itself will be rendered (so must be a string)
 * @param {() => {}} [renderItem] A function to use to render the data rows, if not provided
 * the data element itself will be rendered (so must be a string)
 * @param {() => {}} [renderEmptyList] An element to render in the table when no data is present,
 * if not provided nothing will be rendered in the table
 * @param {boolean} [selectable] If true, checkboxes will be added to the table to enable selectability
 * @param {() => {}} [onSelectClick] A callback function that is triggered when an item is selected
 * @param {boolean} [hideSelectAll] If true, the select all option in the header row will be hidden,
 * but the checkboxes for the rows themselves will still be present
 * @param {() => {}} [isItemSelectableFunc] If provided, will execute this function for each data item
 * to determine whether or not the select checkbox should be displayed
 * @param {() => {}} [renderItemChild] A function that returns an element to render for the child rows
 * of each row. If this parameter is provided, it is assumed that child rendering is desired and expand
 * icons will be added to all parent rows
 * @param {() => {}} [onExpandStateChange] A callback function that is triggered when a rows is expanded
 * @param {JSX.Element} [actionBar] A TableActionBar element which can be provided to add additional
 * functionality to the ListTable
 */
const ListTable = React.forwardRef(
  (
    {
      header,
      data,
      headerContainerClassName,
      itemsContainerClassName,
      selectedRowClassName,
      renderHeader,
      renderItem,
      renderEmptyList,
      selectable,
      onSelectClick,
      hideSelectAll,
      isItemSelectableFunc,
      renderItemChild,
      onExpandStateChange,
      actionBar,
      className
    },
    ref
  ) => {
    const containerRef = useRef(null);
    const selectableRows = useMemo(() => {
      if (!selectable) {
        return [];
      }

      return data.filter((row) => {
        return !isItemSelectableFunc || isItemSelectableFunc(row);
      });
    }, [data, selectable, isItemSelectableFunc]);

    const isSelectionEnabled = useMemo(() => {
      return selectable && selectableRows.length > 0;
    }, [selectableRows, selectable]);

    const isAllChecked = () => {
      let isChecked = true;
      selectableRows.forEach((row) => {
        if (!row.isChecked) {
          isChecked = false;
        }
      });

      return selectableRows.length && isChecked;
    };

    const buildListTableRefFunctions = () => {
      const scrollToItem = (index) => {
        containerRef.current.children[index].scrollIntoView({
          behavior: "smooth"
        });
      };

      return {
        scrollToItem
      };
    };

    if (ref) {
      ref.current = buildListTableRefFunctions();
    }

    const getWrappedItemNode = (datum, itemNode) => {
      if (!isSelectionEnabled && !renderItemChild) {
        return itemNode;
      }

      return (
        <div
          key={datum.id}
          className={`${Style.flex_row} ${isSelectionEnabled && datum.isChecked ? selectedRowClassName : ""}`}
        >
          {renderItemChild && renderExpand(datum)}
          {isSelectionEnabled && renderCheckbox(datum)}
          {itemNode}
        </div>
      );
    };

    const renderExpand = (row) => {
      return (
        <div className={`${Style.expand_icon_container}`}>
          {row.isExpanded ? (
            <ExpandLess
              onClick={() => {
                return onExpandStateChange(row.id, !row.isExpanded);
              }}
              className={`${Style.expand_icon}`}
            />
          ) : (
            <ExpandMore
              onClick={() => {
                return onExpandStateChange(row.id, !row.isExpanded);
              }}
              className={`${Style.expand_icon}`}
            />
          )}
        </div>
      );
    };

    const renderCheckbox = (row) => {
      const isRowSelectable = !isItemSelectableFunc || isItemSelectableFunc(row);
      return (
        <div className={`${Style.checkbox_container} ${isRowSelectable ? null : Style.hidden}`}>
          <Checkbox
            onClick={() => {
              onSelectClick([row.id], !row.isChecked);
            }}
            isChecked={row.isChecked}
          />
        </div>
      );
    };

    const renderItemNode = (datum, index) => {
      return renderItem?.(datum, index) || <li key={`${datum}_${index}`}>{datum}</li>;
    };

    return (
      <>
        {actionBar && actionBar}
        <div className={`${Style.flex_column} ${className}`}>
          <ul
            className={`${Style.flex_row} ${headerContainerClassName} ${renderItemChild ? Style.expand_padding : null}`}
          >
            {isSelectionEnabled && (
              <div className={`${Style.checkbox_container} ${hideSelectAll ? Style.hidden : null}`}>
                <Checkbox
                  onClick={() => {
                    if (onSelectClick) {
                      onSelectClick(
                        selectableRows.map((eachData) => {
                          return eachData.id;
                        }),
                        !isAllChecked()
                      );
                    }
                  }}
                  isChecked={isAllChecked()}
                />
              </div>
            )}
            {header.map((eachHeader, index) => {
              return renderHeader?.(eachHeader, index) || <li key={`${eachHeader}_${index}`}>{eachHeader}</li>;
            })}
          </ul>
          {data.length ? (
            <ul
              className={`${Style.flex_column} ${itemsContainerClassName}`}
              ref={containerRef}
            >
              {data.map((eachData, index) => {
                return (
                  <Fragment key={`${eachData}_${index}`}>
                    {getWrappedItemNode(eachData, renderItemNode(eachData, index))}
                    {eachData.isExpanded && <div className={`${Style.row_detail}`}>{renderItemChild(eachData)}</div>}
                  </Fragment>
                );
              })}
            </ul>
          ) : (
            renderEmptyList?.()
          )}
        </div>
      </>
    );
  }
);

ListTable.defaultProps = {
  header: [],
  data: [],
  headerContainerClassName: "",
  itemsContainerClassName: "",
  selectedRowClassName: "",
  renderHeader: null,
  renderItem: null,
  renderEmptyList: null,
  selectable: false,
  onSelectClick: () => {},
  hideSelectAll: false,
  isItemSelectableFunc: null,
  renderItemChild: null,
  onExpandStateChange: null,
  actionBar: null,
  containerRef: null,
  className: ""
};

ListTable.propTypes = {
  header: PropTypes.array,
  data: PropTypes.array,
  headerContainerClassName: PropTypes.string,
  itemsContainerClassName: PropTypes.string,
  selectedRowClassName: PropTypes.string,
  renderHeader: PropTypes.func,
  renderItem: PropTypes.func,
  renderEmptyList: PropTypes.func,
  selectable: PropTypes.bool,
  onSelectClick: PropTypes.func,
  hideSelectAll: PropTypes.bool,
  isItemSelectableFunc: PropTypes.func,
  renderItemChild: PropTypes.func,
  onExpandStateChange: PropTypes.func,
  actionBar: PropTypes.node,
  containerRef: PropTypes.object,
  className: PropTypes.string
};

export default ListTable;
