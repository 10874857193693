import React, { useEffect, useState } from "react";
import BulkUpdateComponent from "../../components/bulk-update-component/BulkUpdateComponent";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { XemelgoService } from "../../services/XemelgoService";
import { BulkUpdateInputTypeMap, validCSVHeaderCheck } from "../../common/Utilities";
import { BulkUpdateFeatureFunctionMap, processBulkUpdateAttributes } from "../../common/commonAPICalls";

const APP_ID = "asset";
const POSSIBLE_DETECTOR_LOCATIONS = "possibleDetectorLocations";
const ATTRIBUTE_MAP = "attributeMap";

const BulkUpdateAssetsFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [defaultAttributeMap, setDefaultAttributeMap] = useState({});
  const [customAttributeMap, setCustomAttributeMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [itemClient, setItemClient] = useState(null);
  const [publishClient, setPublishClient] = useState(null);
  const [errorExists, setErrorExists] = useState(false);
  const [possibleDetectorLocationCategories, setPossibleDetectorLocationCategories] = useState([]);

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line
  }, []);

  const onLoad = async () => {
    setLoading(true);

    const xemelgoClient = XemelgoService.getClient();
    const possibleLocations = configProvider.getValue(POSSIBLE_DETECTOR_LOCATIONS, "array");
    const attributeMap = configProvider.getValue(ATTRIBUTE_MAP, "object") || {};

    const { defaultAttributeMap: newDefaultAttributeMap = {}, customAttributeMap: newCustomAttributeMap = {} } =
      attributeMap;

    const processedAttributes = await processBulkUpdateAttributes(
      newDefaultAttributeMap,
      newCustomAttributeMap,
      BulkUpdateFeatureFunctionMap,
      { possibleDetectorLocationCategories: possibleLocations, itemClass: ["Asset"] },
      true // filterOnly
    );

    setItemClient(xemelgoClient.getItemClient());
    setPublishClient(xemelgoClient.getPublishClient());
    setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
    setCustomAttributeMap(processedAttributes.customAttributeMap);
    setPossibleDetectorLocationCategories(possibleLocations);
    setLoading(false);
  };

  const closeBannerFn = () => {
    setBannerError(false);
    setBannerMessage("");
    setShowBanner(false);
  };

  const validateBulkUpdateCSVInput = async (data, defaultAttributeMap, customAttributeMap) => {
    let valid = true;
    let errorMessage;
    let processedAttributes;

    const defaultAttributeMapWithInput = Object.keys(defaultAttributeMap).reduce((acc, attr) => {
      acc[attr] = {
        ...defaultAttributeMap[attr],
        inputValues: defaultAttributeMap[attr].validateCSVInputWithOptions ? [] : undefined
      };
      return acc;
    }, {});

    data.forEach((each) => {
      Object.keys(each).forEach((id) => {
        if (defaultAttributeMapWithInput[id].inputValues) {
          defaultAttributeMapWithInput[id].inputValues.push(each[id]);
        }
      });
    });

    try {
      // Get options from API
      setLoading(true);
      processedAttributes = await processBulkUpdateAttributes(
        defaultAttributeMapWithInput,
        customAttributeMap,
        BulkUpdateFeatureFunctionMap,
        { possibleDetectorLocationCategories, itemClass: ["Asset"] },
        false // filterOnly
      );
      setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
      setCustomAttributeMap(processedAttributes.customAttributeMap);
    } catch (e) {
      setLoading(false);
      valid = false;
      errorMessage = `${e}`;
      return { valid, errorMessage };
    }

    data.forEach((each) => {
      Object.keys(each).forEach((id) => {
        const attributeMap = processedAttributes.defaultAttributeMap[id] || processedAttributes.customAttributeMap[id];
        if (attributeMap) {
          const { type, label, options, validateCSVInputWithOptions } = attributeMap;
          if (each[id]) {
            switch (type) {
              case BulkUpdateInputTypeMap.DATE_PICKER:
                const date = Date.parse(each[id]);
                if (isNaN(date)) {
                  valid = false;
                  errorMessage = `Cannot import csv file: ${each[id]} is not a valid date`;
                }
                break;
              // for future use
              // case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
              //   if (typeof each[id] === 'string' && validateCSVInputWithOptions) {
              //     const inputValue = each[id].trim().toUpperCase();
              //     if (options && options.length) {
              //       if (
              //         !options.find((option) => option.label.trim().toUpperCase() === inputValue)
              //       ) {
              //         valid = false;
              //         errorMessage = `Invalid ${label} - ${each[id]}`;
              //       }
              //     }
              //   }
              //   break;
              case BulkUpdateInputTypeMap.INPUT:
                const inputValue = each[id].trim().toUpperCase();
                if (validateCSVInputWithOptions && options) {
                  if (!options[inputValue]) {
                    valid = false;
                    errorMessage = `Invalid ${label} - ${each[id]}`;
                  }
                }
                break;
              // For future use
              // case BulkUpdateInputTypeMap.CHECK_BOX_GROUP:
              // case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN:
              default:
                break;
            }
          }
        } else {
          valid = false;
          errorMessage = "Cannot import csv file: Please check the provided data";
        }
      });
    });
    setLoading(false);
    setErrorExists(!valid);
    return { valid, errorMessage };
  };

  const onUploadCSVSubmit = async (dataList) => {
    const dataListWithStatus = [];
    let hasError = false;
    setLoading(true);
    setLoadingMessage(`Updating Item(s)`);

    const itemPayloadList = dataList.map((eachData) => {
      const itemPayload = {};
      let attributeMap = {};
      Object.keys(eachData).forEach((id) => {
        attributeMap = defaultAttributeMap[id] || customAttributeMap[id];
        const value = eachData[id];
        const { type, transformInput, options } = attributeMap;
        let finalValue;

        switch (type) {
          case BulkUpdateInputTypeMap.DATE_PICKER:
            finalValue = value ? Date.parse(value) : undefined;
            break;
          case BulkUpdateInputTypeMap.INPUT:
            if (value && transformInput) {
              if (transformInput === "toUpperCase") {
                finalValue = value.toUpperCase();
                break;
              }
            }
            finalValue = value;
            break;
          /* For future use
           case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
           case BulkUpdateInputTypeMap.SEARCH_DROP_DOWN:
           case BulkUpdateInputTypeMap.CHECK_BOX_GROUP:
          */
          default:
            finalValue = value;
            break;
        }

        if (
          Object.keys(customAttributeMap).find((key) => {
            return key === id;
          })
        ) {
          if (!itemPayload.customFields) {
            itemPayload.customFields = {};
          }
          itemPayload.customFields[id] = finalValue;
        } else {
          itemPayload[id] = finalValue;
        }
        switch (id) {
          case "identifier":
            if (options && finalValue && options[finalValue.trim().toUpperCase()]) {
              itemPayload.id = options[finalValue.trim().toUpperCase()].id;
            }
            break;
          case "last_known_location":
            if (options && finalValue && options[finalValue.trim().toUpperCase()]) {
              itemPayload.locationId = options[finalValue.trim().toUpperCase()].id;
            }
            break;
          default:
            break;
        }
      });

      return itemPayload;
    });

    const locationIdToItemIdMap = {};
    for (let i = 0; i < itemPayloadList.length; i++) {
      const itemPayload = itemPayloadList[i];
      setLoadingMessage(`Updating asset ${i + 1} of ${itemPayloadList.length}`);
      const processedData = { ...itemPayload };
      try {
        if (itemPayload.id && itemPayload.expiry_date) {
          await itemClient.updateItem(
            itemPayload.id,
            undefined, // identifier
            undefined, // category
            undefined, // name
            undefined, // description
            itemPayload.expiry_date,
            undefined, // image path
            undefined, // quantity
            undefined, // lot number
            itemPayload.refresh_date
          );
        }
        if (itemPayload.id && itemPayload.locationId) {
          if (locationIdToItemIdMap[itemPayload.locationId]) {
            locationIdToItemIdMap[itemPayload.locationId].push(itemPayload.id);
          } else {
            locationIdToItemIdMap[itemPayload.locationId] = [itemPayload.id];
          }
        }
      } catch (err) {
        const errMessage = err.errorMessage ? err.errorMessage.toString() : err.toString();
        setBannerError(true);
        hasError = true;
        processedData.errorMessage = errMessage;
      }
      dataListWithStatus.push(processedData);
    }

    try {
      if (Object.keys(locationIdToItemIdMap).length > 0) {
        setLoadingMessage(`Updating asset location`);
        for (const locationId of Object.keys(locationIdToItemIdMap)) {
          const itemIds = locationIdToItemIdMap[locationId];
          if (itemIds && itemIds.length > 0) {
            while (itemIds.length > 0) {
              const currentSlice = itemIds.splice(0, 50);
              await publishClient.publishUserEvent([...currentSlice], locationId);
            }
          }
        }
      }
    } catch (err) {
      const errMessage = err.errorMessage ? err.errorMessage.toString() : err.toString();
      setBannerError(true);
      setBannerMessage(`Error updating asset location - ${errMessage}`);
      hasError = true;
    }

    setBannerError(hasError);
    setBannerMessage(
      hasError
        ? bannerMessage || "One or more assets could not be updated: Please check the following asset(s) and retry."
        : "Asset(s) updated successfully."
    );

    setShowBanner(true);
    setLoading(false);
    setLoadingMessage("");
    return dataListWithStatus;
  };

  return (
    <BulkUpdateComponent
      loading={loading}
      loadingMessage={loadingMessage}
      title="Assets"
      validCSVHeaderCheck={(data) => {
        const { valid, errorMessage } = validCSVHeaderCheck(data, defaultAttributeMap, customAttributeMap);
        if (!valid) {
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      validCSVDataCheck={async (data) => {
        const { valid, errorMessage } = await validateBulkUpdateCSVInput(data, defaultAttributeMap, customAttributeMap);
        if (!valid) {
          setBannerError(!valid);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
        return valid;
      }}
      bannerError={bannerError}
      showBanner={showBanner}
      bannerMessage={bannerMessage}
      onCloseBanner={() => {
        closeBannerFn();
      }}
      defaultAttributeMap={defaultAttributeMap}
      customAttributeMap={customAttributeMap}
      onUploadCSVSubmit={onUploadCSVSubmit}
      errorExists={errorExists}
      setErrorExistsFn={setErrorExists}
      closeBannerFn={closeBannerFn}
    />
  );
};

export default BulkUpdateAssetsFeature;
