import React, { useEffect, useState, useRef } from "react";
import AddPageComponent from "../../components/add-page-component/AddPageComponent";
import { useAppConfigProvider, useConfigurationProvider } from "../../services/soft-cache-service";
import { XemelgoService } from "../../services/XemelgoService";
import { AddPageInputTypeMap, validCSVHeaderCheck, validCSVDataCheck } from "../../common/Utilities";
import { AddInventoryFeatureFunctionMap, processOnboardingPageAttributes } from "../../common/commonAPICalls";
import {
  INVENTORY_ONBOARDING_V1,
  INVENTORY_ONBOARDING_V1_STEPS
} from "../../constants/mixpanel-constant/inventoryOnboardingV1";
import useMixpanelContext from "../../context/mixpanel-context";

const FEATURE_ID = "addInventory";
const APP_ID = "inventory";

const AddInventoryPageFeature = () => {
  const configProvider = useAppConfigProvider(APP_ID);
  const LocationRoleConfig = useConfigurationProvider().config.features.locationRole || {};
  const [defaultAttributeMap, setDefaultAttributeMap] = useState({});
  const [customAttributeMap, setCustomAttributeMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const bannerMessage = useRef("");
  const [bannerError, setBannerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [uploadCsv, setUploadCsv] = useState(false);
  const [inventoryClient, setInventoryClient] = useState(null);
  const [locationClient, setLocationClient] = useState(null);
  const [enableOnboardingToLocation, setEnableOnboardingToLocation] = useState(false);
  const [supportForAddAndRestock, setSupportForAddAndRestock] = useState(false);
  const [possibleOnboardingLocationCategories, setPossibleOnboardingLocationCategories] = useState([]);
  const [locationRoleAddInvConfig, setLocationRoleAddInvConfig] = useState({});
  const { sendMixPanelEvent } = useMixpanelContext();

  useEffect(() => {
    sendMixPanelEvent(INVENTORY_ONBOARDING_V1, INVENTORY_ONBOARDING_V1_STEPS.ENTRY);
    onLoad();
    // eslint-disable-next-line
  }, []);

  const onLoad = async () => {
    setLoading(true);

    const xemelgoClient = await XemelgoService.getClient();
    const addInventoryConfiguration = configProvider.getValue(FEATURE_ID, "object");

    const {
      defaultAttributeMap: newDefaultAttributeMap = {},
      customAttributeMap: newCustomAttributeMap = {},
      uploadCsv: newUploadCsv,
      enableOnboardingToLocation: newEnableOnboardingToLocation,
      possibleOnboardingLocationCategories: newPossibleOnboardingLocationCategories = ["Department"],
      locationRole: newLocationRoleAddInvConfig = {},
      supportForAddAndRestock: newSupportForAddAndRestock
    } = addInventoryConfiguration;

    // Get options from API
    const processedAttributes = await processOnboardingPageAttributes(
      newDefaultAttributeMap,
      newCustomAttributeMap,
      AddInventoryFeatureFunctionMap
    );

    setInventoryClient(xemelgoClient.getInventoryClient());
    setLocationClient(xemelgoClient.getLocationClient());
    setDefaultAttributeMap(newDefaultAttributeMap);
    setCustomAttributeMap(newCustomAttributeMap);
    setDefaultAttributeMap(processedAttributes.defaultAttributeMap);
    setCustomAttributeMap(processedAttributes.customAttributeMap);
    setPossibleOnboardingLocationCategories(newPossibleOnboardingLocationCategories);
    setLocationRoleAddInvConfig(newLocationRoleAddInvConfig);
    setUploadCsv(newUploadCsv);
    setEnableOnboardingToLocation(newEnableOnboardingToLocation);
    setSupportForAddAndRestock(newSupportForAddAndRestock);
    setLoading(false);
  };

  const getInitialLocation = (locationField, onboardingLocation, locations, locationRoleClasses) => {
    // Find a matching location that has roles assigned
    return locations.find((location) => {
      return (
        location[locationField] == onboardingLocation &&
        location.roles.filter((role) => {
          return (locationRoleClasses || []).includes(role.class);
        }).length
      );
    });
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    setLoadingMessage("Creating Item");

    const itemPayload = {};

    Object.keys(formData).forEach((id) => {
      const { value, type, skipForCreation } = formData[id];
      if (!skipForCreation) {
        let finalValue;

        switch (type) {
          case AddPageInputTypeMap.DATE_PICKER:
            finalValue = value ? value.getTime() : undefined;
            break;
          case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
          case AddPageInputTypeMap.SEARCH_DROP_DOWN:
            finalValue = value && typeof value === "object" ? value.value : value;
            break;
          case AddPageInputTypeMap.CHECK_BOX_GROUP:
          case AddPageInputTypeMap.INPUT:
          default:
            finalValue = value;
            break;
        }

        itemPayload[id] = finalValue;
      }
    });

    const onboardingLocation = enableOnboardingToLocation
      ? formData.onboardingLocation?.value
        ? formData.onboardingLocation.value.value
        : null
      : null;

    // If location roles are enabled, see if this item is being onboarded to a location with the
    // proper role assigned. If so, set the initial location accordingly.
    if (LocationRoleConfig.enabled && (formData?.onboardingLocation?.value?.value || formData?.cabinet?.value?.value)) {
      const locations = await locationClient.getLocationsOfCategory(
        possibleOnboardingLocationCategories,
        LocationRoleConfig.enabled
      );
      const initialLocation = formData?.cabinet?.value?.value
        ? getInitialLocation("identifier", formData?.cabinet?.value?.value, locations, locationRoleAddInvConfig.classes)
        : getInitialLocation(
            "id",
            formData?.onboardingLocation?.value?.value,
            locations,
            locationRoleAddInvConfig.classes
          );

      if (initialLocation) {
        itemPayload.initial_location = initialLocation.identifier;
        // TODO - hisco workaround while there are mix of TSP and process state locations
        itemPayload.cabinet = null;
      }
    }

    const xemelgoClient = await XemelgoService.getClient();
    const itemClient = xemelgoClient.getItemClient();
    const items = await itemClient.getItemsByIdentifiers([formData.name.value.trim().toUpperCase()], undefined, [
      "quantity"
    ]);
    const item = items[0];
    const identifierNotUniqueMessage = `Item with ${
      formData.name.label
    } ${formData.name.value.trim()} already exists. Please use a different ${formData.name.label}.`;

    if (item) {
      if (supportForAddAndRestock) {
        const { quantity: quantityToAdd = 0 } = itemPayload;
        const { quantity: originalQuantity = 0 } = item;
        try {
          await itemClient.updateItemV2(item.id, { quantity: originalQuantity + quantityToAdd });
          sendMixPanelEvent(INVENTORY_ONBOARDING_V1, INVENTORY_ONBOARDING_V1_STEPS.ITEM_UPDATE_SUCCESS);
          bannerMessage.current = "Item(s) successfully restocked!";
          setBannerError(false);
        } catch (e) {
          sendMixPanelEvent(INVENTORY_ONBOARDING_V1, INVENTORY_ONBOARDING_V1_STEPS.ITEM_UPDATE_FAILED, {
            error_message: e.message
          });
          bannerMessage.current = "Item(s) restock failed";
          setBannerError(true);
        }
      } else {
        sendMixPanelEvent(INVENTORY_ONBOARDING_V1, INVENTORY_ONBOARDING_V1_STEPS.ITEM_ONBOARD_FAILED, {
          error_message: identifierNotUniqueMessage
        });
        setBannerError(true);
        bannerMessage.current = identifierNotUniqueMessage;
      }
    } else {
      try {
        const response = await inventoryClient.createItemSet([itemPayload], onboardingLocation);

        if (response.name === "Error") {
          sendMixPanelEvent(INVENTORY_ONBOARDING_V1, INVENTORY_ONBOARDING_V1_STEPS.ITEM_ONBOARD_FAILED, {
            error_message: response.message
          });
          setBannerError(true);
          bannerMessage.current = response.message;
        } else {
          sendMixPanelEvent(INVENTORY_ONBOARDING_V1, INVENTORY_ONBOARDING_V1_STEPS.ITEM_ONBOARD_SUCCESS, {
            item_onboarded_count: 1
          });
          setBannerError(false);
          bannerMessage.current = "Item(s) created";
        }
      } catch (err) {
        sendMixPanelEvent(INVENTORY_ONBOARDING_V1, INVENTORY_ONBOARDING_V1_STEPS.ITEM_ONBOARD_FAILED, {
          error_message: err.message
        });
        bannerMessage.current = err;
        setBannerError(true);
      }
    }
    setLoading(false);
    setLoadingMessage("");
    setShowBanner(true);
  };

  const onUploadCSVSubmit = async (dataList) => {
    let hasError = false;
    setLoading(true);
    setLoadingMessage(`Creating Item(s)`);

    const allLocations = await locationClient.getLocationsOfCategory(
      possibleOnboardingLocationCategories,
      LocationRoleConfig.enabled
    );

    const identifierList = [];

    const itemPayloadList = dataList.map((eachData) => {
      const itemPayload = {};

      Object.keys(eachData).forEach((id) => {
        const attributeMap = defaultAttributeMap[id] || customAttributeMap[id];
        const value = eachData[id];
        const { type, transformInput, numberOnly } = attributeMap;
        let finalValue;

        switch (type) {
          case AddPageInputTypeMap.DATE_PICKER:
            finalValue = value ? Date.parse(value) : undefined;
            break;
          case AddPageInputTypeMap.INPUT:
            if (transformInput) {
              if (transformInput === "toUpperCase") {
                finalValue = value.toUpperCase();
                break;
              }
            }
          // eslint-disable-next-line
          case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
          case AddPageInputTypeMap.SEARCH_DROP_DOWN:
          case AddPageInputTypeMap.CHECK_BOX_GROUP:
          default:
            if (numberOnly) {
              finalValue = parseFloat(value);
            } else {
              finalValue = value;
            }
        }

        if (
          Object.keys(customAttributeMap).find((key) => {
            return key === id;
          })
        ) {
          if (!itemPayload.customFields) {
            itemPayload.customFields = {};
          }
          itemPayload.customFields[id] = finalValue;
        } else {
          itemPayload[id] = finalValue;
        }
      });
      itemPayload.tracker_serial = itemPayload.name;
      identifierList.push(itemPayload.tracker_serial.trim().toUpperCase());

      if (itemPayload.onboardingLocation || itemPayload.cabinet) {
        if (LocationRoleConfig.enabled) {
          const initialLocation = getInitialLocation(
            "identifier",
            itemPayload.onboardingLocation || itemPayload.cabinet,
            allLocations,
            locationRoleAddInvConfig.classes
          );

          if (initialLocation) {
            itemPayload.initial_location = initialLocation.identifier;
            // TODO - hisco workaround while there are mix of TSP and process state locations
            itemPayload.cabinet = null;
          }
        }

        if (itemPayload.onboardingLocation && itemPayload.onboardingLocation.label) {
          itemPayload.onboardingLocation = itemPayload.onboardingLocation.label;
        }
      }

      return itemPayload;
    });

    try {
      let response;
      let itemsToCreate = itemPayloadList;
      if (supportForAddAndRestock) {
        const xemelgoClient = await XemelgoService.getClient();
        const itemClient = xemelgoClient.getItemClient();
        const items = await itemClient.getItemsByIdentifiers(identifierList, undefined, ["quantity"]);

        itemsToCreate = [];

        for (const payload of itemPayloadList) {
          const existingItem = items.find((item) => {
            return item.identifier === payload.name.trim().toUpperCase();
          });

          if (existingItem) {
            await itemClient.updateItemV2(existingItem.id, {
              quantity: parseFloat(existingItem.quantity) + parseFloat(payload.quantity)
            });
          } else {
            itemsToCreate.push(payload);
          }
        }
      }
      while (itemsToCreate.length > 0) {
        const currentSlice = itemsToCreate.splice(0, 100);
        response = { createItemSet: [] };
        response = await inventoryClient.createItemSetFromCSV(currentSlice, allLocations, enableOnboardingToLocation);
        if (!response.createItemSet) {
          hasError = true;
          const errMessage = response.errorMessage ? response.errorMessage.toString() : response.toString();
          bannerMessage.current = `Error creating items - ${errMessage}`;
          break;
        }
      }
    } catch (err) {
      const errMessage = err.errorMessage ? err.errorMessage.toString() : err.toString();
      bannerMessage.current = `Error creating items - ${errMessage}`;
      hasError = true;
    }

    setBannerError(hasError);
    bannerMessage.current = hasError
      ? bannerMessage.current || "One or more items could not be created: Please check the following item(s) and retry."
      : "Item(s) created successfully.";

    setShowBanner(true);
    setLoading(false);
    setLoadingMessage("");

    return dataList;
  };

  return (
    <AddPageComponent
      loading={loading}
      loadingMessage={loadingMessage}
      title="Item"
      validCSVHeaderCheck={(data) => {
        const { valid, errorMessage } = validCSVHeaderCheck(data, defaultAttributeMap, customAttributeMap);
        if (!valid) {
          setBannerError(!valid);
          bannerMessage.current = errorMessage;
          setShowBanner(true);
        }
        return valid;
      }}
      validCSVDataCheck={(data) => {
        const { valid, errorMessage } = validCSVDataCheck(data, defaultAttributeMap, customAttributeMap);
        if (!valid) {
          setBannerError(!valid);
          bannerMessage.current = errorMessage;
          setShowBanner(true);
        }
        return valid;
      }}
      bannerError={bannerError}
      showBanner={showBanner}
      setShowBanner={setShowBanner}
      bannerMessage={bannerMessage.current}
      onCloseBanner={() => {
        setBannerError(false);
        bannerMessage.current = "";
        setShowBanner(false);
      }}
      defaultAttributeMap={defaultAttributeMap}
      customAttributeMap={customAttributeMap}
      uploadCsv={uploadCsv}
      onSubmit={onSubmit}
      onUploadCSVSubmit={onUploadCSVSubmit}
    />
  );
};

export default AddInventoryPageFeature;
