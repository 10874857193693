import { AddPageInputTypeMap, TIME_IN_MS } from "../../common/Utilities";

/**
 * @typedef {Object} FieldValueType
 * @property {string} id
 * @property {string} label
 * @property {string} value
 */

/**
 * @typedef {Object} FormField
 * @property {string} id
 * @property {string} type
 * @property {FieldValueType} defaultValue
 * @property {Object} [dependency]
 * @property {string} dependency.action
 * @property {string[]} dependency.dependentOn
 * @property {boolean} [numberOnly]
 */

/**
 * @typedef {Object.<string, FieldValueType>} FormData
 */

/**
 * @typedef {Object.<string, FormField>} FormFields
 */

/**
 * @param {FormField} dependentField
 * @param {FormData} formData
 * @param {FormFields} formFields
 * @returns {FieldValueType}
 */
export const calculateDependentFormValue = (
  dependentField,
  formData,
  formFields
) => {
  const { dependency, type = "input", numberOnly = false } = dependentField;
  const { action, dependentOn } = dependency || {};
  let totalValue = "";
  let date = null;
  let hasValue = true;
  if (numberOnly || type === "datepicker") {
    totalValue = 0;
  }

  dependentOn.forEach((dependentAttributeKey) => {
    const dependentAttribute = formFields[dependentAttributeKey];
    const dependentAttributeValue = formData[dependentAttributeKey];
    if (dependentAttributeValue) {
      switch (dependentAttribute.type) {
        case AddPageInputTypeMap.INPUT:
          switch (action) {
            case "add":
            default:
              totalValue += dependentAttributeValue;
          }
          break;
        case AddPageInputTypeMap.DATE_PICKER:
          date = dependentAttributeValue;
          break;
        case AddPageInputTypeMap.SEARCH_DROP_DOWN:
        case AddPageInputTypeMap.SEARCH_DROP_DOWN_FROM_API:
          totalValue = dependentAttributeValue;
          break;
        default:
          break;
      }
    } else {
      hasValue = false;
    }
  });

  if (hasValue) {
    let finalValue;
    // if trying to combine date and numbers
    if (date && totalValue) {
      switch (action) {
        case "add":
        default:
          finalValue = date + totalValue * TIME_IN_MS.DAYS;
      }
      // if trying to combine dates, just assign one date to it
    } else if (date) {
      finalValue = date;
      // if trying to combine all numbers only, assign totalValue
    } else if (totalValue) {
      finalValue = totalValue;
    }

    return finalValue;
  }
  return formData[dependentField.id] ?? dependentField.defaultValue;
};
