/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import queryString from "query-string";
import xemelgoStyle from "styles/variable";
import PropTypes from 'prop-types';
import ScreenFrame from "components/ScreenFrame/ScreenFrame";
import FreeTextSearchInput from "components/free-text-search-input";
import PaginatedList from "components/PaginatedList/PaginatedList";
import OrderDetailComponent from "components/order-detail-component/OrderDetailComponent";
import { getStackedXemelgoLogo } from "../../common/Utilities";
import LoadingCircle from "../../components/loading/LoadingCircle";
import { ReactComponent as ExportIcon } from "../../assets/icons/export.svg";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useAppConfigProvider } from "../../services/soft-cache-service";
import { ReactComponent as InventoryIcon } from "../../assets/icons/inventory.svg";
import Style from "./InventoryOrderDetailFeature.module.css";
import { InventoryOrderDetailRow } from "./components/inventory-order-detail-row/InventoryOrderDetailRow";
import { ReactComponent as SortUpIcon } from "../../assets/icons/sort-up.svg";
import { ReactComponent as SortDownIcon } from "../../assets/icons/sort-down.svg";
import { sortItems } from "./helpers/sort-helper/sortHelpers";
import { mapGraphToOrderInfo } from "./helpers/item-to-row-mapping/itemToRowMapping";

const InventoryOrderDetailFeature = ({ history }) => {
  const configProvider = useAppConfigProvider("inventory");
  const appConfig = configProvider.getValue("orderDetail", "object") ?? {};

  const tableHeadersDefault = [
    {
      property: "itemTypeName",
      header: "Item Type",
      type: "string",
      flex: 2,
    },
    {
      property: "expectedQuantity",
      header: "Expected Qty",
      type: "number",
      flex: 1
    },
    {
      property: "scannedQuantity",
      header: "Scanned Qty",
      type: "number",
      flex: 1
    },
    {
      property: "status",
      header: "Status",
      type: "status",
      flex: 0.75,
      minWidth: "130px"
    },
    {
      property: "timestamp",
      header: "Timestamp",
      type: "string",
      flex: 2,
      minWidth: "200px"
    }
  ];

  const [logo, setLogo] = useState(null);
  const [TransferClient] = useState(useXemelgoClient().getTransferClient());
  const [orderDTO, setOrderDTO] = useState();
  const [orderInfo, setOrderInfo] = useState();
  const [orderItems, setOrderItems] = useState([]);
  const [titleText, setTitleText] = useState();
  const [itemsDetailText, setItemsDetailText] = useState();
  const [itemDescription, setItemDescription] = useState();
  const [loading, setLoading] = useState(true);
  const [tableHeaders, setTableHeaders] = useState();
  const [hideLocationDetails, setHideLocationDetails] = useState(false);
  const [hideStatusDetails, setHideStatusDetails] = useState(false);
  const [tableSort, setTableSort] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchHintText, setSearchHintText] = useState("Search Item Type");

  const [transferStatuses, setTransferStatuses] = useState([
    {
      status: "InProgress",
      displayText: "In Progress",
      color: xemelgoStyle.theme.STATUS_YELLOW
    },
    {
      status: "Received",
      displayText: "Received",
      color: xemelgoStyle.theme.STATUS_GREEN
    }
  ]);

  useEffect(() => {
    onLoad();
  }, []);      

  useEffect(() => {
    if (orderDTO === undefined) {
      return;
    }
    const normalizedItem = mapGraphToOrderInfo(orderInfo, transferStatuses, orderDTO);
    setOrderInfo(normalizedItem);
    setOrderItems(normalizedItem.items);
    setLoading(false);
  }, [orderDTO]);

  useEffect(() => {
    if (!orderInfo?.items) {
      return;
    }

    executeSearch(sortItems(orderInfo?.items, tableHeaders, tableSort), searchText);
  }, [searchText, tableSort]);

  /**
   * Loads the application configuration and sets the corresponding state variables.
   */
  const loadAppConfig = () => {
    setTableHeaders(appConfig.tableHeaders ?? tableHeadersDefault);
    setTitleText(appConfig.titleText ?? "Transfer Order Details");
    setItemDescription(appConfig.itemDescription ?? "Transfer Order");
    setItemsDetailText(appConfig.itemsDetailText ?? "Transferred Items");
    setSearchHintText(appConfig.searchHintText ?? "Search Item Type");
    setHideLocationDetails(appConfig.hideLocationDetails ?? false);
    setHideStatusDetails(appConfig.hideStatusDetails ?? false);
    setTransferStatuses(appConfig.transferStatuses ?? transferStatuses);
    if (!tableSort) {
      setTableSort({
        header: (appConfig.tableHeaders ?? tableHeadersDefault)[0].header,
        direction: "asc"
      });
    }
  };

  /**
   * Executes a search on the given items based on the provided text.
   * @param {Array} items - The array of items to search through.
   * @param {string} text - The text to search for.
   * @returns {void}
   */
  const executeSearch = (items, text) => {
    const filtered = items.filter((item) => {
      try {
        return item.itemTypeName.toLowerCase().includes(text.toLowerCase());
      } catch (e) {
        return false;
      }
    });
    setOrderItems(filtered);
  };

  /**
   * Function that is called when the component is loaded.
   * It loads the app config, retrieves the id from the query string,
   * and fetches the transfer order data based on the id.
   * If no id is provided, it redirects back to the track page.
   * If the provided id is invalid, it also redirects back to the track page.
   */
  const onLoad = async () => {
    loadAppConfig();

    const payload = queryString.parse(history.location.search);
    const { id } = payload;
    const additionalAttributes = {
      itemTypeAttributes: ["node_last_updated_time", "color_ts"], 
      transferOrderAttributes: ["node_last_updated_time"]
    };

    const navigateToInventory = () => {
      const fullPath = history.location.pathname;
      const parentPath = fullPath.slice(0, fullPath.indexOf("/transfer-order-detail"));
      history.replace(parentPath);
      
    };

    if (!id) {
      // if no id provided, don't even try to fetch the data. Save the round trip and redirect back to the track page
      navigateToInventory();
      return;
    }

    const data = await TransferClient.getTransferOrderByVids([id], null, additionalAttributes);

    // if the url provided is invalid, redirect back to the track page
    // this shouldn't really happen unless the user is trying to mess with the url
    if (!data || data.length === 0) {
      navigateToInventory();
      return;
    }

    const _logo = getStackedXemelgoLogo("dark");
    setLogo(_logo);
    setOrderDTO(data[0]);
  };

  if (loading) {
    return <LoadingCircle />;
  }

  /**
   * Renders sort buttons for a given property.
   * @param {string} property - The property to sort by.
   * @returns {JSX.Element} - The rendered sort buttons.
   */
  const renderSortButtons = (property) => {
    return (
      <>
        <div
          className={Style.direction_button_container}
        >
          <div className={`${Style.direction_button} ${tableSort.header === property && tableSort.direction !== "desc" ? Style.direction_button_selected : ""}`}>
            <SortUpIcon />
          </div>
          <div className={`${Style.direction_button} ${tableSort.header === property && tableSort.direction !== "asc" ? Style.direction_button_selected : ""}`}>
            <SortDownIcon />
          </div>
        </div>
      </>);
  };

  /**
   * Encodes the given CSV content and creates a download link to download the encoded CSV file.
   * @param {string} csvContent - The content of the CSV file.
   */
  const encodeCsvLinkAndClick = (csvContent) => {
    // Any React way of doing this?
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    const fileName = `${itemDescription}_${orderInfo.identifier}.csv`;
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  }

  /**
   * Exports the data to a CSV file.
   */
  const exportToCSV = () => {
    const wrapIfNecessary = (value) => {
      if (Number.isNaN(value)) {
        if (value.includes(",")) {
          return `"${value}"`;
        }
      }
      return value;
    };

    const header = "data:text/csv;charset=utf-8,";
    const columns = `${tableHeaders.map((h) => {return h.header})  }\n`;
    const csvContent = header + columns + orderInfo.items.map((item) => {
      return tableHeaders.map((h) => {
        if (h.property.endsWith("_ts")) {
          return wrapIfNecessary(item.ts_properties[h.property]);
        }
        return wrapIfNecessary(item[h.property]);
      }).join(",");
    }).join("\n");
    encodeCsvLinkAndClick(csvContent);
  };
  
  /**
   * Renders the feature component for the inventory order detail.
   * @returns {JSX.Element} The rendered feature component.
   */
  const renderFeature = () => {
    return <>
      <ScreenFrame 
        title={titleText} 
        color={xemelgoStyle.theme.INVENTORY_PRIMARY}
        secondaryColor={xemelgoStyle.theme.INVENTORY_SECONDARY}
        titleIconComponent={
          <InventoryIcon
            width={25}
            height={25}
            style={{ color: xemelgoStyle.theme.INVENTORY_PRIMARY }}
          />
        }
      >
        <div className={Style.content_holder}>
          <div className={Style.detail_group}>
            <div className={Style.detail_element}>
              <OrderDetailComponent 
                orderInfo={orderInfo} 
                orderLabel={itemDescription}
                image={logo}
                hideLocationDetails={hideLocationDetails}
                hideStatusDetails={hideStatusDetails}
              />
            </div>
          </div>
          <div className={Style.table_group}>
            <div className={Style.title_group}>
              <div className={Style.title_label}>
                {itemsDetailText}
              </div>
            </div>
              
            <div className={Style.top_table}>
              <div className={Style.flex_container}>
                <div className={`${Style.search_input}`}>
                  <FreeTextSearchInput
                    className={Style.search_input}
                    placeholder={searchHintText}
                    onChangeText={(text) => {
                        setSearchText(text);
                      }}
                    value={searchText}
                  />
                </div>
                <button className={`${Style.export_button} ${Style.flex_container}`} onClick={exportToCSV}>    
                  <ExportIcon className={Style.export_icon} />
                  <p className={Style.export_text}>Export</p>
                </button>
              </div>
                
              <PaginatedList
                paginatorLocation="top"
                paginatorFontWeight="600"
                showRowsPerPage
                header={tableHeaders.map((header) => {return header})} 
                headerContainerClassName={Style.table_header}
                renderEmptyList={() => {
                    return (
                      <div className={Style.empty_list_container}>
                        <p className={Style.empty_list_text}>No matching results</p>
                      </div>
                    )
                  }}
                renderHeader={(eachHeader, index) => {
                    return (
                      <div
                        key={index}
                        className={Style.table_column}
                        style={{ flex: eachHeader.flex ?? 1, minWidth: eachHeader.minWidth ?? "0px" }}
                      >
                        <div className={Style.table_column_container}
                          onClick={() => {
                          setTableSort({
                              header: eachHeader.header,
                              direction: tableSort.direction === "asc" ? "desc" : "asc"
                            });
                          }}>
                          <p className={Style.table_header_text}>{eachHeader.header}</p>
                          {renderSortButtons(eachHeader.header)}
                        </div>
                      </div>
                    );
                  }}
                itemsContainerClassName={Style.table_items}
                data={orderItems}
                renderItem={(eachItem, index) => {
                    return (
                      <InventoryOrderDetailRow key={index} orderData={eachItem} orderTableConfig={tableHeaders} />
                    );
                  }}
              />
            </div>
          </div>
        </div>
      </ScreenFrame>
    </>
  };

  return renderFeature();
};

InventoryOrderDetailFeature.propTypes = {
  // other prop types...
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(InventoryOrderDetailFeature);