import React from "react";
import PropTypes from "prop-types";
import Style from "./PrinterSelectionComponent.module.css";
import InfoCard from "../add-inventory-v2-components/InfoCard";
import Selector from "../add-inventory-v2-components/Selector";
import xemelgoStyle from "../../styles/variable";
import LoadingCircle from "../loading/LoadingCircle";

export const PrinterSelectionComponent = ({
  printerList,
  selectedPrinterInfo,
  onPrinterSelect,
  isLoading,
  errorMessage
}) => {
  if (isLoading) {
    return <LoadingCircle />;
  }

  return (
    <div className={Style.printer_information_container}>
      <p className={`${Style.header} ${xemelgoStyle.header}`}>Printer Options</p>
      <div className={Style.printer_selection_container}>
        <p className={`${xemelgoStyle.subtextBold} ${Style.sub_header}`}>
          Printer
          <span className={Style.asterisk}> *</span>
        </p>
        <div className={Style.dropdown_input_container}>
          <Selector
            disableTabFocus
            options={printerList}
            onSelect={(printer) => {
              onPrinterSelect(printer);
            }}
            value={selectedPrinterInfo.name}
            placeholder="Select a printer"
            error={!!errorMessage}
          />
        </div>
        <InfoCard info={selectedPrinterInfo} />
      </div>
    </div>
  );
};

const PrinterType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  printerObject: PropTypes.shape({})
});

const SelectedPrinterInfoType = PropTypes.shape({
  name: PropTypes.shape({
    hiddenOnCard: PropTypes.bool,
    label: PropTypes.string
  }),
  id: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),

  message: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
});

PrinterSelectionComponent.propTypes = {
  printerList: PropTypes.arrayOf(PrinterType).isRequired,
  selectedPrinterInfo: SelectedPrinterInfoType.isRequired,
  onPrinterSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired
};
