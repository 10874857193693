import React, { useMemo } from "react";
import Style from "./LocationListContent.module.css";
import { STATUS_COLOR_MAP, VIEW_MODE_MAP } from "../../../../data/constants";
import useManagementDashboardStateContext from "../../../../contexts/management-dashboard-state-context";
import PaginatedListTable from "../../../../../../components/PaginatedList/PaginatedList";
import TableStyle from "../../SelectedLocationContent.module.css";
import { multipleSortComparison } from "../../../../../../utils";

export const LocationListContent = () => {
  const { mapRef, viewMode, selectedLocationId, activeLocationTreeMap } = useManagementDashboardStateContext();

  const [childLocations, childLocationCategory] = useMemo(() => {
    let children = Object.keys(activeLocationTreeMap)
      .filter((eachLocationId) => {
        return activeLocationTreeMap[eachLocationId].directParentId === selectedLocationId;
      })
      .map((eachLocationId) => {
        return { ...activeLocationTreeMap[eachLocationId] };
      });
    if (viewMode === VIEW_MODE_MAP.expedited_parts_mode) {
      children = children.filter((eachChildrenLocation) => {
        return eachChildrenLocation.metrics.expedited > 0;
      });
    }
    return [children.sort(multipleSortComparison([{ id: "name" }])), children[0]?.category || ""];
  }, [activeLocationTreeMap, selectedLocationId, viewMode]);

  return (
    <PaginatedListTable
      paginatorLocation="top"
      header={[`${childLocationCategory} #`, `${childLocationCategory} Status`]}
      headerContainerClassName={TableStyle.table_header}
      renderHeader={(eachHeader, index) => {
        return (
          <div
            key={index}
            className={TableStyle.table_column}
          >
            <p className={TableStyle.table_header_text}>{eachHeader}</p>
          </div>
        );
      }}
      data={childLocations}
      renderItem={(eachLocation, index) => {
        return (
          <div
            key={index}
            className={`${Style.flex_row} ${TableStyle.table_item}`}
          >
            <div className={`${Style.flex_row} ${TableStyle.table_column}`}>
              <div
                className={`${TableStyle.table_item_text} ${TableStyle.table_item_text_active}`}
                onClick={() => {
                  mapRef.current.selectLocation(eachLocation.id);
                }}
                onMouseEnter={() => {
                  mapRef.current.hoverLocation(eachLocation.id);
                }}
                onMouseLeave={() => {
                  mapRef.current.resetHover();
                }}
              >
                {eachLocation.name}
              </div>
            </div>
            <div className={`${Style.flex_column} ${TableStyle.table_column}`}>
              <div
                className={Style.information_title_status}
                style={{
                  backgroundColor:
                    STATUS_COLOR_MAP[
                      eachLocation.statuses[
                        viewMode === VIEW_MODE_MAP.expedited_parts_mode ? "expeditedStatus" : "status"
                      ]
                    ],
                  marginLeft: 0
                }}
              >
                {eachLocation.statuses[viewMode === VIEW_MODE_MAP.expedited_parts_mode ? "expeditedStatus" : "status"]}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};
