import React from "react";
import PropTypes from "prop-types";
import Style from "./ViewTabs.module.css";
import useCustomizableDashboardDataSourceContext from "../../contexts/customizable-dashboard-data-source-context";
import useCustomizableDashboardStateContext from "../../contexts/customizable-dashboard-state-context";

export const ViewTabs = () => {
  const { storedLayouts } = useCustomizableDashboardDataSourceContext();
  const { currentViewTabIndex, setCurrentViewTabIndex } = useCustomizableDashboardStateContext();

  return (
    <div className={Style.layout_list_container}>
      {storedLayouts.map(({ name: eachLayoutName }, index) => {
        return (
          <button
            type="button"
            className={`${Style.layout_button} ${index === currentViewTabIndex && Style.layout_button_active}`}
            key={`${eachLayoutName}${index}`}
            onClick={() => {
              setCurrentViewTabIndex(index);
            }}
          >
            {eachLayoutName}
          </button>
        );
      })}
    </div>
  );
};

ViewTabs.defaultProps = {};

ViewTabs.propTypes = {};
