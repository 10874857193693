import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Style from "./UploadCsvComponent.module.css";

import xemelgoStyle from "../../styles/variable";
import generateCsvTemplate from "./utils/generate-csv-template";
import { SOLUTION_UNITS } from "../../data/constants";
import UploadedFileInfoSection from "./components/uploaded-file-info-section";
import ListView from "../TrackPageComponents/ListView";
import FileDropOffSection from "./components/file-dropoff-section";

export const UploadCsvComponent = ({ csvHeadersMap, onFileInput, solution }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploadError, setUploadError] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [numberOfInvalidItem, setNumberOfInvalidItem] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);

  // get csv headers list
  const csvHeaders = useMemo(() => {
    return Object.values(csvHeadersMap).map((headerObject) => {
      const { label, isRequired } = headerObject;

      // add a asterisk to a required csv header
      return `${label}${isRequired ? "*" : ""}`;
    });
  }, [csvHeadersMap]);

  const handleRemove = () => {
    setUploadedFile(null);
    setUploadError(false);
    setUploadProgress(0);
    setUploadMessage("");
    setNumberOfInvalidItem(0);
    setCsvData([]);
    onFileInput([]);
  };

  const handleOnError = (errorMessage) => {
    setUploadError(true);
    setUploadMessage(errorMessage);
  };

  // This useEffect control the message display to the user based on the number of invalid items
  useEffect(() => {
    if (!uploadedFile && !csvData.length) {
      return;
    }

    if (numberOfInvalidItem) {
      setUploadMessage(
        `${numberOfInvalidItem} out of ${csvData.length} ${SOLUTION_UNITS[solution]} were not ready to be imported due to the incorrect data. You may resolve the errors below or upload an updated csv file.`
      );
      setUploadError(true);
    } else {
      setUploadError(false);
      setUploadMessage(`${csvData.length} ${SOLUTION_UNITS[solution]} ready to be imported.`);
      onFileInput(csvData);
    }
  }, [numberOfInvalidItem, csvData, uploadedFile]);

  if (uploadedFile) {
    return (
      <>
        <UploadedFileInfoSection
          onRemove={handleRemove}
          isError={uploadError}
          message={uploadMessage}
          progressPercentage={uploadProgress}
          uploadedFileName={uploadedFile?.name}
        />

        {numberOfInvalidItem > 0 && (
          <div>
            <ListView
              onboarding
              handleDelete={(row) => {
                const newCsvData = _.cloneDeep(csvData);
                const filteredCsvData = newCsvData.filter((each) => {
                  return JSON.stringify(each) !== JSON.stringify(row);
                });
                setCsvData(filteredCsvData);
                const remainingNumberOfInvalidItems = filteredCsvData.reduce((count, csvRow) => {
                  if (csvRow.error === true) {
                    count++;
                  }
                  return count;
                }, 0);

                if (numberOfInvalidItem) {
                  setNumberOfInvalidItem(remainingNumberOfInvalidItems);
                }
              }}
              dataList={csvData}
              headerStructureList={Object.values(csvHeadersMap)}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <div className={Style.csv_upload_container}>
      <div className={Style.download_template_button_container}>
        <button
          type="button"
          className={`${Style.download_button} ${xemelgoStyle.subtext}`}
          onClick={() => {
            generateCsvTemplate(csvHeaders);
          }}
        >
          Download Template
        </button>
      </div>
      <FileDropOffSection
        csvHeadersMap={csvHeadersMap}
        onUploadProgressChange={setUploadProgress}
        onFileUpload={setUploadedFile}
        onError={handleOnError}
        onInvalidDataInput={(newNumberOfInvalidItems, newValidatedData) => {
          setNumberOfInvalidItem(newNumberOfInvalidItems);
          setCsvData(newValidatedData);
        }}
        onValidDataInput={(newValidatedData) => {
          setCsvData(newValidatedData);
          onFileInput(newValidatedData);
        }}
      />
    </div>
  );
};
const FormHeadersMapType = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    type: PropTypes.string,
    hidden: PropTypes.bool
  })
);

UploadCsvComponent.propTypes = {
  csvHeadersMap: FormHeadersMapType.isRequired,
  onFileInput: PropTypes.func.isRequired,
  solution: PropTypes.string.isRequired
};
