import React, { useMemo, useCallback, useEffect, useState } from "react";
import Style from "./SelectedLocationContent.module.css";
import { STATUS_COLOR_MAP, VIEW_MODE_MAP, TAB_OPTION_MAP, STATUS_TITLE_MAP } from "../../data/constants";
import useManagementDashboardStateContext from "../../contexts/management-dashboard-state-context";
import useManagementDashboardDataSourceContext from "../../contexts/management-dashboard-data-source-context";
import { naturalSort } from "../../../../common/Utilities";
import { useXemelgoAppsyncClient } from "../../../../services/xemelgo-appsync-service";
import LocationListContent from "./features/location-list-content";
import JobStatusContent from "./features/job-status-content";
import { useSolutionMetricsFilterStringBuilder } from "../../../../components/filters";
import useManagementDashboardConfigContext from "../../contexts/management-dashboard-config-context";

export const SelectedLocationContent = () => {
  const {
    viewMode,
    selectedMetricType,
    setSelectedMetricType,
    selectedLocationId,
    selectedTab,
    setSelectedTab,
    isWorkOrdersFilteredByLocationAndMetricsLoading,
    setIsWorkOrdersFilteredByLocationAndMetricsLoading,
    filterValue,
    activeLocationTreeMap,
    isFilterActive
  } = useManagementDashboardStateContext();

  const { popupFilterControl } = useManagementDashboardConfigContext();

  const { workOrdersFilteredByLocationAndMetrics, setWorkOrdersFilteredByLocationAndMetrics } =
    useManagementDashboardDataSourceContext();

  const xemelgoAppsyncClient = useXemelgoAppsyncClient();

  const [managementDashboardAppsyncClient] = useState(xemelgoAppsyncClient.getManagementDashboardClient());

  const { build: buildFilterString } = useSolutionMetricsFilterStringBuilder();

  const filterStringForAPI = useMemo(() => {
    const idToTypeMap = popupFilterControl.reduce((accumulator, { id, type }) => {
      return { ...accumulator, [id]: type };
    }, {});
    return isFilterActive ? buildFilterString(filterValue, idToTypeMap) : "";
  }, [filterValue, popupFilterControl, isFilterActive]);

  const selectedLocation = useMemo(() => {
    if (selectedLocationId && Object.keys(activeLocationTreeMap).length) {
      return activeLocationTreeMap[selectedLocationId];
    }
    return null;
  }, [activeLocationTreeMap, selectedLocationId]);

  const childLocationCategory = useMemo(() => {
    let children = Object.keys(activeLocationTreeMap)
      .filter((eachLocationId) => {
        return activeLocationTreeMap[eachLocationId].directParentId === selectedLocationId;
      })
      .map((eachLocationId) => {
        return { ...activeLocationTreeMap[eachLocationId] };
      });

    if (viewMode === VIEW_MODE_MAP.expedited_parts_mode) {
      children = children.filter((eachChildrenLocation) => {
        return eachChildrenLocation.metrics.expedited > 0;
      });
    }

    return children[0]?.category || "";
  }, [activeLocationTreeMap, selectedLocationId, viewMode]);

  const getWorkOrderFilteredByLocationAndMetricsFunc = async () => {
    setWorkOrdersFilteredByLocationAndMetrics([]);
    setIsWorkOrdersFilteredByLocationAndMetricsLoading(true);

    if (selectedLocation?.id && selectedMetricType) {
      const [severity = "", status = ""] = selectedMetricType?.split("-") || [];
      const locationIds = selectedLocation.childLocations.filter((eachChildrenLocationId) => {
        return activeLocationTreeMap[eachChildrenLocationId].childLocations.length === 1;
      });
      const workOrders = selectedLocation?.metrics?.total
        ? await managementDashboardAppsyncClient.getWorkOrdersFilteredByLocationAndStatus(
            locationIds,
            status.trim(),
            severity.trim(),
            viewMode === VIEW_MODE_MAP.expedited_parts_mode,
            filterStringForAPI
          )
        : [];
      setWorkOrdersFilteredByLocationAndMetrics(naturalSort(workOrders, "identifier"));
    }

    setIsWorkOrdersFilteredByLocationAndMetricsLoading(false);
  };

  useEffect(() => {
    getWorkOrderFilteredByLocationAndMetricsFunc();
  }, [selectedMetricType, selectedLocation, viewMode, filterStringForAPI]);

  useEffect(() => {
    if (selectedLocation) {
      const { metrics } = selectedLocation;
      switch (viewMode) {
        case VIEW_MODE_MAP.expedited_parts_mode:
          if (metrics.expeditedTimeExceededCritical) {
            setSelectedMetricType(STATUS_TITLE_MAP.idleCritical);
          } else if (metrics.expeditedLateToStartCritical) {
            setSelectedMetricType(STATUS_TITLE_MAP.lateToStartCritical);
          } else if (metrics.expeditedTimeExceededWarning) {
            setSelectedMetricType(STATUS_TITLE_MAP.idleWarning);
          } else if (metrics.expeditedLateToStartWarning) {
            setSelectedMetricType(STATUS_TITLE_MAP.lateToStartWarning);
          } else if (
            metrics.expedited -
              metrics.expeditedLateToStartCritical -
              metrics.expeditedLateToStartWarning -
              metrics.expeditedTimeExceededCritical -
              metrics.expeditedTimeExceededWarning >
            0
          ) {
            setSelectedMetricType(STATUS_TITLE_MAP.healthy);
          } else {
            setSelectedMetricType(null);
          }
          break;
        case VIEW_MODE_MAP.floor_status_mode:
        default:
          if (metrics.timeExceededCritical + metrics.expeditedTimeExceededCritical) {
            setSelectedMetricType(STATUS_TITLE_MAP.idleCritical);
          } else if (metrics.lateToStartCritical + metrics.expeditedLateToStartCritical) {
            setSelectedMetricType(STATUS_TITLE_MAP.lateToStartCritical);
          } else if (metrics.timeExceededWarning + metrics.expeditedTimeExceededWarning) {
            setSelectedMetricType(STATUS_TITLE_MAP.idleWarning);
          } else if (metrics.lateToStartWarning + metrics.expeditedLateToStartWarning) {
            setSelectedMetricType(STATUS_TITLE_MAP.lateToStartWarning);
          } else {
            setSelectedMetricType(null);
          }
          break;
      }
    }
  }, [selectedLocation, viewMode]);

  const TableContent = useCallback(() => {
    switch (selectedTab) {
      case TAB_OPTION_MAP.locationListTab:
        return <LocationListContent />;

      case TAB_OPTION_MAP.jobStatusTab:
      default:
        return <JobStatusContent />;
    }
  }, [
    selectedTab,
    selectedLocation,
    viewMode,
    selectedMetricType,
    workOrdersFilteredByLocationAndMetrics,
    isWorkOrdersFilteredByLocationAndMetricsLoading
  ]);

  return (
    <>
      <div className={Style.information_header}>
        <div className={`${Style.flex_row} ${Style.information_title_container}`}>
          <p className={Style.information_title}>{selectedLocation?.name}</p>
          <div
            className={Style.information_title_status}
            style={{
              backgroundColor:
                STATUS_COLOR_MAP[
                  selectedLocation?.statuses[
                    viewMode === VIEW_MODE_MAP.expedited_parts_mode ? "expeditedStatus" : "status"
                  ]
                ]
            }}
          >
            {selectedLocation?.statuses[viewMode === VIEW_MODE_MAP.expedited_parts_mode ? "expeditedStatus" : "status"]}
          </div>
        </div>
        {childLocationCategory && (
          <div className={`${Style.flex_row} ${Style.information_tab_list}`}>
            <div
              className={`${Style.information_tab} ${
                selectedTab === TAB_OPTION_MAP.jobStatusTab && Style.information_tab_active
              }`}
              onClick={() => {
                setSelectedTab(TAB_OPTION_MAP.jobStatusTab);
              }}
            >
              Job Status
            </div>

            <div
              className={`${Style.information_tab} ${
                selectedTab === TAB_OPTION_MAP.locationListTab && Style.information_tab_active
              }`}
              onClick={() => {
                setSelectedTab(TAB_OPTION_MAP.locationListTab);
              }}
            >
              {`${childLocationCategory}s`}
            </div>
          </div>
        )}
      </div>
      <div className={`${Style.flex_column} ${Style.information_content}`}>
        <TableContent />
      </div>
    </>
  );
};
