import React from "react";
import PropTypes from "prop-types";
import AutoSizeTextArea from "components/AutoSizeTextArea/AutoSizeTextArea";
import Selector from "./Selector";
import InfoCard from "./InfoCard";
import Style from "./AddInventoryV2Components.module.css";
import { getStackedXemelgoLogo } from "../../common/Utilities";

const defaultXemelgoLogo = getStackedXemelgoLogo("dark");

const ItemTypeInformation = ({
  label,
  placeholder,
  selectItemTypeDisabled,
  itemTypeInput,
  setItemTypeInput,
  itemTypes,
  itemTypeInputLoading,
  onSelect,
  onDebounce,
  selectedItemType
}) => {
  return (
    <div className={Style.item_information_container}>
      <p className={Style.info_content_title}>Item Information</p>
      <p className={Style.info_content_label}>
        {label}
        <span className={Style.asterisk}> *</span>
      </p>
      {selectItemTypeDisabled ? (
        <div className={Style.input_container}>
          <AutoSizeTextArea
            autoFocus
            onChangeText={setItemTypeInput}
            value={itemTypeInput}
          />
        </div>
      ) : (
        <Selector
          debounceInput
          loading={itemTypeInputLoading}
          options={itemTypes}
          onSelect={(itemType) => {
            onSelect(itemType);
          }}
          onDebounce={onDebounce}
          value={selectedItemType?.name}
          placeholder={placeholder}
        />
      )}
      <div className={Style.item_information_card_container}>
        {!!Object.keys(selectedItemType).length && (
          <div className={Style.item_information_card}>
            <div className={Style.item_type_image_container}>
              <img
                className={Style.item_type_image}
                src={selectedItemType?.image_path?.value || defaultXemelgoLogo}
              />
            </div>
            <InfoCard info={selectedItemType} />
          </div>
        )}
      </div>
    </div>
  );
};

ItemTypeInformation.defaultProps = {
  selectedItemType: {},
  label: "",
  placeholder: "",
  selectItemTypeDisabled: false,
  itemTypeInput: "",
  setItemTypeInput: () => {}
};

ItemTypeInformation.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  selectItemTypeDisabled: PropTypes.bool,
  itemTypeInput: PropTypes.string,
  setItemTypeInput: PropTypes.func,
  itemTypes: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedItemType: PropTypes.object
};

export default ItemTypeInformation;
