import React from "react";
import messageCache from "./MessageCache";
import Loader from "./components/loader-component/LoaderComponent";
import { CHAT_BOT_EVENT, CHAT_BOT_STEPS } from "../constants/mixpanel-constant/chatbot";

const CHAT_API_URL = "https://ruq9jr4kze.execute-api.us-west-2.amazonaws.com/prod/chat/"; //"http://localhost:3004/chat";
const cache = messageCache;

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, sendMixPanelEventFn) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.sendMixPanelEventFn = sendMixPanelEventFn;
  }

  handleException = () => {
    this.setState((prev) => {
      // Remove loading here
      const newPrevMsg = prev.messages.slice(0, -1);
      return { ...prev, messages: [...newPrevMsg] };
    });
    const botMessage = this.createChatBotMessage(
      "Apologies, but I don't quite understand how to process your last request. I am still learning how to evaluate and process this data, so please try again later."
    );

    this.updateChatbotState(botMessage);
  };

  handleQuestion = (message, authHeader, faqClicked = false) => {
    // inserts a user message if they clicked on a FAQ example
    if (faqClicked) {
      const userMessage = { message: message, type: "user", id: new Date().getTime() };
      this.updateChatbotState(userMessage);
    }

    this.sendMixPanelEventFn(CHAT_BOT_EVENT, CHAT_BOT_STEPS.QUESTION_ASKED, { question: message });
    this.askQuestion(message, authHeader)
      .then((data) => {
        this.handleInventory(data);
      })
      .catch((err) => {
        this.handleInventory(err);
      });
  };

  async askQuestion(message, authHeader) {
    // Add loading before API call
    const loading = this.createChatBotMessage(<Loader />);
    this.updateChatbotState(loading);

    const request = new Request(`${CHAT_API_URL}?question="${message}"`, {
      method: "POST",
      body: JSON.stringify(cache.messages),
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${authHeader}`
      })
    });
    const response = await fetch(request);
    const data = await response.json();
    cache.messages.push({ message: message });
    return data;
  }

  rateLimitMessagePosting = async (messages, delay = 500) => {
    this.setState((prev) => {
      // Remove loading here
      const newPrevMsg = prev.messages.slice(0, -1);
      return { ...prev, messages: [...newPrevMsg] };
    });

    for (const msg of messages) {
      this.updateChatbotState(msg);
      // wait 1 second before sending the next message
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  };

  handleS3 = async (data) => {
    try {
      const messages = [];
      messages.push(this.createChatBotMessage("Here are the requested S3 details as of today:"));
      data.forEach((item) => {
        messages.push(
          this.createChatBotMessage(
            Object.keys(item)
              .map((key) => `${key}: ${item[key]}`)
              .join("\n")
          )
        );
      });

      this.rateLimitMessagePosting(messages);
    } catch (error) {
      this.handleException();
    }
  };

  isValidResponse = (data) => {
    return data && ((data.hint && data.type) || (data.items && data.message));
  };

  handleInventory = (data) => {
    try {
      if (data.items?.length === 0) {
        this.rateLimitMessagePosting([this.createChatBotMessage("No items were found matching the search criteria")]);
        return;
      }

      if (this.isValidResponse(data)) {
        if (data.hint === "unknown" && data.type === "none") {
          this.rateLimitMessagePosting([this.createChatBotMessage(data.prompt)]);
          return;
        }

        const messages = [];

        const message = data.message ? data.message : "Here are the results of your inquiry as of today:";

        messages.push(
          this.createChatBotMessage(message, {
            widget: "resultTable",
            payload: data
          })
        );

        this.rateLimitMessagePosting(messages);
      } else {
        this.handleException();
      }
    } catch (error) {
      this.handleException();
    }
  };

  handleGreeting = () => {
    const botMessage = this.createChatBotMessage("Hello there! What can I help you with today?");

    this.updateChatbotState(botMessage);
  };

  updateChatbotState(message) {
    // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.

    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }));
  }
}

export default ActionProvider;

// import React from 'react';
// import messageCache from './MessageCache';
// import Loader
//  from './components/loader-component/LoaderComponent';

// const CHAT_API_URL = "https://bcbzl7y188.execute-api.us-west-2.amazonaws.com/Prod/chat/"; //"http://localhost:3004/chat";
// const cache = messageCache;

// const ActionProvider = ({ createChatBotMessage, setState, children }) => {

//   const handleGreeting = () => {
//     const botMessage = createChatBotMessage('Hello there! What can I help you with today?');

//     setState((prev) => ({
//       ...prev,
//       messages: [...prev.messages, botMessage],
//     }));
//   };

//   const handleException = (data) => {
//     const botMessage = createChatBotMessage("Apologies, but I don't quite understand how to process your last request. I am still learning how to evaluate and process this data, so please try again later.");

//     setState((prev) => ({
//       ...prev,
//       messages: [...prev.messages, botMessage],
//     }));
//   };

//   const handleQuestion = (message, authHeader) => {
//     askQuestion(message, authHeader).then(data => {
//       handleInventory(data);
//     }).catch(err => {
//       setState((prev) => {
//         // Remove loading here
//         const newPrevMsg = prev.messages.slice(0, -1)
//         return { ...prev, messages: [...newPrevMsg], }
//     })
//       handleInventory(err);
//     });
//   }

//   async function askQuestion(message, authHeader) {
//     // Add loading before API call
//     const loading = createChatBotMessage(<Loader />)
//     setState((prev) => ({
//       ...prev,
//       messages: [...prev.messages, loading],
//     }));

//     const request = new Request(`${CHAT_API_URL}?question="${message}"`, {
//       method: 'POST',
//       body: JSON.stringify(cache.messages),
//       headers: new Headers({
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${authHeader}`
//       })
//     });
//     const response = await fetch(request)
//     const data = await response.json();
//     cache.messages.push({ message: message });
//     return data;

//   }

//   const rateLimitMessagePosting = async (messages, delay = 500) => {
//     setState((prev) => {
//       // Remove loading here
//       const newPrevMsg = prev.messages.slice(0, -1)
//       return { ...prev, messages: [...newPrevMsg], }
//     })

//     for (const msg of messages) {
//       setState((prev) => ({
//         ...prev,
//         messages: [...prev.messages, msg],
//       }));
//       // wait 1 second before sending the next message
//       await new Promise((resolve) => setTimeout(resolve, delay));
//     }
//   };

//   const handleS3 = async (data) => {
//     try {
//       const messages = [];
//       messages.push(createChatBotMessage("Here are the requested S3 details as of today:"));
//       data.forEach((item) => {
//         messages.push(createChatBotMessage(Object.keys(item).map((key) => `${key}: ${item[key]}`).join("\n")))
//       });

//       rateLimitMessagePosting(messages);
//     } catch (error) {
//       handleException();
//     }
//   };

//   const isValidResponse = (data) => {
//     return data && ((data.hint && data.type) || (data.items && data.message));
//   }

//   const handleInventory = (data) => {
//     try {
//       if (data.items?.length === 0) {
//         rateLimitMessagePosting([createChatBotMessage("No items were found matching the search criteria")]);
//         return
//       }

//       if (isValidResponse(data)) {
//         if (data.hint === "unknown" && data.type === "none") {
//           rateLimitMessagePosting([createChatBotMessage(data.prompt)]);
//           return
//         }

//         const messages = [];

//         const message = data.message ? data.message : "Here are the results of your inquiry as of today:";

//         messages.push(createChatBotMessage(message,
//           {
//             widget: 'resultTable',
//             payload: data,
//           }));

//         rateLimitMessagePosting(messages);
//       } else {
//         handleException();
//       }

//     } catch (error) {
//       handleException();
//     }
//   };

//   const handleJavascriptList = () => {
//     const botMessage = createChatBotMessage(
//       "Fantastic, I've got the following resources for you on Javascript:"

//     );
//     setState((prev) => ({
//       ...prev,
//       messages: [...prev.messages, botMessage],
//     }));
//   }

//   // Put the handleHello function in the actions object to pass to the MessageParser
//   return (
//     <div>
//       {React.Children.map(children, (child) => {
//         return React.cloneElement(child, {
//           actions: {
//             handleInventory,
//             handleS3,
//             handleGreeting,
//             handleJavascriptList,
//             handleQuestion
//           },
//         });
//       })}
//     </div>
//   );
// };

// export default ActionProvider;
