import React, { useState, useMemo, useEffect } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Responsive, WidthProvider } from "react-grid-layout";
import _ from "lodash";
import useCustomizableDashboardDataSourceContext from "../../contexts/customizable-dashboard-data-source-context";
import useCustomizableDashboardStateContext from "../../contexts/customizable-dashboard-state-context";
import useWidgetHelper from "../../hooks/use-widget-helper";
import Style from "./DashboardGrid.module.css";
import { ReactComponent as RemoveIcon } from "../../../../assets/icons/requirement_not_met.svg";
import { WidgetSharableContextProvider } from "./contexts/widget-sharable-context";
import "./DashboardGrid.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

export const DashboardGrid = () => {
  const { getWidgetComponentById } = useWidgetHelper();

  const { storedLayouts } = useCustomizableDashboardDataSourceContext();

  const { isEditMode, currentViewTabIndex, editingLayouts, setEditingLayouts } = useCustomizableDashboardStateContext();

  const [isDragging, setIsDragging] = useState(false);

  const displayedLayout = useMemo(() => {
    return ((isEditMode ? editingLayouts[currentViewTabIndex] : storedLayouts[currentViewTabIndex])?.layout || []).map(
      (eachLayout) => {
        return { ...eachLayout, static: !isEditMode };
      }
    );
  }, [isEditMode, currentViewTabIndex, editingLayouts, storedLayouts]);

  const onLayoutChange = (newLayout) => {
    const newEditingLayout = _.cloneDeep(editingLayouts);
    if (newEditingLayout?.[currentViewTabIndex]?.layout) {
      newEditingLayout[currentViewTabIndex].layout = newLayout;
      setEditingLayouts(newEditingLayout);
    }
  };

  const onRemoveWidget = (widgetId) => {
    const newEditingLayout = _.cloneDeep(editingLayouts);
    newEditingLayout[currentViewTabIndex].layout = newEditingLayout[currentViewTabIndex].layout.filter(({ i }) => {
      return i !== widgetId;
    });
    setEditingLayouts(newEditingLayout);
  };

  const getWidget = (id) => {
    return (
      <div
        className={`${Style.grid_item_container} ${isEditMode && Style.grid_item_container_editable}`}
        key={id}
      >
        {isEditMode && (
          <div
            className={Style.grid_item_remove_button}
            onClick={() => {
              onRemoveWidget(id);
            }}
          >
            <RemoveIcon className={Style.grid_item_remove_icon} />
          </div>
        )}
        <div className={Style.grid_item_widget_container}>{getWidgetComponentById(id)}</div>
      </div>
    );
  };

  return (
    <WidgetSharableContextProvider>
      <ResponsiveGridLayout
        margin={[20, 20]}
        layouts={{ lg: displayedLayout }}
        rowHeight={25}
        className={`${Style.grid_layout} ${isDragging && Style.grid_layout_editable}`}
        // breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 10, md: 4, sm: 3, xs: 2, xxs: 1 }}
        //   compactType={null}
        onLayoutChange={onLayoutChange}
        onDragStart={() => {
          setIsDragging(true);
        }}
        onDragStop={() => {
          setIsDragging(false);
        }}
      >
        {displayedLayout.map(({ i }) => {
          return getWidget(i);
        })}
      </ResponsiveGridLayout>
    </WidgetSharableContextProvider>
  );
};
