import React, { useContext, useState, useEffect } from "react";

const WidgetSharableContext = React.createContext();

const initialState = {
  selectedStrategicSourcingTableId: "",
  selectedHeatMapTableId: ""
};

export const useWidgetSharableContext = () => {
  return useContext(WidgetSharableContext);
};

export const WidgetSharableContextProvider = ({ children }) => {
  const [selectedStrategicSourcingTableId, setSelectedStrategicSourcingTableId] = useState(
    initialState.selectedStrategicSourcingTableId
  );
  const [selectedHeatMapTableId, setSelectedHeatMapTableId] = useState(initialState.selectedHeatMapTableId);
  return (
    <WidgetSharableContext.Provider
      value={{
        selectedStrategicSourcingTableId,
        setSelectedStrategicSourcingTableId,
        selectedHeatMapTableId,
        setSelectedHeatMapTableId
      }}
    >
      {children}
    </WidgetSharableContext.Provider>
  );
};
