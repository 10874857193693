import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useStore } from "react-hookstore";
import { userProfileStore } from "state-managements/stores/user-profile-store";
import { fullScreenModeStore } from "../../state-managements/stores/full-screen-mode-store";
import { useDisplayBannerContext } from "../../context/DisplayBannerContext/DisplayBannerContext";
import MainAppComponent from "../../components/main-app-component/MainAppComponent";
import HeaderComponent from "../../components/header-component/HeaderComponent";
import MainNavigationBar from "../main-navigation-bar/MainNavigationBar";
import RenderComponent from "../../components/render-component/RenderComponent";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import MainAppFeatureStyle from "./MainAppFeature.module.css";
import { LocalCacheService } from "../../services/local-cache-service";
import Chatbot from "chatbot/Chatbot";

const DEFAULT_SIDE_NAVIGATION_COLLAPSED_STATE = false;
const DEFAULT_CHAT_COLLAPSED_STATE = false;

const MainAppFeature = ({ render }) => {
  const [userProfile] = useStore(userProfileStore);
  const [fullScreenMode] = useStore(fullScreenModeStore);
  const [sideNavigationCollapsed, setSideNavigationCollapsed] = useState(
    LocalCacheService.getSideNavigationHorizontalCollapsed() || DEFAULT_SIDE_NAVIGATION_COLLAPSED_STATE
  );
  const [chatbotCollapsed, setChatbotCollapsed] = useState(
    LocalCacheService.getChatbotCollapsed() || DEFAULT_CHAT_COLLAPSED_STATE
  );

  const { pathname } = useLocation();

  useEffect(() => {
    if (userProfile) {
      window.fcWidget.init({
        token: "8b71db32-33e9-4423-916d-966821e989a0",
        host: "https://wchat.freshchat.com",
        externalId: userProfile.userId
      });
      window.fcWidget.user.get(function (resp) {
        const status = resp && resp.status;
        // Status 200 is from Freschat API docs -- It means that there is a user registered using the chat
        if (status !== 200) {
          window.fcWidget.user.setProperties({
            firstName: userProfile.firstName, // user's first name
            lastName: userProfile.lastName, // user's last name
            email: userProfile.email, // user's email address
            phone: userProfile.phoneNumber
          });
          window.fcWidget.on("user:created", function (onCreatedResp) {
            const onCreatedRespStatus = onCreatedResp && onCreatedResp.status;
            // TODO: this part of initalization will check if the user exists and if so, we use restoreID to initialize
            // chat with previous conversation if logging on to the web app via different machines
            if (onCreatedRespStatus === 200) {
              // Update restoreId in your database
            }
          });
        }
      });
    }
  }, [userProfile]);

  const testingMode = sessionStorage.getItem("CustomerTestMode") === "Test";
  let mainAppStyle;
  let headerContainerStyle;
  let sideNavigationContainerStyle;
  let chatbotContainerStyle;

  const { showBanner, setShowBanner, bannerHasError, bannerTitle } = useDisplayBannerContext();

  if (fullScreenMode) {
    if (testingMode) {
      mainAppStyle = MainAppFeatureStyle.main_app_component_full_screen_container_test;
      headerContainerStyle = MainAppFeatureStyle.header_fullscreen_container_test;
    } else {
      mainAppStyle = MainAppFeatureStyle.main_app_component_full_screen_container;
    }
  } else if (testingMode) {
    if (sideNavigationCollapsed) {
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_test_collapsed;
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_test_collapsed;
    } else {
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_test;
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_test;
    }
    headerContainerStyle = MainAppFeatureStyle.header_component_container_test;
  } else {
    if (sideNavigationCollapsed && chatbotCollapsed) {
      // both closed
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_collapsed; // full container view
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_collapsed; // collapsed nav
    } else if (sideNavigationCollapsed) {
      // just chat expanded
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_chat_expanded; // container view w chat expanded
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container_collapsed; // collapsed nav
    } else if (chatbotCollapsed) {
      // just side nav expanded
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container; // expanded nav
      mainAppStyle = MainAppFeatureStyle.main_app_component_container; // container view w nav expanded
    } else {
      // both chat & side nav expanded
      mainAppStyle = MainAppFeatureStyle.main_app_component_container_full_condensed; // smallest container view w both
      sideNavigationContainerStyle = MainAppFeatureStyle.side_navigation_container; // expanded nav
    }
    headerContainerStyle = MainAppFeatureStyle.header_component_container;
  }
  return (
    <MainAppComponent
      containerStyle={mainAppStyle}
      headerComponent={() => {
        return (
          <HeaderComponent
            containerStyle={headerContainerStyle}
            fullScreenMode={fullScreenMode}
          />
        );
      }}
      sideNavigationComponent={() => {
        return (
          !fullScreenMode && (
            <MainNavigationBar
              navbarCollapsed={sideNavigationCollapsed}
              onNavbarCollapse={() => {
                setSideNavigationCollapsed(!sideNavigationCollapsed);
                LocalCacheService.saveSideNavigationHorizontalCollapsed(!sideNavigationCollapsed);
              }}
              containerStyle={sideNavigationContainerStyle}
            />
          )
        );
      }}
      renderComponent={() => {
        return (
          <>
            {!pathname.startsWith("/gpt") && (
              <div
                className={`${MainAppFeatureStyle.banner_container} ${
                  !showBanner && MainAppFeatureStyle.banner_container_collapsed
                }`}
              >
                {showBanner && (
                  <DisplayBanner
                    bannerError={bannerHasError}
                    onCloseBanner={() => {
                      setShowBanner(false);
                    }}
                    bannerMessage={bannerTitle}
                  />
                )}
              </div>
            )}
            <RenderComponent
              containerStyle={
                pathname.startsWith("/gpt")
                  ? MainAppFeatureStyle.fullscreen_render_component_container
                  : MainAppFeatureStyle.render_component_container
              }
              renderer={render}
            />
          </>
        );
      }}
      chatComponent={() => {
        if (!pathname.startsWith("/gpt")) {
          return (
            <Chatbot
              chatbotCollapsed={chatbotCollapsed}
              onChatbotCollapse={() => {
                if (chatbotCollapsed) {
                  // hide freshchat when chatbot is open
                  window.fcWidget.hide();
                } else {
                  // show freshchat when chatbot is closed
                  window.fcWidget.show();
                }
                setChatbotCollapsed(!chatbotCollapsed);
                LocalCacheService.saveChatbotCollapsed(!chatbotCollapsed);
              }}
              containerStyle={chatbotContainerStyle}
            />
          );
        }
      }}
    />
  );
};

export default withRouter(MainAppFeature);

MainAppFeature.propTypes = {
  render: PropTypes.element.isRequired
};
