import React, { useEffect, useState } from "react";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import LoadingCircle from "../../../../components/loading/LoadingCircle";
import { ModalForm } from "../../../../components/modal-form";
import InventoryModalHeader from "../../../../components/add-inventory-v2-components/InventoryModalHeader";
import InventoryModalFooter from "../../../../components/add-inventory-v2-components/InventoryModalFooter";
import "./AddTransferOrderStyle.css";
import {
  useAddTransferOrderFeatureConfigContext
} from "./contexts/add-transfer-order-feature-config-context/AddTransferOrderFeatureConfigContext";
import BulkCreateTransferOrderFeature from "./features/bulk-create-transfer-order-feature";
import { 
  useAddTransferOrderFeatureStateContext
} from "./contexts/add-transfer-order-feature-state-context/AddTransferOrderFeatureStateContext";
import validateCsvData from "./utils/validate-transfer-order-csv-data";

class TransferOrderPayloadItem {
  fields = {
    // string
    aggregation_method: String,

    // number
    quantity_total: Number
  }

  connections = {
    // string (id of node)
    ofItemType: String
  }
}

export const AddTransferOrderFeature = ({ onClose }) => {
  const [itemTypeClient] = useState(useXemelgoClient().getItemTypeClient());
  const [transferClient] = useState(useXemelgoClient().getTransferClient());
  const [locationClient] = useState(useXemelgoClient().getLocationClient());

  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [headerMessage, setHeaderMessage] = useState("");
  const [error, setError] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const {
    useDefaultLocation,
    fromLocationDefault,
    toLocationDefault,
    transferOrderTitleLabel,
    submitLabel,
    allowMultipleTransferOrdersPerFile
  } = useAddTransferOrderFeatureConfigContext();
  
  const { formData, setFormData } = useAddTransferOrderFeatureStateContext();
  
  useEffect(() => {
    onLoad();
    // hide fc_chat widget
    window.fcWidget.hide();
    return () => {
      // show fc_chat widget
      window.fcWidget.show();
    };
  }, []);

  useEffect(() => {
    // disable the submit button if we have no data
    setSubmitButtonDisabled(!formData || formData.length === 0);
    // set the header back to empty if we have new data
    if (formData) {
      setHeaderMessage("");
    }
  }, [formData]);

  const onLoad = async () => {
    setError(false);
    setHeaderMessage("");
    setSubmitButtonDisabled(false);
    setLoading(false);
  };

  /**
   * Submits transfer orders and creates transfer order items.
   * @param {Array} transferOrders - The array of transfer orders to be submitted.
   * @returns {Object} - A dictionary of responses for each transfer order, with the tracking number as the key.
   */
  const submitTransferOrder = async (transferOrders) => {
    // first we need to iterate through all the items to extract their itemTypes
    const itemTypeIdentifiers = [];
    transferOrders.forEach((transferOrder) => {
      transferOrder.items.forEach((item) => {
        itemTypeIdentifiers.push(item.itemType);
      });
    });

    // break it into slices of 50 to manage query sizes
    const itemTypeIdentifierSlices = [];
    for (let i = 0; i < itemTypeIdentifiers.length; i += 50) {
      itemTypeIdentifierSlices.push(itemTypeIdentifiers.slice(i, i + 50));
    }

    // for each slice, issue a query to get the itemTypes and use the results to create a dictionary
    const itemTypeIds = {};
    for (let i = 0; i < itemTypeIdentifierSlices.length; i++) {
      const itemTypeIdentifiers = itemTypeIdentifierSlices[i];
        const itemTypeQuery = await itemTypeClient.getItemTypeByIdentifiers(itemTypeIdentifiers);

        itemTypeQuery.forEach((itemType) => {
          itemTypeIds[itemType.identifier] = itemType.id;
        });
      }

    // now we need to actually issue the command to create each transfer order
    // this will be a dictionary of Promises from the responses, with the key being the tracking number
    const responses = await Promise.all(
      transferOrders.map(async (transferOrder) => {
        const transferItems = [];
        for (let j = 0; j < transferOrder.items.length; j++) {
          const item = transferOrder.items[j];
          const transferItem = new TransferOrderPayloadItem();
          transferItem.fields = {
            aggregation_method: "Manual",
            quantity_total: parseInt(item.quantity),
          };

          transferItem.connections = {
            ofItemType: itemTypeIds[item.itemType],
          };
          transferItems.push(transferItem);
        }

        // tricky, as this can have the same location as the source and destination, which would result in only a single result.
        // So we need to look it up via the identifier. But still get it as a single API call for efficiency reasons.
        const locationIds = await getLocationIds([transferOrder.fromLocation, transferOrder.toLocation]);
        const fromLocationId = locationIds.find((location) => location.identifier === transferOrder.fromLocation)?.id;
        const toLocationId = locationIds.find((location) => location.identifier === transferOrder.toLocation)?.id;

        if (!fromLocationId) {
          throw new Error(`Could not find location '${transferOrder.fromLocation}' for transfer order '${transferOrder.trackingNumber}'`);
        }
        if (!toLocationId) {
          throw new Error(`Could not find location '${transferOrder.toLocation}' for transfer order '${transferOrder.trackingNumber}'`);
        }

        const thisTrackingResponse = {
          trackingNumber: transferOrder.trackingNumber,
          error: null,
          success: false,
          response: null,
        };
        try {
          thisTrackingResponse.response = await transferClient.createTransferOrder(
            transferOrder.trackingNumber,
            fromLocationId,
            toLocationId,
            transferItems
          );
          thisTrackingResponse.success = true;
        } catch (e) {
          thisTrackingResponse.error = e;
        }
        return thisTrackingResponse;
      })
    );

    const responsesDictionary = {};
    responses.forEach((response) => {
      responsesDictionary[response.trackingNumber] = response;
    });

    return responsesDictionary;
  };

  /**
   * Retrieves location IDs based on the given location identifiers.
   *
   * @param {string[]} locationIdentifiers - An array of location identifiers.
   * @returns {Promise<{ id: string, identifier: string }[]>} - A promise that resolves to an array of location objects containing their IDs and identifiers.
   */
  const getLocationIds = async (locationIdentifiers) => {
    return (await locationClient.getLocationsByIdentifiers(locationIdentifiers)).map((location) => { return { id: location.id, identifier: location.identifier }; } );
  }

  const mapErrorResponseToHeaderMessage = (response) => {
    let reason = response.error.message;
    if (reason.includes("status code 409")) {
      reason = "already exists";
    }
    let message = `${transferOrderTitleLabel} '${response.trackingNumber}' failed to create: ${reason}`;
    return message;
  }

  const onSubmit = async () => {
    setHeaderMessage("");
    setError(false);
    setLoading(true);
    setLoadingMessage(`Creating ${transferOrderTitleLabel}...`);
    setSubmitButtonDisabled(true);

    const trackingNumbers = [];
    const getTransferOrder = (trackingNumber) => {
      if (!trackingNumber) {
        throw new Error("Tracking number is required");
      }
      let index = trackingNumbers.findIndex((tn) => tn.trackingNumber === trackingNumber);
      if (index === -1) {
        // does not exist. Add it and return the index (which is the length - 1)
        index = trackingNumbers.push({ 
          trackingNumber: trackingNumber, 
          items: [],
          fromLocation: useDefaultLocation ? fromLocationDefault : null,
          toLocation: useDefaultLocation ? toLocationDefault : null
        }) - 1;
      }
      return trackingNumbers[index];
    }
    
    try {
      // convert the csv data into an object
      validateCsvData(formData, getTransferOrder, useDefaultLocation);

      // check to see if we have multiple tracking numbers... if so, check to see if that is allowed
      if (trackingNumbers.length > 1 && !allowMultipleTransferOrdersPerFile) {
        throw new Error(`CSV Must contain only a single ${transferOrderTitleLabel}`);
      }

      // now submit the transfer order
      const results = await submitTransferOrder(trackingNumbers);

      // now we need to check the responses for errors
      const errors = [];
      for (let i = 0; i < trackingNumbers.length; i++) {
        const trackingNumber = trackingNumbers[i];
        const response = results[trackingNumber.trackingNumber];
        if (!response.success) {
          errors.push(mapErrorResponseToHeaderMessage(response));
        }
      }
      if (errors.length > 0) { 
        throw new Error(errors.join("\n"));
      }
      setError(false);
      setHeaderMessage(`${transferOrderTitleLabel} created successfully`);
    } catch (e) {
      setError(true);
      setHeaderMessage(e.message);
    } finally {
      setFormData(null);
      setLoading(false);
    }
  };

  return (
    <ModalForm
      scrollable
      show
      title={
        <InventoryModalHeader
          onClose={onClose}
          error={error}
          headerMessage={headerMessage}
          titleText={"Add " + transferOrderTitleLabel}
        />
      }
      body={
        loading ? (
          <LoadingCircle
            message={loadingMessage}
            messageStyle="loading_message"
          />
        ) : (
          <div className={"modal_body"}>
            <BulkCreateTransferOrderFeature />
          </div>
        )
      }
      footer={
        <InventoryModalFooter
          onClose={onClose}
          onSubmit={onSubmit}
          disabled={submitButtonDisabled}
          submitLabel={submitLabel}
        />
      }
      prefix="inv"
      className="inv_modal_container"
    />
  );
};