import React from "react";
import { useAddTransferOrderFeatureConfigContext } from "../../contexts/add-transfer-order-feature-config-context/AddTransferOrderFeatureConfigContext";
import UploadCsvComponent from "../../../../../../components/upload-csv-component";
import { SOLUTIONS } from "../../../../../../data/constants";
import { useAddTransferOrderFeatureStateContext } from "../../contexts/add-transfer-order-feature-state-context/AddTransferOrderFeatureStateContext";
import Style from "./BulkCreateTransferOrderFeature.module.css";
export const BulkCreateTransferOrderFeature = () => {
  const { transferOrderCsvHeadersMap } = useAddTransferOrderFeatureConfigContext();

  const { setFormData } = useAddTransferOrderFeatureStateContext();

  return (
    <div className={Style.upload_container}>
      <UploadCsvComponent
        csvHeadersMap={transferOrderCsvHeadersMap}
        onFileInput={setFormData}
        solution={SOLUTIONS.INVENTORY}
      />
    </div>
  );
};
