import React, { useEffect, useState } from "react";
import useWorkOrderTrackPageConfigContext from "../../contexts/work-order-track-page-config-context";
import useWorkOrderTrackPageStateContext from "../../contexts/work-order-track-page-state-context";
import SideFilter from "../../../side-filter/components/side-filter/SideFilter";
import { useSideFilterConfigBuilder } from "./hooks/use-filter-configuration/useFilterConfiguration";
import { TAB_OPTION_MAP } from "../../data/constants";
import {
  WORK_ORDER_TRACK_PAGE_V2_EVENT,
  WORK_ORDER_TRACK_PAGE_V2_STEPS
} from "../../../../constants/mixpanel-constant/workOrderTrackpageV2";
import useMixpanelContext from "../../../../context/mixpanel-context";

export const SideFilters = () => {
  const { sideFilter } = useWorkOrderTrackPageConfigContext();
  const { sendMixPanelEvent } = useMixpanelContext();

  const { filterConfiguration, initialFilterValue, applySideFilters, isLoading } =
    useSideFilterConfigBuilder(sideFilter);

  const [sideFilterValue, setSideFilterValue] = useState(initialFilterValue);
  const [isTelemetrySent, setIsTelemetrySent] = useState(false);

  const { setApplySideFilterFn, selectedTab } = useWorkOrderTrackPageStateContext();

  useEffect(() => {
    setSideFilterValue(initialFilterValue);
  }, [initialFilterValue]);

  useEffect(() => {
    setApplySideFilterFn(() => {
      return (item) => {
        return applySideFilters(filterConfiguration, sideFilterValue, item);
      };
    });
  }, [sideFilterValue]);

  if (selectedTab === TAB_OPTION_MAP.orderTab) {
    return (
      <SideFilter
        isLoading={isLoading}
        filterConfiguration={filterConfiguration}
        initialFilterValues={{ ...sideFilterValue }}
        onFilterChange={({ allValues }) => {
          if (!isTelemetrySent) {
            setIsTelemetrySent(true);
            sendMixPanelEvent(WORK_ORDER_TRACK_PAGE_V2_EVENT, WORK_ORDER_TRACK_PAGE_V2_STEPS.SIDE_FILTER_APPLIED);
          }
          setSideFilterValue(allValues);
        }}
      />
    );
  }
  return null;
};
