import {
  binaryToHex,
  decimalToBinary,
  getCurrentTimestamp,
  getFormattedDate
} from "../../../../../../common/Utilities";
import { useXemelgoClient } from "../../../../../../services/xemelgo-service";
import generateCustomIdentifier from "../../../../utils/generate-custom-identifier";
import { useAddInventoryFeatureV2ConfigContext } from "../../contexts/add-inventory-feature-v2-config-context";

const NO_LOCATION_ID = "no_location";

export const useInventoryFormDataParser = () => {
  const { encodeTag, targetLocationEnabled, singlePrint, customItemIdentifierTemplate, formHeadersMap } =
    useAddInventoryFeatureV2ConfigContext();

  const xemelgoClient = useXemelgoClient();
  const locationClient = xemelgoClient.getLocationClient();

  const generateSGTINPayload = (itemTypeIdentifier, selectedLocation, quantity = 1) => {
    const payloads = [];

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const timeString = getFormattedDate(Date.now(), "MMDDHHmm");
      const paddedString = i.toString().padStart(10 - timeString.length, "0");
      // arbitrary 2 as it lets us get more bits
      const serial = `2${timeString}${paddedString}`;
      const tag = generateSGTIN96Tag(itemTypeIdentifier, serial);
      const payload = {
        item_number: itemTypeIdentifier,
        tracker_serial: tag,
        name: serial,
        item_identifier: serial
      };
      if (targetLocationEnabled && Object.keys(selectedLocation).length) {
        payload.initial_location = selectedLocation.identifier;
      }
      payloads.push(payload);
    }

    return payloads;
  };

  const generateSGTIN96Tag = (UPC, serialToGenerate) => {
    // this is a header for a SGTIN96, Point of Sale tag with a partition of 5
    const prefix = "00110000001101";
    const paddedUPC = `0${UPC}`;
    const companyPrefix = decimalToBinary(paddedUPC.substring(0, 7)).padStart(24, "0");
    const itemNumber = decimalToBinary(paddedUPC.substring(7, paddedUPC.length - 1)).padStart(20, "0");
    const binarySerial = decimalToBinary(serialToGenerate).padStart(38, "0");
    return binaryToHex(`${prefix}${companyPrefix}${itemNumber}${binarySerial}`).toUpperCase();
  };

  const generateTags = (quantity = 1) => {
    // to make sure every vid is unique
    const tags = [];

    const timeStamp = getCurrentTimestamp();
    const date = getFormattedDate(timeStamp, "YYMMDD");

    const startingSerial = 1;
    for (let i = startingSerial; i <= quantity; i++) {
      const paddedString = i.toString().padStart(5, "0");
      tags.push(`${date}${timeStamp}${paddedString}`);
    }
    return tags;
  };

  const generatePayload = async (formData) => {
    const payloadsByLocationIdMap = {};

    const locationIdentifierList = formData.map((eachForm) => {
      return eachForm?.location;
    });

    const locationInfoMap = (await locationClient.getLocationsByIdentifiers(locationIdentifierList)).reduce(
      (locationMap, eachLocation) => {
        const { identifier } = eachLocation;
        locationMap[identifier] = eachLocation;
        return locationMap;
      },
      {}
    );

    for (let rowIndex = 0; rowIndex < formData.length; rowIndex++) {
      let payloads = [];
      const {
        item_number: itemNumber,
        print_quantity: printQuantity,
        location,
        ...additionalProperties
      } = formData[rowIndex];
      let newVids = [];
      const quantityToSubmit = singlePrint ? 1 : printQuantity;

      // await 1s for each row to ensure vids are unique
      await new Promise((resolve) => {
        return setTimeout(resolve, 1);
      });

      // generate vid
      if (encodeTag) {
        payloads = generateSGTINPayload(itemNumber, locationInfoMap[location], quantityToSubmit);
      } else {
        newVids = generateTags(quantityToSubmit);

        newVids.forEach((tag) => {
          const itemPayload = {
            item_number: itemNumber,
            tracker_serial: tag,
            name: tag
          };
          payloads.push(itemPayload);
        });
      }

      // add target location and other custom fields to the payload
      payloads = payloads.map((itemPayload) => {
        if (targetLocationEnabled && Object.keys(location).length) {
          itemPayload.initial_location = locationInfoMap[location].identifier;
        }

        const newItemPayload = Object.keys(additionalProperties).reduce(
          (tempItemPayload, id) => {
            if (additionalProperties[id]) {
              switch (formHeadersMap[id].type) {
                case "date":
                case "datepicker":
                  tempItemPayload[id] = additionalProperties[id].getTime();
                  break;
                default:
                  tempItemPayload[id] = formHeadersMap[id].numberOnly
                    ? parseFloat(additionalProperties[id])
                    : additionalProperties[id];
              }
            }
            return tempItemPayload;
          },
          { ...itemPayload }
        );

        if (customItemIdentifierTemplate) {
          const generatedIdentifier = generateCustomIdentifier(newItemPayload, customItemIdentifierTemplate);
          newItemPayload.item_identifier = generatedIdentifier;
        }
        return newItemPayload;
      });

      // map payloads by location
      const locationId = locationInfoMap[location]?.id || NO_LOCATION_ID;
      if (!payloadsByLocationIdMap[locationId]) {
        payloadsByLocationIdMap[locationId] = [];
      }
      payloadsByLocationIdMap[locationId].push(...payloads);
    }

    return Object.keys(payloadsByLocationIdMap).map((locationId) => {
      const payloads = payloadsByLocationIdMap[locationId];

      const newLocationId = locationId === NO_LOCATION_ID ? null : locationId;
      return { payloads, locationId: newLocationId };
    });
  };

  return {
    generatePayload
  };
};
