import React, { useContext, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CUSTOMIZABLE_DASHBOARD_EVENT,
  CUSTOMIZABLE_DASHBOARD_STEPS
} from "../../../../constants/mixpanel-constant/customizableDashboard";
import useAuthenticationContext from "../../../../context/authentication-context";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import useMixpanelContext from "../../../../context/mixpanel-context";

const CustomizableDashboardDataSourceContext = React.createContext();
// sud todo should use height/width from definition?
const newLayoutSource = [
  {
    id: "default-overview",
    layout: [
      {
        h: 6,
        i: "CrossFunctionalMetrics",
        w: 10,
        x: 0,
        y: 0
      },
      {
        i: "stockRecommendations",
        h: 12,
        w: 5,
        x: 0,
        y: 6
      },
      {
        i: "insights",
        h: 12,
        w: 5,
        x: 5,
        y: 6
      },
      {
        i: "siteMap",
        w: 5,
        h: 12,
        x: 0,
        y: 18
      },
      {
        i: "linkGroup",
        w: 5,
        h: 12,
        x: 5,
        y: 18
      }
    ],
    name: "Overview"
  },
  {
    id: "default-strategic-sourcing",
    layout: [
      {
        h: 6,
        i: "metrics",
        w: 10,
        x: 0,
        y: 0
      },
      {
        i: "table",
        h: 12,
        w: 10,
        x: 0,
        y: 6
      },
      {
        i: "insights",
        h: 12,
        w: 5,
        x: 0,
        y: 18
      },
      {
        i: "siteMap",
        w: 5,
        h: 12,
        x: 5,
        y: 18
      }
    ],
    name: "Strategic Sourcing"
  },
  {
    id: "default-inventory-management",
    layout: [
      {
        h: 15,
        i: "HeatMapMetrics",
        w: 10,
        x: 0,
        y: 0
      },
      {
        i: "heatMap",
        h: 12,
        w: 10,
        x: 0,
        y: 15
      },
      {
        i: "insights",
        h: 12,
        w: 5,
        x: 0,
        y: 27
      },
      {
        i: "siteMap",
        w: 5,
        h: 12,
        x: 5,
        y: 27
      }
    ],
    name: "Inventory Management"
  }
];

export const useCustomizableDashboardDataSourceContext = () => {
  return useContext(CustomizableDashboardDataSourceContext);
};

export const CustomizableDashboardDataSourceContextProvider = ({ children }) => {
  const { sendMixPanelEvent } = useMixpanelContext();

  const [personNodeId, setPersonNodeId] = useState("");

  const { cognitoUser } = useAuthenticationContext();

  const queryClient = useQueryClient();

  const xemelgoClient = useXemelgoClient();

  const widgetsQuery = useQuery({
    queryKey: ["widgets"],
    queryFn: async () => {
      const {
        attributes: { sub: cognitoId }
      } = cognitoUser || {};

      const personClient = xemelgoClient.getPersonClient();
      const { persons } = await personClient.getPersonByCognitoId(cognitoId);
      const [{ description, id }] = persons;
      const layoutMap = JSON.parse(description);
      setPersonNodeId(id);

      return layoutMap;
    },
    onError: (err) => {
      sendMixPanelEvent(CUSTOMIZABLE_DASHBOARD_EVENT, CUSTOMIZABLE_DASHBOARD_STEPS.USER_PREFERENCE_FETCH_FAILURE, {
        errorMessage: err.message
      });
    },
    onSuccess: () => {
      sendMixPanelEvent(CUSTOMIZABLE_DASHBOARD_EVENT, CUSTOMIZABLE_DASHBOARD_STEPS.USER_PREFERENCE_FETCH_SUCCESS);
    },
    refetchOnWindowFocus: false
  });

  const uploadLayoutOnPersonNode = (data) => {
    const personClient = xemelgoClient.getPersonClient();
    return personClient.updatePerson(personNodeId, null, null, null, null, JSON.stringify(data));
  };

  const widgetsMutation = useMutation({
    mutationFn: uploadLayoutOnPersonNode,
    onMutate: (data) => {
      queryClient.setQueryData(["widgets"], data);
    },
    onError: (err) => {
      sendMixPanelEvent(CUSTOMIZABLE_DASHBOARD_EVENT, CUSTOMIZABLE_DASHBOARD_STEPS.USER_PREFERENCE_UPDATE_FAILURE, {
        errorMessage: `${err.message} ${err?.response?.data}`
      });
    },
    onSuccess: () => {
      sendMixPanelEvent(CUSTOMIZABLE_DASHBOARD_EVENT, CUSTOMIZABLE_DASHBOARD_STEPS.USER_PREFERENCE_UPDATE_SUCCESS);
      queryClient.invalidateQueries(["widgets"], { exact: true });
    }
  });

  return (
    <CustomizableDashboardDataSourceContext.Provider
      value={{
        isLoading: widgetsQuery.isLoading,
        storedLayouts: widgetsQuery?.data?.length ? widgetsQuery.data : newLayoutSource,
        setStoredLayouts: widgetsMutation.mutate
      }}
    >
      {children}
    </CustomizableDashboardDataSourceContext.Provider>
  );
};
