import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../services/soft-cache-service";

const CustomizableDashboardConfigContext = React.createContext();

const initialState = {};

export const useCustomizableDashboardConfigContext = () => {
  return useContext(CustomizableDashboardConfigContext);
};

export const CustomizableDashboardConfigContextProvider = ({ children }) => {
  return (
    <CustomizableDashboardConfigContext.Provider value={{}}>{children}</CustomizableDashboardConfigContext.Provider>
  );
};
