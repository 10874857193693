import React from "react";
import PropTypes from "prop-types";
import { useAddInventoryFeatureV2ConfigContext } from "../../contexts/add-inventory-feature-v2-config-context";
import { useAddInventoryFeatureV2StateContext } from "../../contexts/add-inventory-feature-v2-state-context";
import UploadCsvComponent from "../../../../../../components/upload-csv-component";
import PrinterSelectionComponent from "../../../../../../components/printer-selection-component";
import Style from "./BulkCreateTabFeature.module.css";
import { SOLUTIONS } from "../../../../../../data/constants";

export const BulkCreateTabFeature = ({ printService }) => {
  const { formHeadersMap, isPrintEnabled } = useAddInventoryFeatureV2ConfigContext();

  const { setFormData } = useAddInventoryFeatureV2StateContext();
  const { selectedPrinterInfo, onSelectPrinter, printers, isLoading, printerErrorMessage } = printService;

  return (
    <div className={Style.bulk_create_container}>
      <div className={Style.upload_container}>
        <UploadCsvComponent
          csvHeadersMap={formHeadersMap}
          onFileInput={setFormData}
          solution={SOLUTIONS.INVENTORY}
        />
      </div>
      {isPrintEnabled && (
        <div className={Style.printer_options_container}>
          <PrinterSelectionComponent
            isLoading={isLoading}
            printerList={printers}
            selectedPrinterInfo={selectedPrinterInfo}
            onPrinterSelect={onSelectPrinter}
            errorMessage={printerErrorMessage}
          />
        </div>
      )}
    </div>
  );
};

BulkCreateTabFeature.propTypes = {
  printService: PropTypes.shape({
    selectedPrinterInfo: PropTypes.shape({}).isRequired,
    onSelectPrinter: PropTypes.func.isRequired,
    printers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool.isRequired,
    printerErrorMessage: PropTypes.string.isRequired
  }).isRequired
};
