import React, { useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import OnboardItemModal from "../../../../components/onboard-item-modal";
import { useXemelgoClient } from "../../../../services/xemelgo-service";
import usePrintService from "../../../../hooks/use-print-service";
import TabBar from "../../../../components/tab-bar";
import { PRINT_TYPES, SOLUTIONS, TAB_OPTIONS } from "../../../../data/constants";
import { ReactComponent as AssetIcon } from "../../../../assets/icons/asset.svg";
import StatusPopupComponent, { STATUS_OPTIONS } from "../../../../components/status-popup-component";
import useGeneratePrintTagCommands from "../../../../hooks/use-generate-print-tag-payload";
import {
  AddAssetFeatureConfigContextProvider,
  useAddAssetFeatureConfigContext
} from "./contexts/add-asset-feature-config-context";
import {
  AddAssetFeatureStateContextProvider,
  useAddAssetFeatureStateContext
} from "./contexts/add-asset-feature-state-context";
import AssetBulkCreateTabFeature from "./features/asset-bulk-create-tab-feature";
import useAssetFormDataParser from "./hooks/use-asset-form-parser";
import AssetSingleCreateTab from "./features/asset-single-create-tab";
import Style from "./AddAssetFeatureV2.module.css";

const CREATE_BATCH_SIZE = 50;
const POPUP_DURATION_MS = 7000;

const AddAssetFeature = ({ onClose }) => {
  const [assetClient] = useState(useXemelgoClient().getAssetClient());
  const [popupExpired, setPopupExpired] = useState(false);

  const popupTimeoutRef = useRef(null);
  const {
    formFields,
    bartenderConfig,
    isPrintEnabled,
    printType,
    zplConfig,
    isBulkCreateEnabled
  } = useAddAssetFeatureConfigContext();

  const { formDataList, setFormDataList, isSubmitDisabled, submitStatus, setSubmitStatus } =
    useAddAssetFeatureStateContext();

  const { generatePayload } = useAssetFormDataParser();
  const { generateMultiplePrintCommands } = useGeneratePrintTagCommands(SOLUTIONS.ASSET, printType);

  const [currentTab, setCurrentTab] = useState(TAB_OPTIONS.SINGLE);
  const [statusMessage, setStatusMessage] = useState("");

  const printService = usePrintService(isPrintEnabled, printType, bartenderConfig);

  const {
    print,
    printerErrorMessage
  } = printService;

  const tabList = useMemo(() => {
    const newTabList = [
      {
        id: TAB_OPTIONS.SINGLE,
        label: "Create an Asset"
      }
    ];

    if (isBulkCreateEnabled) {
      newTabList.push({
        id: TAB_OPTIONS.BULK,
        label: "Bulk Create"
      });
    }

    return newTabList;
  }, [isBulkCreateEnabled]);

  useEffect(() => {
    return () => {
      if (popupTimeoutRef.current) {
        clearTimeout(popupTimeoutRef.current);
      }
    };
  }, []);

  const printTags = async (payloads) => {
    const printConfig = printType === PRINT_TYPES.ZPL ? zplConfig : bartenderConfig;
    const printPayloads = await generateMultiplePrintCommands(payloads, printConfig);
    for (const printPayload of printPayloads) {
      await print(printPayload);
    }
  };

  const onSubmit = async () => {
    setSubmitStatus(STATUS_OPTIONS.LOADING);
    setStatusMessage("The assets are being added...");
    setPopupExpired(false);

    const promises = [];

    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }

    try {
      // ------------------ CREATE ------------------ //
      const payloadsByLocations = await generatePayload(formDataList, formFields);
      const allPayloads = [].concat(
        ...payloadsByLocations.map((item) => {
          return item.payloads;
        })
      );

      payloadsByLocations.forEach((payloadsByLocation) => {
        const { payloads, locationId } = payloadsByLocation;

        while (payloads.length) {
          const currentSlice = payloads.splice(0, CREATE_BATCH_SIZE);
          promises.push(assetClient.createAssetSet(currentSlice, locationId));
        }
      });

      await Promise.all(promises);

      // ------------------ PRINT ------------------ //
      if (isPrintEnabled) {
        setStatusMessage("The assets have been added and are being submitted for printing...");
        await printTags(allPayloads);
        setStatusMessage("The assets have been added and submitted for printing.");
      } else {
        setStatusMessage("The assets have been added successfully.");
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.includes("[BadRequest] vid")) {
        setStatusMessage("Failed to create asset. RFID tag number already exists.");
      } else {
        setStatusMessage("Failed to create asset. Please contact Xemelgo Support for assistance");
      }
      setSubmitStatus(STATUS_OPTIONS.ERROR);
      popupTimeoutRef.current = setTimeout(() => {
        setPopupExpired(true);
      }, POPUP_DURATION_MS);
      return;
    }
    setFormDataList([]);
    popupTimeoutRef.current = setTimeout(() => {
      setPopupExpired(true);
    }, POPUP_DURATION_MS);
    setSubmitStatus(STATUS_OPTIONS.SUCCESS);
  };

  return (
    <OnboardItemModal
      title="Add Asset"
      headerMessage={isPrintEnabled ? printerErrorMessage : ""}
      error={isPrintEnabled}
      titleIconComponent={(
        <div className={Style.title_icon}>
          <AssetIcon
            width={25}
            height={25}
          />
        </div>
      )}
      onSubmit={onSubmit}
      onClose={onClose}
      isPrintEnabled={isPrintEnabled}
      submitDisabled={isSubmitDisabled}
      submitLoading={submitStatus === STATUS_OPTIONS.LOADING}
      modalContainerClassName={Style.modal_container}
    >
      <div className={Style.modal_body_container}>
        <div className={Style.main_container}>
          {tabList.length > 1 && (
            <TabBar
              containerClassName={Style.tab_bar}
              tabList={tabList}
              currentTabId={currentTab}
              onTabClick={(newTab) => {
                setCurrentTab(newTab);
                setFormDataList([]);
              }}
            />
          )}
          <div className={Style.tab_container}>
            {currentTab === TAB_OPTIONS.SINGLE ? (
              <AssetSingleCreateTab printService={printService} />
            ) : (
              <AssetBulkCreateTabFeature printService={printService} />
            )}
          </div>
        </div>

        <div className={Style.popup_modal_div}>
          <StatusPopupComponent
            showPopup={!popupExpired && submitStatus !== STATUS_OPTIONS.NONE && statusMessage !== ""}
            message={statusMessage}
            status={submitStatus}
          />
        </div>
      </div>
    </OnboardItemModal>
  );
};

export default (props) => {
  return (
    <AddAssetFeatureConfigContextProvider>
      <AddAssetFeatureStateContextProvider>
        <AddAssetFeature {...props} />
      </AddAssetFeatureStateContextProvider>
    </AddAssetFeatureConfigContextProvider>
  );
};

AddAssetFeature.propTypes = {
  onClose: PropTypes.func.isRequired
};
