import React, { useEffect } from "react";
import chatbotLogo from "../../assets/images/vallen-favicon.png";
import { ReactComponent as PopoutIcon } from "../../assets/icons/popout.svg";
import { useStore } from "react-hookstore";
import { fullScreenModeStore } from "../../../state-managements/stores/full-screen-mode-store";

export const Header = ({ botName }) => {
  const [fullScreenMode] = useStore(fullScreenModeStore);

  return (
    <div className={"react-chatbot-kit-chat-header"}>
      <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "9px" }}>
          <img
            height="32px"
            alt="logo"
            src={chatbotLogo}
          />
        </div>
        <div>{botName}</div>
      </div>
      {/* gonna assume that if it's in full screen then it's in new window */}
      {!fullScreenMode && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            const origin = window.location.origin;
            window.open(`${origin}/gpt`, "gptWindow", "popup");
          }}
        >
          <PopoutIcon
            height="32px"
            alt="popout"
          />
        </div>
      )}
    </div>
  );
};
