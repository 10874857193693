import React, { Fragment } from "react";
import PropTypes from "prop-types";

const MainAppComponent = ({
  headerComponent,
  sideNavigationComponent,
  renderComponent,
  containerStyle,
  chatComponent
}) => {
  return (
    <>
      {headerComponent && headerComponent()}
      {sideNavigationComponent && sideNavigationComponent()}
      {chatComponent && chatComponent()}

      <div className={containerStyle}>{renderComponent()}</div>
    </>
  );
};

export default MainAppComponent;

MainAppComponent.defaultProps = {
  containerStyle: null
};

MainAppComponent.propTypes = {
  headerComponent: PropTypes.func.isRequired,
  sideNavigationComponent: PropTypes.func.isRequired,
  chatComponent: PropTypes.func.isRequired,
  renderComponent: PropTypes.func.isRequired,
  containerStyle: PropTypes.string
};
