import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useStore } from "react-hookstore";
import PropTypes from "prop-types";
import ChatbotKit from "react-chatbot-kit";
import { getLongXemelgoLogo } from "common/Utilities";
import xemelgoStyle from "styles/variable";
import Style from "./Chatbot.module.css";
import Config from "./config";
import MessageParser, { setBearerToken } from "./MessageParser";
import ActionProvider from "./ActionProvider";
import "react-chatbot-kit/build/main.css";
import chatbotLogo from "./assets/images/vallen-favicon.png";
import { ReactComponent as MinimizeIcon } from "./assets/icons/minimize.svg";
import { LocalCacheService } from "../services/local-cache-service";
import { fullScreenModeStore } from "../state-managements/stores/full-screen-mode-store";
import useMixpanelContext from "../context/mixpanel-context";

const Chatbot = ({ chatbotCollapsed, onChatbotCollapse, containerStyle }) => {
  const { sendMixPanelEvent } = useMixpanelContext();

  const [showPoweredByXemelgoFooter] = useState(true);
  const userProfile = LocalCacheService.loadUserProfile();
  const [fullScreenMode, setFullScreenMode] = useStore(fullScreenModeStore);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.startsWith("/gpt") && !fullScreenMode) {
      window.fcWidget.hide();
      setFullScreenMode(true);
    }
  }, [pathname, fullScreenMode]);

  useEffect(() => {
    const { idToken } = userProfile.tokens;
    setBearerToken(idToken);
  }, []);

  const poweredByXemelgo = () => {
    return (
      showPoweredByXemelgoFooter && (
        <div className={Style.chatbot_footer}>
          <p>Powered by</p>
          <img
            src={getLongXemelgoLogo()}
            width="100"
          />
        </div>
      )
    );
  };

  return (
    <div className={fullScreenMode ? Style.fullscreen_chatbot_container : Style.chatbot_container}>
      {!fullScreenMode && (
        <div className={chatbotCollapsed && Style.collapsed_tab_bar}>
          <div
            className={`${Style.flex_row} ${Style.information_collapsible_button} ${
              chatbotCollapsed && Style.information_collapsible_button_collapsed
            }`}
            onClick={onChatbotCollapse}
          >
            {chatbotCollapsed ? (
              <div className={Style.expand_chatbot_tab}>
                <img
                  width="30px"
                  height="30px"
                  alt="logo"
                  src={chatbotLogo}
                />
                <p>VAL</p>
              </div>
            ) : (
              <MinimizeIcon width="15px" />
            )}
          </div>
        </div>
      )}
      <div
        className={`${Style.flex_column} ${
          fullScreenMode ? Style.fullscreen_information_container : Style.information_container
        } ${chatbotCollapsed && Style.information_container_collapsed}`}
      >
        <ChatbotKit
          config={Config}
          messageParser={MessageParser}
          actionProvider={(createChatBotMessage, setStateFunc) => {
            return new ActionProvider(createChatBotMessage, setStateFunc, sendMixPanelEvent);
          }}
          placeholderText="Ask VAL anything..."
        />
        {poweredByXemelgo()}
      </div>
    </div>
  );
};

export default Chatbot;

Chatbot.defaultProps = {
  containerStyle: null,
  onChatbotCollapse: () => {},
  chatbotCollapsed: false
};

Chatbot.propTypes = {
  containerStyle: PropTypes.string,
  onChatbotCollapse: PropTypes.func,
  chatbotCollapsed: PropTypes.bool
};
