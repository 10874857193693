import React, { useContext, useState, useEffect } from "react";
import { useAppConfigProvider } from "../../../../../../services/soft-cache-service";

const FEATURE_ID = "addInventory";
const APP_ID = "inventory";

const ONBOARDING_OPTIONS = "onboardingOptions";
const DEFAULT_ONBOARDING_LOCATION = "defaultOnboardingLocation";
const TARGET_LOCATION_ENABLED = "targetLocationEnabled";
const ENABLE_ONBOARDING_TO_LOCATION = "enableOnboardingToLocation";
const HIDE_SELECT_ONBOARDING_REQUIRED = "hideSelectOnboardingRequired";
const ONBOARDING_TO_LOCATION_REQUIRED = "onboardingToLocationRequired";
const POSSIBLE_ONBOARDING_LOCATION_CATEGORIES = "possibleOnboardingLocationCategories";
const ITEM_TYPE_PROPERTIES_TO_QUERY = "itemTypePropertiesToQuery";
const ITEM_TYPE_INFO = "itemTypeInfo";
const ADDITIONAL_ITEM_PROPERTIES = "additionalItemProperties";
const DEFAULT_ONBOARDING_TAB = "defaultOnboardingTab";
const IS_TAB_INPUT_EDITABLE = "isTagInputEditable";
const CUSTOM_TEMPLATE = "customTemplate";
const ENCODE_TAG = "encodeTag";
const GET_TAGS_API_URL = "getTagsApiUrl";
const SELECT_ITEM_TYPE_DISABLED = "selectItemTypeDisabled";
const SINGLE_PRINT = "singlePrint";
const CUSTOM_ITEM_IDENTIFIER_TEMPLATE = "customItemIdentifierTemplate";
const PRINT_ONLY = "printOnly";
const IS_BULK_CREATE_ENABLED = "isBulkCreateEnabled";
const IS_PRINT_ENABLED = "isPrintEnabled";

const initialState = {
  onboardingOptions: ["add", "print"],
  defaultOnboardingLocation: {},
  enableOnboardingToLocation: false,
  hideSelectOnboardingLocationInput: false,
  targetLocationEnabled: false,
  onboardingToLocationRequired: false,
  possibleOnboardingLocationCategories: ["Department"],
  itemTypePropertiesToQuery: ["id", "identifier", "name", "image_path"],
  itemTypeInfo: {
    propertiesMap: {
      identifier: { hiddenOnInfoCard: true },
      name: { hiddenOnInfoCard: true },
      image_path: { hiddenOnInfoCard: true }
    },
    label: "Item",
    placeholder: "Select an item",
    dislayIdentifierOnly: false
  },
  additionalItemProperties: [],
  defaultOnboardingTab: "add",
  isTagInputEditable: false,
  customTemplate: "",
  encodeTag: false,
  printOnly: false,
  getTagsApiUrl: "https://dvzoza0ah1.execute-api.us-west-2.amazonaws.com/v1/devices/",
  selectItemTypeDisabled: false,
  singlePrint: false,
  customItemIdentifierTemplate: "",
  bartenderConfig: {},
  formHeadersMap: {},
  isBulkCreateEnabled: false,
  isPrintEnabled: false
};

const AddInventoryFeatureV2ConfigContext = React.createContext({
  onboardingOptions: initialState.onboardingOptions,
  defaultOnboardingLocation: initialState.defaultOnboardingLocation,
  targetLocationEnabled: initialState.targetLocationEnabled,
  enableOnboardingToLocation: initialState.enableOnboardingToLocation,
  hideSelectOnboardingLocationInput: initialState.hideSelectOnboardingLocationInput,
  onboardingToLocationRequired: initialState.onboardingToLocationRequired,
  possibleOnboardingLocationCategories: initialState.possibleOnboardingLocationCategories,
  itemTypePropertiesToQuery: initialState.itemTypePropertiesToQuery,
  itemTypeInfo: initialState.itemTypeInfo,
  additionalItemProperties: initialState.additionalItemProperties,
  defaultOnboardingTab: initialState.defaultOnboardingTab,
  isTagInputEditable: initialState.isTagInputEditable,
  customTemplate: initialState.customTemplate,
  encodeTag: initialState.encodeTag,
  getTagsApiUrl: initialState.getTagsApiUrl,
  selectItemTypeDisabled: initialState.selectItemTypeDisabled,
  singlePrint: initialState.singlePrint,
  customItemIdentifierTemplate: initialState.customItemIdentifierTemplate,
  bartenderConfig: initialState.bartenderConfig,
  printOnly: initialState.printOnly,
  formHeadersMap: initialState.formHeadersMap,
  isBulkCreateEnabled: initialState.isBulkCreateEnabled,
  isPrintEnabled: initialState.isPrintEnabled,
  isLoading: true
});

export const useAddInventoryFeatureV2ConfigContext = () => {
  return useContext(AddInventoryFeatureV2ConfigContext);
};

export const AddInventoryFeatureV2ConfigContextProvider = ({ children }) => {
  const configProvider = useAppConfigProvider(APP_ID);

  const [isLoading, setIsLoading] = useState(true);

  const [onboardingOptions, setOnboardingOptions] = useState(initialState.onboardingOptions);
  const [defaultOnboardingLocation, setDefaultOnboardingLocation] = useState(initialState.defaultOnboardingLocation);
  const [enableOnboardingToLocation, setEnableOnboardingToLocation] = useState(initialState.enableOnboardingToLocation);
  const [hideSelectOnboardingLocationInput, setHideSelectOnboardingLocationInput] = useState(
    initialState.hideSelectOnboardingLocationInput
  );
  const [targetLocationEnabled, setTargetLocationEnabled] = useState(initialState.targetLocationEnabled);
  const [onboardingToLocationRequired, setOnboardingToLocationRequired] = useState(
    initialState.onboardingToLocationRequired
  );
  const [possibleOnboardingLocationCategories, setPossibleOnboardingLocationCategories] = useState(
    initialState.possibleOnboardingLocationCategories
  );
  const [itemTypePropertiesToQuery, setItemTypePropertiesToQuery] = useState(initialState.itemTypePropertiesToQuery);
  const [itemTypeInfo, setItemTypeInfo] = useState(initialState.itemTypeInfo);
  const [additionalItemProperties, setAdditionalItemProperties] = useState(initialState.additionalItemProperties);
  const [defaultOnboardingTab, setDefaultOnboardingTab] = useState(initialState.defaultOnboardingTab);
  const [isTagInputEditable, setIsTagInputEditable] = useState(initialState.isTagInputEditable);
  const [customTemplate, setCustomTemplate] = useState(initialState.customTemplate);
  const [encodeTag, setEncodeTag] = useState(initialState.encodeTag);
  const [printOnly, setPrintOnly] = useState(initialState.printOnly);
  const [getTagsApiUrl, setGetTagsApiUrl] = useState(initialState.getTagsApiUrl);
  const [selectItemTypeDisabled, setSelectItemTypeDisabled] = useState(initialState.selectItemTypeDisabled);
  const [singlePrint, setSinglePrint] = useState(initialState.singlePrint);
  const [customItemIdentifierTemplate, setcustomItemIdentifierTemplate] = useState(
    initialState.customItemIdentifierTemplate
  );
  const [bartenderConfig, setBartenderConfig] = useState(initialState.bartenderConfig);
  const [formHeadersMap, setFormHeadersMap] = useState(initialState.formHeadersMap);
  const [isBulkCreateEnabled, setIsBulkCreateEnabled] = useState(initialState.isBulkCreateEnabled);
  const [isPrintEnabled, setIsPrintEnabled] = useState(initialState.isPrintEnabled);

  useEffect(() => {
    setIsLoading(true);
    const addInventoryConfiguration = configProvider.getValue(FEATURE_ID, "object");
    const { inventoryBartenderConfig = {} } = configProvider.getModelMap();
    const newOnboardingOptions = addInventoryConfiguration[ONBOARDING_OPTIONS] || initialState.onboardingOptions;
    const newDefaultOnboardingLocation =
      addInventoryConfiguration[DEFAULT_ONBOARDING_LOCATION] || initialState.defaultOnboardingLocation;
    const newTargetLocationEnabled =
      addInventoryConfiguration[TARGET_LOCATION_ENABLED] || initialState.targetLocationEnabled;
    const newEnableOnboardingToLocation =
      addInventoryConfiguration[ENABLE_ONBOARDING_TO_LOCATION] || initialState.enableOnboardingToLocation;
    const newHideSelectOnboardingLocationInput =
      addInventoryConfiguration[HIDE_SELECT_ONBOARDING_REQUIRED] || initialState.hideSelectOnboardingLocationInput;
    const newOnboardingToLocationRequired =
      addInventoryConfiguration[ONBOARDING_TO_LOCATION_REQUIRED] || initialState.onboardingToLocationRequired;
    const newPossibleOnboardingLocationCategories =
      addInventoryConfiguration[POSSIBLE_ONBOARDING_LOCATION_CATEGORIES] ||
      initialState.possibleOnboardingLocationCategories;
    const newItemTypePropertiesToQuery =
      addInventoryConfiguration[ITEM_TYPE_PROPERTIES_TO_QUERY] || initialState.itemTypePropertiesToQuery;
    const newItemTypeInfo = addInventoryConfiguration[ITEM_TYPE_INFO] || initialState.itemTypeInfo;
    const newAdditionalItemProperties =
      addInventoryConfiguration[ADDITIONAL_ITEM_PROPERTIES] || initialState.additionalItemProperties;
    const newDefaultOnboardingTab =
      addInventoryConfiguration[DEFAULT_ONBOARDING_TAB] || initialState.defaultOnboardingTab;
    const newIsTagInputEditable = addInventoryConfiguration[IS_TAB_INPUT_EDITABLE] || initialState.isTagInputEditable;
    const newCustomTemplate = addInventoryConfiguration[CUSTOM_TEMPLATE] || initialState.customTemplate;
    const newEncodeTag = addInventoryConfiguration[ENCODE_TAG] || initialState.encodeTag;
    const newGetTagsApiUrl = addInventoryConfiguration[GET_TAGS_API_URL] || initialState.getTagsApiUrl;
    const newSelectItemTypeDisabled =
      addInventoryConfiguration[SELECT_ITEM_TYPE_DISABLED] || initialState.selectItemTypeDisabled;
    const newSinglePrint = addInventoryConfiguration[SINGLE_PRINT] || initialState.singlePrint;
    const newCustomItemIdentifierTemplate =
      addInventoryConfiguration[CUSTOM_ITEM_IDENTIFIER_TEMPLATE] || initialState.customItemIdentifierTemplate;

    const newPrintOnly = addInventoryConfiguration[PRINT_ONLY] || initialState.printOnly;
    const newIsBulkCreateEnabled =
      addInventoryConfiguration[IS_BULK_CREATE_ENABLED] || initialState.isBulkCreateEnabled;
    const newBartenderConfig = inventoryBartenderConfig.def || initialState.bartenderConfig;
    const newIsPrintEnabled = addInventoryConfiguration[IS_PRINT_ENABLED] || initialState.isPrintEnabled;

    const getFormHeadersMap = () => {
      const csvAttributesMap = {
        item_number: {
          id: "item_number",
          label: newItemTypeInfo.label,
          isRequired: true,
          type: newSelectItemTypeDisabled ? "input" : "dropdownWithOptionsFromAPI"
        },
        print_quantity: {
          id: "print_quantity",
          label: "Number of Tags",
          isRequired: true
        }
      };

      if (newEnableOnboardingToLocation) {
        csvAttributesMap.location = {
          id: "location",
          label: "Location",
          isRequired: newOnboardingToLocationRequired,
          type: "dropdownWithOptionsFromAPI"
        };
      }
      newAdditionalItemProperties.forEach((property) => {
        const { id, label, numberOnly } = property;
        csvAttributesMap[id] = {
          id,
          label,
          isRequired: false
        };
        if (numberOnly) {
          csvAttributesMap[id].type = "number";
        }
      });
      return csvAttributesMap;
    };

    const newFormHeaderMap = getFormHeadersMap();

    setOnboardingOptions(newOnboardingOptions);
    setDefaultOnboardingLocation(newDefaultOnboardingLocation);
    setTargetLocationEnabled(newTargetLocationEnabled);
    setEnableOnboardingToLocation(newEnableOnboardingToLocation);
    setHideSelectOnboardingLocationInput(newHideSelectOnboardingLocationInput);
    setOnboardingToLocationRequired(newOnboardingToLocationRequired);
    setPossibleOnboardingLocationCategories(newPossibleOnboardingLocationCategories);
    setItemTypePropertiesToQuery(newItemTypePropertiesToQuery);
    setItemTypeInfo(newItemTypeInfo);
    setAdditionalItemProperties(newAdditionalItemProperties);
    setDefaultOnboardingTab(newDefaultOnboardingTab);
    setIsTagInputEditable(newIsTagInputEditable);
    setCustomTemplate(newCustomTemplate);
    setEncodeTag(newEncodeTag);
    setGetTagsApiUrl(newGetTagsApiUrl);
    setSelectItemTypeDisabled(newSelectItemTypeDisabled);
    setSinglePrint(newSinglePrint);
    setcustomItemIdentifierTemplate(newCustomItemIdentifierTemplate);
    setBartenderConfig(newBartenderConfig || initialState.bartenderConfig);
    setPrintOnly(newPrintOnly);
    setFormHeadersMap(newFormHeaderMap);
    setIsBulkCreateEnabled(newIsBulkCreateEnabled);
    setIsPrintEnabled(newIsPrintEnabled);

    setIsLoading(false);
  }, [configProvider]);

  return (
    <AddInventoryFeatureV2ConfigContext.Provider
      value={{
        isLoading,
        onboardingOptions,
        defaultOnboardingLocation,
        targetLocationEnabled,
        enableOnboardingToLocation,
        hideSelectOnboardingLocationInput,
        onboardingToLocationRequired,
        possibleOnboardingLocationCategories,
        itemTypePropertiesToQuery,
        itemTypeInfo,
        additionalItemProperties,
        defaultOnboardingTab,
        isTagInputEditable,
        customTemplate,
        encodeTag,
        getTagsApiUrl,
        selectItemTypeDisabled,
        singlePrint,
        customItemIdentifierTemplate,
        bartenderConfig,
        printOnly,
        formHeadersMap,
        isBulkCreateEnabled,
        isPrintEnabled
      }}
    >
      {children}
    </AddInventoryFeatureV2ConfigContext.Provider>
  );
};
