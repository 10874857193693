import React, { useContext, useState } from "react";

const AddTransferOrderFeatureStateContext = React.createContext({
  formData: [],
  setFormData: (newFormData) => {},
});

export const useAddTransferOrderFeatureStateContext = () => {
  return useContext(AddTransferOrderFeatureStateContext);
};

export const AddTransferOrderFeatureStateContextProvider = ({ children }) => {
  const [formData, setFormData] = useState([]);

  return (
    <AddTransferOrderFeatureStateContext.Provider
      value={{
        formData,
        setFormData
      }}
    >
      {children}
    </AddTransferOrderFeatureStateContext.Provider>
  );
};
