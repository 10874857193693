import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import { AssetMap } from "../../features/asset-track-page-feature/features/asset-map/AssetMap";

const AssetMapApp = () => {
  return (
    <>
      <Route
        exact
        path="/asset-map"
        component={AssetMap}
      />
    </>
  );
};

export default AssetMapApp;
