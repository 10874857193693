export const getLabelByItemClass = (itemClass) => {
  switch (itemClass) {
    case "Traveller":
      return "Order";
    case "Part":
    case "Asset":
      return itemClass;
    case "TransferOrder":
      return "Inventory Report";
    default:
      return "Item";
  }
};
