/* eslint-disable react/display-name */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { DisplayBannerContextProvider } from "./context/DisplayBannerContext/DisplayBannerContext";
import Routes from "./routes/Routes";
import MainAppFeature from "./features/main-app-feature/MainAppFeature";
import { XemelgoService } from "./services/XemelgoService";
import { MixpanelContextProvider } from "./context/mixpanel-context";
import "./App.css";

const MainApp = () => {
  const [isLoading, setLoading] = useState(true);

  /**
   * @type {./domains/user-profile.UserProfile}
   */
  // check if the scenario is prod (0) or dev (1)

  useEffect(() => {
    const onLoad = async () => {
      setLoading(true);
      await XemelgoService.getClient().setScenario(sessionStorage.getItem("scenario") || "0");
      setLoading(false);
    };

    onLoad();
  }, []);

  if (isLoading) {
    return null;
  }
  return (
    <DisplayBannerContextProvider>
      <MainAppFeature render={<Routes />} />
    </DisplayBannerContextProvider>
  );
};

export default withRouter(() => {
  return (
    <MixpanelContextProvider>
      <MainApp />
    </MixpanelContextProvider>
  );
});
