import React, { useState, useRef, useEffect, useMemo } from "react";
import Style from "./ReportsPageFeature.module.css";
import ReportClassFilter from "./components/report-class-filter/ReportClassFilter";
import { ReportsPageContextProvider, useReportsPageContext } from "./context/reportsPageContext";
import FreeTextSearchInput from "../../components/free-text-search-input";
import ReportTypePicker from "./components/report-type-picker/ReportTypePicker";
import ReportList from "./components/report-list/ReportList";
import ReportPageScreenFrame from "./components/report-page-screen-frame/ReportPageScreenFrame";
import RunReportModalFeature from "../run-report-modal-feature";
import { useDisplayBannerContext } from "../../context/DisplayBannerContext/DisplayBannerContext";
import SideFilter from "../side-filter/components/side-filter/SideFilter";
import { useAppConfigProvider } from "../../services/soft-cache-service";

const APP_ID = "reports";

const ReportsPageFeature = ({ screenFrameTitle, screenFrameTitleIcon, singleReportMode, reportClassId }) => {
  const configProvider = useAppConfigProvider(APP_ID);
  const [isRunReportModalOpen, setIsRunReportModalOpen] = useState(false);
  const {
    searchInputText,
    setSearchInputText,
    sideFilter,
    setSideFilter,
    reportSideFilterConfiguration,
    setSelectedClassFilter,
    setSelectedTypeFilter
  } = useReportsPageContext();
  const { setShowBanner, setBannerHasError, setBannerTitle } = useDisplayBannerContext();
  const reportGroups = configProvider.getValue("reportGroups", "object") || {};

  useEffect(() => {
    return () => {
      setShowBanner(false);
    };
  }, []);

  useEffect(() => {
    if (singleReportMode) {
      setSelectedClassFilter(singleReportClass);
    }
  }, [singleReportClass]);

  useEffect(() => {
    if (singleReportMode) {
      setSelectedTypeFilter(singleReportType);
    }
  }, [singleReportType]);

  const singleReportClass = useMemo(() => {
    if (!singleReportMode) {
      return null;
    }

    const groupWithDetail = Object.keys(reportGroups).find((reportGroupKey) => {
      const containsReportDetail = reportGroups[reportGroupKey].some((report) => {
        return report.id === reportClassId;
      });

      if (containsReportDetail) {
        return reportGroupKey;
      }
    });

    return groupWithDetail;
  }, [singleReportMode, reportClassId]);

  const singleReportType = useMemo(() => {
    if (!singleReportMode && !singleReportClass) {
      return null;
    }

    const matchingType = reportGroups[singleReportClass].find((report) => {
      return report.id === reportClassId;
    });

    return matchingType;
  }, [singleReportMode, singleReportClass, reportClassId]);

  const reportListRef = useRef();

  const onFilterChange = (filterUpdates) => {
    setSideFilter(filterUpdates.allValues);
  };

  return (
    <>
      <ReportPageScreenFrame
        title={screenFrameTitle}
        titleIcon={screenFrameTitleIcon}
        sideContent={() => {
          return (
            <>
              {!singleReportType?.disableRun && (
                <div className={`${Style.flex_row} ${Style.run_report_button_container}`}>
                  <button
                    type="button"
                    className={`${Style.run_report_button}`}
                    onClick={() => {
                      setIsRunReportModalOpen(true);
                    }}
                  >
                    Run Report
                  </button>
                </div>
              )}
              {!singleReportMode && (
                <div>
                  <ReportClassFilter defaultReportClass={singleReportClass} />
                </div>
              )}
              <div className={`${Style.side_filter_container}`}>
                <SideFilter
                  onFilterChange={onFilterChange}
                  filterConfiguration={reportSideFilterConfiguration}
                  initialFilterValues={{ ...sideFilter }}
                />
              </div>
              <div />
            </>
          );
        }}
        mainContent={() => {
          return (
            <>
              <FreeTextSearchInput
                value={searchInputText}
                onChangeText={setSearchInputText}
              />
              {!singleReportMode && <ReportTypePicker defaultReportType={singleReportType} />}
              <ReportList ref={reportListRef} />
            </>
          );
        }}
      />
      {isRunReportModalOpen && (
        <RunReportModalFeature
          onCloseClick={() => {
            setIsRunReportModalOpen(false);
          }}
          onSubmitClick={(success) => {
            if (success) {
              reportListRef.current.reload();
              setBannerHasError(false);
              setBannerTitle("Successfully created the report");
              setShowBanner(true);
            } else {
              setBannerHasError(true);
              setBannerTitle("Cannot create the report. Please try again later");
              setShowBanner(true);
            }
          }}
        />
      )}
    </>
  );
};

export default (props) => {
  return (
    <ReportsPageContextProvider>
      <ReportsPageFeature {...props} />
    </ReportsPageContextProvider>
  );
};
