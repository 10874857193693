import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Style from "./BulkUpdateWorkOrderButton.module.css";

const BUTTON_TITLE = "Bulk Update";
export const BulkUpdateWorkOrderButton = () => {
  const history = useHistory();

  return (
    <Link
      className={`${Style.bulk_update_button}`}
      to={`${history.location.pathname}/bulk-update`}
    >
      {BUTTON_TITLE}
    </Link>
  );
};

BulkUpdateWorkOrderButton.defaultProps = {};

BulkUpdateWorkOrderButton.propTypes = {};
