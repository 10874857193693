import React from "react";

import { widgetDefinitionMap } from "./widgetDefinition";
import { multipleSortComparison } from "../../../../utils";

export const useWidgetHelper = () => {
  const getWidgetComponentById = (id) => {
    return widgetDefinitionMap[id]?.component || <div>{id}</div>;
  };

  const getAvailableWidgets = () => {
    return Object.keys(widgetDefinitionMap)
      .map((eachWidgetId) => {
        return {
          id: eachWidgetId,
          control: { i: eachWidgetId, ...widgetDefinitionMap[eachWidgetId].control },
          label: widgetDefinitionMap[eachWidgetId].label
        };
      })
      .sort(multipleSortComparison([{ id: "label" }]));
  };

  return { getWidgetComponentById, getAvailableWidgets };
};
