const NO_END_TIME = 32503680000000;

export const getLocationHistory = (fullItemData, recipientNames) => {
  const sessions = fullItemData?.["associatedWithSession#edge"].map((edge) => {
    return {
      ...(edge?.["associatedWithSession#node"]?.[0] || {}),
      isActive: edge.edge_end_time === NO_END_TIME
    };
  });
  const sortedNames = recipientNames
    .sort((a, b) => {
      return b.localeCompare(a);
    })
    .map((name) => {
      const underscoreIndex = name.indexOf("_");
      return underscoreIndex !== -1 ? name.substring(underscoreIndex + 1) : name;
    });
  const history = sessions.reduce((accumulator, session) => {
    const { includesEvent = [], isActive } = session;
    const sessionHistory = includesEvent
      .filter((eachTrackingSessionEvent) => {
        return eachTrackingSessionEvent.detectedAt.length;
      })
      .sort((a, b) => {
        return b.node_start_time - a.node_start_time;
      })
      .reduce((sessionHistory, eachTrackingSessionEvent) => {
        const { detectedAt = [], start_time: startTime, end_time: endTime } = eachTrackingSessionEvent;

        const { name: locationName } = detectedAt?.[0] || {};
        sessionHistory.push({
          locationName,
          startTime,
          endTime
        });
        return sessionHistory;
      }, []);

    if (!isActive) {
      const recipientName = sortedNames[0];
      sortedNames.splice(0, 1);
      sessionHistory[1].description = `at ${sessionHistory[0]?.locationName}`;
      sessionHistory[1].signedBy = recipientName ? `Signed by ${recipientName}` : "";
      sessionHistory.splice(0, 1);
    }
    accumulator.push(...sessionHistory);
    return accumulator;
  }, []);

  return history.sort((a, b) => {
    return b.startTime - a.startTime;
  });
};

export const getIsTrackingSessionEnded = (fullItemData) => {
  return !fullItemData?.["associatedWithSession#edge"]?.find((edge) => {
    return edge.edge_end_time == NO_END_TIME;
  });
};

export const getDetailScreenData = (fullItemData, attributes, locationCategories, isTrackingSessionEnded) => {
  const { hasSensorProfile, ofType, ...restAttributes } = fullItemData;
  const {
    edge_end_time,
    edge_start_time,
    "associatedWithSession#node": associatedWithSession = []
  } = fullItemData?.["associatedWithSession#edge"]?.sort((a, b) => {
    return b.edge_end_time - a.edge_end_time;
  })[0] || {};

  const { vid = "" } = hasSensorProfile?.[0] || {};
  const { id: itemTypeId = "", itemTypeIdentifier = "" } = ofType?.[0] || {};
  const {
    includesEvent = [],
    lastDetectedLocation = [],
    node_end_time: lastDetectedTime,
    node_start_time: firstDetectedTime
  } = associatedWithSession?.[0] || {};
  let deliveredLocation = "-";
  if (isTrackingSessionEnded) {
    const deliveredEvent = includesEvent.find(
      (event) => event.detectedAt.length && !locationCategories.includes(event.detectedAt[0].category)
    );
    deliveredLocation = deliveredEvent?.detectedAt?.[0]?.name || "-";
  }
  const { id: lastDetectedLocationId = "", name: lastDetectedLocationName = "" } = lastDetectedLocation?.[0] || {};
  const itemData = {
    ...restAttributes,
    vid,
    itemTypeId,
    itemTypeIdentifier,
    lastDetectedLocationId,
    lastDetectedLocationName,
    lastDetectedTime,
    firstDetectedTime,
    edge_end_time: edge_end_time === NO_END_TIME ? null : edge_end_time,
    edge_start_time,
    deliveredLocation
  };

  return attributes
    .filter((attribute) => {
      const { inactiveOnly } = attribute;
      return !(inactiveOnly && !isTrackingSessionEnded);
    })
    .reduce((accumulator, current) => {
      const { id, isHeader, subText } = current;
      accumulator[id] = {
        ...current,
        primary: isHeader,
        value: itemData[id],
        subText: itemData[subText]
      };

      return accumulator;
    }, {});
};

export const getStatusList = (
  fullItemData,
  locationTreeMap,
  locationIdentifierToStatusColorMap,
  isTrackingSessionEnded
) => {
  const associatedWithSession =
    fullItemData?.["associatedWithSession#edge"]?.sort((a, b) => {
      return b.edge_end_time - a.edge_end_time;
    })?.[0]?.["associatedWithSession#node"] || [];
  const { id: lastDetectedLocationId = "", name: lastDetectedLocationName = "" } =
    associatedWithSession?.[0]?.includesEvent?.[isTrackingSessionEnded ? 3 : 0]?.detectedAt?.[0] || {};
  if (lastDetectedLocationId) {
    return [
      {
        id: lastDetectedLocationId,
        label: lastDetectedLocationName,
        ...(locationIdentifierToStatusColorMap[locationTreeMap[lastDetectedLocationId]?.identifier] || {})
      }
    ];
  }
  return [];
};
