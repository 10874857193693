import React, { useMemo, useState } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";
import PaginatedListTable from "../../components/paginated-list-table";
import queryFns from "../../queryFns";
import useWidgetSharableContext from "../../../../features/dashboard-grid/contexts/widget-sharable-context";
import Style from "./HeatMap.module.css";
import useSortableHeader from "../../../../../../hooks/use-sortable-header";

const TableIdIndexMap = {
  heatMap: 0,
  minMax: 1,
  openOrders: 2,
  critical: 3,
  nonCritical: 4
};

// sud todo confirm labels
const TABLE_ID_TO_CSV_HEADER_MAP = {
  heatMap: [
    {
      id: "Warehouse",
      label: "Warehouse"
    },
    {
      id: "Part Number",
      label: "Part Number"
    },
    {
      id: "Critical",
      label: "Critical"
    },
    {
      id: "Description",
      label: "Description"
    },
    {
      id: "Stock UOM",
      label: "Stock UOM"
    },
    {
      id: "vendor name",
      label: "Vendor Name"
    },
    {
      id: "lead time",
      label: "Lead Time"
    },
    {
      id: "Status",
      label: "Status"
    },
    {
      id: "order pt/min",
      label: "Order Pt/Min"
    },
    {
      id: "order qty",
      label: "Order Qty"
    },
    {
      id: "line point/max",
      label: "Line Point/Max"
    },
    {
      id: "sugg min",
      label: "Sugg Min"
    },
    {
      id: "sugg max",
      label: "Sugg Max"
    },
    {
      id: "bin loc1",
      label: "Bin Loc"
    },
    {
      id: "show",
      label: "Show"
    },
    {
      id: "Qty On Hand",
      label: "Qty On Hand"
    },
    {
      id: "Qty Ordered",
      label: "Qty Ordered"
    },
    {
      id: "max",
      label: "Max"
    },
    {
      id: "op+20%",
      label: "OP+20%"
    },
    {
      id: "below order point",
      label: "Below Order Point"
    },
    {
      id: "at or within 20% of order point",
      label: "At or Within 20% of Order Point"
    },
    {
      id: "within parameters, no risk",
      label: "Within Parameters, No Risk"
    },
    {
      id: "oh supply weeks",
      label: "On Hand Supply Weeks"
    },
    {
      id: "cust only",
      label: "Cust Only"
    },
    {
      id: "at risk of stockout",
      label: "At Risk of Stockout"
    },
    {
      id: "bor",
      label: "BOR"
    }
  ],
  minMax: [
    {
      id: "Suggested Min",
      label: "Suggested Min"
    },
    {
      id: "Suggested Max",
      label: "Suggested Max"
    },
    {
      id: "Current Min",
      label: "Current Min"
    },
    {
      id: "Current Max",
      label: "Current Max"
    },
    {
      id: "Part Number",
      label: "Part Number"
    },
    {
      id: "Status",
      label: "Status"
    },
    {
      id: "Critical",
      label: "Critical"
    },
    {
      id: "Stock UOM",
      label: "Stock UOM"
    },
    {
      id: "Description",
      label: "Description"
    },
    { id: "min wks usage", label: "Min Weeks Usage" },
    { id: "max weeks usage", label: "Max Weeks Usage" },
    { id: "avg wk usage (12 mos)", label: "Average Weekly Usage (12 Months)" },
    { id: "avg wk usage (6 mos)", label: "Average Weekly Usage (6 Months)" },
    { id: "std-pkg", label: "Standard Package" },
    { id: "min std pck?", label: "Min Standard Package" },
    { id: "last 6 mos", label: "Last 6 Months" },
    { id: "average lead time", label: "Average Lead Time" },
    { id: "stockout list?", label: "Stock List" },
    { id: "cost", label: "Cost" },
    { id: "current annual turns", label: "Current Annual Turns" },
    { id: "new annual turns", label: "New Annual Turns" },
    { id: "current oq based on std pk?", label: "Current OQ Based on STD Pk" },
    { id: "current oq value", label: "Current OQ Value" },
    { id: "min stock over-ride", label: "Min Stock Override" },
    { id: "max stock over-ride", label: "Max Stock Override" },
    { id: "cust part", label: "cust part" },
    { id: "cust avg cost", label: "cust avg cost" },
    { id: "total qty oh", label: "Total Qty On Hand" },
    { id: "order pt/min", label: "Order Pt/Min" },
    { id: "line point/max", label: "Line Point/Max" },
    { id: "lead time", label: "Lead Time" },
    { id: "bor", label: "BOR" }
  ],
  openOrders: [
    {
      id: "Part Number",
      label: "Part Number"
    },
    {
      id: "PO Number",
      label: "PO Number"
    },
    {
      id: "ERP Part Number",
      label: "ERP Part Number"
    },
    {
      id: "Order Date",
      label: "Order Date"
    },
    {
      id: "Promise Date",
      label: "Promise Date"
    },
    {
      id: "Confirmed",
      label: "Confirmed"
    },
    {
      id: "Description",
      label: "Description"
    },
    {
      id: "Vendor",
      label: "Vendor"
    },
    {
      id: "Critical",
      label: "Critical"
    },
    {
      id: "Status",
      label: "Status"
    }
  ],
  critical: [
    { id: "Part Number", label: "Part Number" },
    { id: "Description", label: "Description" },
    { id: " total qoh", label: "Total QOH" },
    { id: "Weeks of Supply", label: "Weeks of Supply" },
    { id: "Lead Time (Days)", label: "Lead Time (Days)" },
    { id: "Projected Zero Stock", label: "Projected Zero Stock" },
    { id: "min order point", label: "Min Order Point" },
    { id: "order qty", label: "Order Qty" },
    { id: "vendor", label: "Vendor" },
    { id: "vendor #", label: "Vendor #" },
    { id: "vendor pn", label: "Vendor Part Number" },
    { id: "bor", label: "BOR" },
    { id: "perm c", label: "Perm C" }
  ],
  nonCritical: [
    { id: "Part Number", label: "Part Number" },
    { id: "Description", label: "Description" },
    { id: "Qty On-Hand", label: "Qty On-Hand" },
    { id: "Weeks of Supply", label: "Weeks of Supply" },
    { id: "Lead Time (Days)", label: "Lead Time (Days)" },
    { id: "Projected Zero Stock", label: "Projected Zero Stock" },
    { id: "min order point", label: "Min Order Point" },
    { id: "order qty", label: "Order Qty" },
    { id: "vendor", label: "Vendor" },
    { id: "vendor #", label: "Vendor #" },
    { id: "vendor pn", label: "Vendor Part Number" },
    { id: "bor", label: "BOR" },
    { id: "perm c", label: "Perm C" }
  ]
};

const TABLE_ID_TO_HEADER_MAP = {
  heatMap: [
    { id: "Part Number", label: "Customer Part Number" },
    { id: "Description", label: "Description" },
    {
      id: "Critical",
      label: "Critical"
    },
    {
      id: "Status",
      label: "Status"
    },
    {
      id: "Warehouse",
      label: "Warehouse"
    },
    {
      id: "Stock UOM",
      label: "Stock UOM"
    },
    {
      id: "Qty On Hand",
      label: "On Hand"
    },
    {
      id: "Qty Ordered",
      label: "Qty Ordered"
    },
    {
      id: "Projected Exhaustion Date",
      label: "Projected Exhaustion Date"
    },
    {
      id: "Promise Date",
      label: "Promise Date"
    },
    {
      id: "below order point",
      label: "Below Order Point"
    }
  ],
  minMax: [
    {
      id: "Part Number",
      label: "Part Number"
    },
    {
      id: "Suggested Min",
      label: "Suggested Min"
    },
    {
      id: "Suggested Max",
      label: "Suggested Max"
    },
    {
      id: "Current Min",
      label: "Current Min"
    },
    {
      id: "Current Max",
      label: "Current Max"
    },
    {
      id: "Suggested OQ Diff Value",
      label: "Suggested OQ Diff Value"
    },
    {
      id: "Average Cost",
      label: "Average Cost"
    },
    {
      id: "Status",
      label: "Status"
    },
    {
      id: "Critical",
      label: "Critical"
    },
    {
      id: "Stock UOM",
      label: "Stock UOM"
    },
    {
      id: "Description",
      label: "Description"
    }
  ],
  openOrders: [
    {
      id: "Part Number",
      label: "Part Number"
    },
    {
      id: "PO Number",
      label: "PO Number"
    },
    {
      id: "ERP Part Number",
      label: "ERP Part Number"
    },
    {
      id: "Order Date",
      label: "Order Date"
    },
    {
      id: "Promise Date",
      label: "Promise Date"
    },
    {
      id: "Confirmed",
      label: "Confirmed"
    },
    {
      id: "Description",
      label: "Description"
    },
    {
      id: "Vendor",
      label: "Vendor"
    },
    {
      id: "Critical",
      label: "Critical"
    },
    {
      id: "Status",
      label: "Status"
    }
  ],
  critical: [
    {
      id: "Part Number",
      label: "Part Number"
    },
    {
      id: "Description",
      label: "Description"
    },
    {
      id: "Qty On-Hand",
      label: "Qty On-Hand"
    },
    {
      id: "Weeks of Supply",
      label: "Weeks of Supply"
    },
    {
      id: "Lead Time (Days)",
      label: "Lead Time (Days)"
    },
    {
      id: "Projected Zero Stock",
      label: "Projected Zero Stock"
    }
  ],
  nonCritical: [
    {
      id: "Part Number",
      label: "Part Number"
    },
    {
      id: "Description",
      label: "Description"
    },
    {
      id: "Qty On-Hand",
      label: "Qty On-Hand"
    },
    {
      id: "Weeks of Supply",
      label: "Weeks of Supply"
    },
    {
      id: "Lead Time (Days)",
      label: "Lead Time (Days)"
    },
    {
      id: "Projected Zero Stock",
      label: "Projected Zero Stock"
    }
  ]
};

export const HeatMap = ({}) => {
  const [filterInput, setFilterInput] = useState("");
  const { selectedHeatMapTableId } = useWidgetSharableContext();

  const heatMapQueries = useQueries({
    queries: [
      {
        queryKey: ["widgets", "heat-map-heatMap"],
        queryFn: queryFns["heat-map-table-heatMap"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "heat-map-table-minMax"],
        queryFn: queryFns["heat-map-table-minMax"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "heat-map-table-openOrders"],
        queryFn: queryFns["heat-map-table-openOrders"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "heat-map-critical"],
        queryFn: queryFns["heat-map-table-critical"],
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["widgets", "heat-map-nonCritical"],
        queryFn: queryFns["heat-map-table-nonCritical"],
        refetchOnWindowFocus: false
      }
    ]
  });

  const heatMapExportCsvQuery = useQuery({
    queryKey: ["widgets", "heat-map-table-heatMap-export-csv"],
    queryFn: queryFns["heat-map-table-heatMap-export-csv"],
    refetchOnWindowFocus: false
  });

  // despite heatmap being the only one that needs, using useQueries in case there's other CSV queries
  const heatMapCSVQueries = useQueries({
    queries: [
      {
        queryKey: ["widgets", "heat-map-export-heatMap"],
        queryFn: queryFns["heat-map-export-heatMap"],
        refetchOnWindowFocus: false
      }
    ]
  });

  const tableId = useMemo(() => {
    switch (selectedHeatMapTableId) {
      case "Total Open Orders":
        return "openOrders";
      case "Total Inventory Value":
      case "Reduction of Order Mins":
      case "Reduction of Order Max":
      case "Consignment Eligible":
        return "minMax";
      case "Non-Critical Zero Stock":
      case "Non-Critical Projected Zero Stock":
        return "nonCritical";
      case "Critical Zero Stock":
      case "Critical Projected Zero Stock":
        return "critical";
      case "Within Parameters, No Risk":
      case "Below Order Point":
      case "At or Within 20% of Order Point":
      case "At Risk of Stockout":
      default:
        return "heatMap";
    }
  }, [selectedHeatMapTableId]);

  const currentHeaders = useMemo(() => {
    return TABLE_ID_TO_HEADER_MAP[tableId];
  }, [tableId]);

  const currentCSVHeaders = useMemo(() => {
    return TABLE_ID_TO_CSV_HEADER_MAP[tableId];
  }, [tableId]);

  const tableIndex = useMemo(() => {
    return TableIdIndexMap[tableId] || 0;
  }, [tableId, TableIdIndexMap]);

  const isLoading = useMemo(() => {
    return heatMapQueries[tableIndex]?.isLoading ?? true;
  }, [tableIndex, heatMapQueries]);

  const isCSVDataLoading = useMemo(() => {
    return heatMapCSVQueries[tableIndex]?.isLoading ?? false;
  }, [heatMapCSVQueries, tableIndex]);

  const filteredData = useMemo(() => {
    if (isLoading) {
      return [];
    }
    let tempData = heatMapQueries?.[tableIndex]?.data || [];

    if (selectedHeatMapTableId == "Within Parameters, No Risk") {
      tempData = tempData.filter((data) => {
        return data["within parameters, no risk"].toLowerCase() === "true";
      });
    }

    if (selectedHeatMapTableId == "Below Order Point") {
      tempData = tempData.filter((data) => {
        return data["below order point"].toLowerCase() === "true";
      });
    }

    if (selectedHeatMapTableId == "At Risk of Stockout") {
      tempData = tempData.filter((data) => {
        return data["at risk of stockout"].toLowerCase() !== "nan";
      });
    }

    if (selectedHeatMapTableId == "At or Within 20% of Order Point") {
      tempData = tempData.filter((data) => {
        return data["at or within 20% of order point"].toLowerCase() === "true";
      });
    }

    if (selectedHeatMapTableId == "Reduction of Order Mins") {
      tempData = tempData.filter((data) => {
        return data["Suggested Min"]?.toLowerCase() !== "#no change";
      });
    }

    if (selectedHeatMapTableId == "Reduction of Order Max") {
      tempData = tempData.filter((data) => {
        return data["Suggested Max"]?.toLowerCase() !== "#no change";
      });
    }

    if (selectedHeatMapTableId == "Consignment Eligible") {
      tempData = tempData.filter((data) => {
        return data["inv value of increased turns to add consignment"]?.toLowerCase() === "nan";
      });
    }

    if (filterInput.length) {
      const keywords = filterInput.trim().split(" ");
      return tempData.filter((data) => {
        let matches = true;
        const searchProperties = currentHeaders.reduce((accumulator, header) => {
          return `${accumulator}${data[header.id] || ""} `;
        }, "");

        for (const keyword of keywords) {
          if (!searchProperties.toLowerCase().includes(keyword.toLowerCase())) {
            matches = false;
            break;
          }
        }

        return matches;
      });
    }
    return tempData || [];
  }, [isLoading, heatMapQueries, filterInput, selectedHeatMapTableId]);

  const filteredDataWithCSVProperties = useMemo(() => {
    if (tableId === "heatMap") {
      return filteredData.map((data) => {
        const foundCSVProperties = heatMapCSVQueries?.[tableIndex]?.data?.find((csvProperties) => {
          return (
            data.Warehouse === csvProperties.Warehouse &&
            data["Part Number"] === csvProperties["Part Number"] &&
            data["vendor part number"] === csvProperties["vendor part number"]
          );
        });
        return {
          ...data,
          ...(foundCSVProperties || {})
        };
      });
    }

    return filteredData;
  }, [filteredData, isCSVDataLoading, heatMapCSVQueries, tableIndex]);

  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    const defaultHeader =
      currentHeaders.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      currentHeaders[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: "asc"
    };
  }, [currentHeaders]);

  const { getSortedData, getSortableHeader, order, orderBy } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const sortedData = useMemo(() => {
    return getSortedData(filteredDataWithCSVProperties);
  }, [filteredDataWithCSVProperties, order, orderBy]);

  return (
    <PaginatedListTable
      isLoading={isLoading}
      isCSVDataLoading={heatMapExportCsvQuery?.isLoading}
      title="Heat Map"
      csvHeaders={currentCSVHeaders}
      csvData={heatMapExportCsvQuery?.data || {}}
      header={currentHeaders}
      data={sortedData}
      onFilterChange={setFilterInput}
      filterInput={filterInput}
      renderItem={(eachItem, index) => {
        return (
          <div
            key={`${eachItem?.[currentHeaders?.[0]?.id]} ${index}`}
            className={`${Style.flex_row} ${Style.table_item}`}
          >
            {currentHeaders.map((eachHeader, subIndex) => {
              return (
                <div
                  key={`${eachItem?.[eachHeader.id]}${subIndex}`}
                  className={`${Style.flex_row} ${Style.table_column} ${Style[`table_column_${eachHeader.size}`]}`}
                >
                  <div className={`${Style.table_item_text} `}>{eachItem[eachHeader.id]}</div>
                </div>
              );
            })}
          </div>
        );
      }}
      renderHeaderProp={(eachHeader) => {
        return getSortableHeader(eachHeader, {
          headerContainerClass: `${Style.flex_row} ${Style.table_column} ${Style.table_header} ${
            Style[`table_column_${eachHeader.size}`]
          }`,
          className: Style.table_header_text
        });
      }}
    />
  );
};
