import React, { Fragment, useState, useEffect } from "react";
import queryString from "query-string";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import InputLabel from "@material-ui/core/InputLabel";
import SearchDropdown from "../../SearchDropdown/SearchDropdown";
import { multipleSortComparison } from "../../../utils";
import TextareaAutosize from "react-textarea-autosize";
import { DropdownItem } from "mdbreact";
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@material-ui/icons";
import AutoSizeTextArea from "components/AutoSizeTextArea/AutoSizeTextArea";
import { XemelgoService } from "../../../services/XemelgoService";
import ConfigurationService from "../../../services/ConfigurationService";
import { LocalCacheService } from "../../../services/local-cache-service";
import Style from "./css/OrderDetailPage.module.css";
import DetailCard from "../DetailCard";
import DataTable from "../DataTable";
import TimeTrackingDataTable from "../TimeTrackingDataTable";
import ProgressGraph from "../../RouteTracker/ProgressGraph";
import { getFormattedDate, msToTime, getStatusFlags } from "../../../common/Utilities";
import PartCrudTable from "../../../pages/track/PartCrudTable";
import LoadingCircle from "../../loading/LoadingCircle";
import EditButton from "../../EditButton/EditButton";
import { DatePicker } from "../../DateTimePicker/DateTimePicker";
import { DEFAULT_DAYS_FOR_HOT } from "constants/workOrderConstants";
import {
  WORK_ORDER_DETAIL_PAGE_EVENT,
  WORK_ORDER_DETAIL_PAGE_STEPS
} from "../../../constants/mixpanel-constant/workOrderDetailPage";
import { CreateAndPrintTagsModal } from "../../../features/order-track-page-feature/features/create-and-print-tags-modal/CreateAndPrintTagsModal";
import { ReactComponent as OrderIcon } from "../../../assets/icons/order.svg";
import ScreenFrame from "../../../components/ScreenFrame/ScreenFrame";
import xemelgoStyle from "../../../styles/variable";
import useOrderDetailSubscriptionContext, {
  OrderDetailSubscriptionContextProvider
} from "./contexts/order-detail-subscription-context";

const ASSOCIATED_PART_NAME_KEY = "name";
const ASSOCIATED_PART_IDENTIFIER_KEY = "identifier";
const ASSOCIATED_PART_ID_KEY = "id";

const defaultShipmentIssueOptionsList = [
  {
    key: "broken",
    label: "Broken Part(s)",
    value: "broken"
  }
];

const customerSpecificShipmentIssueOptionsList = [
  {
    key: "damaged",
    label: "Fallout",
    value: "damaged"
  }
];

const OperationStatusTable = ({ operations: operationsProp, onSaveClick }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [operations, setOperations] = useState(_.cloneDeep(operationsProp));

  useEffect(() => {
    setOperations(_.cloneDeep(operationsProp));
  }, [operationsProp]);

  const onChange = (value, index, id) => {
    const newOperations = _.cloneDeep(operations);
    newOperations[index][id] = value;
    newOperations[index].edited = true;
    setOperations(newOperations);
  };

  return (
    <div className={`${Style.flex_column} ${Style.operation_table}`}>
      <div className={`${Style.flex_row} ${Style.operation_table_title}`}>
        <p className={Style.operation_table_title_text}>Operation Status</p>
        <EditButton
          textButtonClassName={Style.operation_table_text_button}
          editMode={isEditMode}
          onDiscardClick={() => {
            setOperations([...operationsProp]);
            setIsEditMode(false);
          }}
          onSaveClick={() => {
            const editedOperations = operations.filter((each) => {
              return each.edited;
            });
            onSaveClick(editedOperations);
            setIsEditMode(false);
          }}
          onEditClick={() => {
            setIsEditMode(true);
          }}
        />
      </div>
      <div className={`${Style.flex_column} ${Style.operation_table_body}`}>
        <div className={`${Style.flex_row} ${Style.operation_table_body_header}`}>
          <p className={`${Style.operation_table_body_header_text} ${Style.operation_table_body_column}`}>Step Name</p>
          <p className={`${Style.operation_table_body_header_text} ${Style.operation_table_body_column_wide}`}>
            Start Date & Time
          </p>
          <p className={`${Style.operation_table_body_header_text} ${Style.operation_table_body_column_wide}`}>
            End Date & Time
          </p>
          <p className={`${Style.operation_table_body_header_text} ${Style.operation_table_body_column}`}>
            Produced Qty
          </p>
          <p className={`${Style.operation_table_body_header_text} ${Style.operation_table_body_column}`}>Machine</p>
          <p className={`${Style.operation_table_body_header_text} ${Style.operation_table_body_column}`}>Operator</p>
        </div>
        <div className={`${Style.flex_column} ${Style.operation_table_body_items_container}`}>
          {operations.length ? (
            operations.map((eachItem, index) => {
              return (
                <div
                  key={`${eachItem}_${index}`}
                  className={`${Style.flex_row} ${Style.operation_table_body_item}`}
                >
                  <div className={Style.operation_table_body_column}>
                    <AutoSizeTextArea
                      value={!isEditMode ? eachItem.name || "--" : eachItem.name || ""}
                      readOnly
                      maxRows={1}
                      textareaClassName={Style.operation_table_input}
                      onChangeText={(text) => {
                        onChange(text, index, "name");
                      }}
                    />
                  </div>
                  <div className={Style.operation_table_body_column_wide}>
                    <DatePicker
                      value={eachItem.start_date}
                      readOnly={!isEditMode}
                      inputClassName={Style.operation_table_input}
                      inputContainerClassName={Style.operation_table_input_container}
                      onTimeChange={(time) => {
                        onChange(time, index, "start_date");
                      }}
                    />
                  </div>
                  <div className={Style.operation_table_body_column_wide}>
                    <DatePicker
                      value={eachItem.completion_date}
                      readOnly={!isEditMode}
                      inputClassName={Style.operation_table_input}
                      inputContainerClassName={Style.operation_table_input_container}
                      onTimeChange={(time) => {
                        onChange(time, index, "completion_date");
                      }}
                    />
                  </div>
                  <div className={Style.operation_table_body_column}>
                    <AutoSizeTextArea
                      value={!isEditMode ? eachItem.operation_output || "--" : eachItem.operation_output || ""}
                      readOnly={!isEditMode}
                      numberOnly
                      maxRows={1}
                      textareaClassName={Style.operation_table_input}
                      onChangeText={(text) => {
                        onChange(text, index, "operation_output");
                      }}
                    />
                  </div>
                  <div className={Style.operation_table_body_column}>
                    <AutoSizeTextArea
                      value={!isEditMode ? eachItem.operation_machine || "--" : eachItem.operation_machine || ""}
                      readOnly={!isEditMode}
                      maxRows={1}
                      textareaClassName={Style.operation_table_input}
                      onChangeText={(text) => {
                        onChange(text, index, "operation_machine");
                      }}
                    />
                  </div>
                  <div className={Style.operation_table_body_column}>
                    <AutoSizeTextArea
                      value={!isEditMode ? eachItem.operation_operator || "--" : eachItem.operation_operator || ""}
                      readOnly={!isEditMode}
                      maxRows={1}
                      textareaClassName={Style.operation_table_input}
                      onChangeText={(text) => {
                        onChange(text, index, "operation_operator");
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <div className={`${Style.flex_row} ${Style.operation_table_body_item_empty}`}>
              <p className={Style.operation_table_empty_list_message_text}>No data to show</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default (props) => {
  return (
    <OrderDetailSubscriptionContextProvider>
      <OrderDetailPage {...props} />
    </OrderDetailSubscriptionContextProvider>
  );
};

export class OrderDetailPage extends React.Component {
  static contextType = useOrderDetailSubscriptionContext();

  constructor(props) {
    super(props);
    this.state = {
      titleStructure: {},
      contentStructureList: [],
      orderState: {},
      route: [],
      locations: [],
      associatedParts: [],
      associatedPartsTableHeaders: [],
      associatedPartsTableData: [],
      routeTitleList: [],
      partTitleList: [],
      nodeStructureList: [],
      itemId: "",
      hide: false,
      editPart: false,
      partCrudTableData: [],
      addLocation: false,
      locationToAdd: {},
      partInfo: [],
      partConstraint: {},
      loading: true,
      timeTrackingEnabled: false,
      showShipmentIssueModal: false,
      selectedShipmentIssue: null,
      isSubmitting: false,
      addLocationConfig: {
        displayName: "Add Location",
        disabled: false,
        adminOnly: false
      },
      shipmentIssueConfig: {
        displayName: "Issue with Shipment",
        disabled: false
      },
      remarks: "",
      remarksEnabled: false,
      operations: [],
      taskId: "",
      taskRoutingId: "",
      orderRouteTableEnabled: true,
      operationStatusTableEnabled: false,
      useV2: false,
      showExpeditedAlert: false,
      expeditedToggleValue: false,
      disableDeleteButton: false,
      taskStatusDisplayMap: {}
    };
  }

  componentDidMount() {
    const values = queryString.parse(window.location.search);
    const { itemId } = values;
    const { setCallbackFn } = this.context;
    setCallbackFn(() => {
      return this.onLoad;
    });
    const { sendMixPanelEvent } = this.context.mixpanelContext;
    sendMixPanelEvent(WORK_ORDER_DETAIL_PAGE_EVENT, WORK_ORDER_DETAIL_PAGE_STEPS.ENTRY);
    this.onLoad(itemId);
  }

  getDefaultShipmentIssueOptions = () => {
    return defaultShipmentIssueOptionsList;
  };

  getCustomerSpecificShipmentIssueOptions = () => {
    return customerSpecificShipmentIssueOptionsList;
  };

  processAssociatedPartsData = (rawPartsList, attributeList, availablePartFlags) => {
    const processedDataObject = {};
    const processedPartsDatalist = [];

    rawPartsList.forEach((rawPartInfo = {}) => {
      attributeList.forEach((titleObject) => {
        const { type, key, format = "hh mm A DD MMM, YYYY" } = titleObject;
        switch (titleObject.type) {
          case "link":
            if (rawPartInfo[key]) {
              processedDataObject[key] = {
                id: key,
                displayText: rawPartInfo[key]
              };
              if (key === ASSOCIATED_PART_NAME_KEY || key === ASSOCIATED_PART_IDENTIFIER_KEY) {
                processedDataObject[key].link = `/work-order/part/detail?itemId=${rawPartInfo[ASSOCIATED_PART_ID_KEY]}`;
              }
            } else {
              processedDataObject[key] = "-";
            }
            break;
          case "status":
            processedDataObject[key] = getStatusFlags(rawPartInfo[key] || [], availablePartFlags);
            break;
          case "date":
            processedDataObject[key] = rawPartInfo[key] ? getFormattedDate(rawPartInfo[key], format) : "-";
            break;
          default:
            processedDataObject[key] = rawPartInfo[key] || "-";
            break;
        }
      });
      processedPartsDatalist.push({ ...processedDataObject });
    });

    return [...processedPartsDatalist];
  };

  onLoad = async (itemId) => {
    const { configData } = await ConfigurationService.getFullConfiguration();
    const { possibleDetectorLocations } = await ConfigurationService.getCustomerConfigInfo();
    const allSolutionFlags = await ConfigurationService.getStatusConfiguration();
    const availableOrderFlags = allSolutionFlags.order || {};
    const availablePartFlags = allSolutionFlags.part || {};
    const attributeMap = await ConfigurationService.getSolutionAttributeMap("order");
    const userProfile = LocalCacheService.loadUserProfile();
    const isAdmin = userProfile.isUserSuperAdmin();
    const { defaultAttributeMap = {}, customAttributeMap = {} } = attributeMap;
    const { components, features, solutionType, solutionConfiguration, webClient } = configData;
    const { ItemRelationshipConstraints, TrackedItemTypes } = components.item;
    const { task = {}, item = {} } = components;
    const { taskMetaInformation = {} } = task;
    const { timeTracking = {} } = features.tracking;
    const trackingLocation = possibleDetectorLocations.length > 0 && possibleDetectorLocations;
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();

    let part;
    const { shipments } = solutionConfiguration;
    TrackedItemTypes.forEach((eachType) => {
      const { contains } = ItemRelationshipConstraints[eachType];
      part = contains.part;
    });

    const detailsPageConfig = webClient.appConfigurationMap.order.detailsPage || {};
    const { daysForHot = DEFAULT_DAYS_FOR_HOT } = webClient.appConfigurationMap?.order || {};

    const useV2 = webClient.appConfigurationMap.order.useV2 || false;

    const {
      features: detailFeatures = {},
      hotShipmentPriorityThreshold = 32,
      remarksEnabled,
      orderRouteTableEnabled = true,
      operationStatusTableEnabled,
      taskStatusDisplayMap: newTaskStatusDisplayMap = {}
    } = detailsPageConfig;

    const {
      addLocation = {},
      shipmentIssue = {},
      shipmentHistory = {},
      associatedPartsTable = {},
      markAsComplete = {},
      createAndPrintTags: createAndPrintTagsConfig = {}
    } = detailFeatures;
    const addLocationConfig = {
      disabled: addLocation.disabled || false,
      displayName: addLocation.displayName || "Add Location",
      adminOnly: addLocation.adminOnly || false
    };
    const markAsCompleteConfig = {
      adminOnly: markAsComplete.adminOnly || false,
      disabled: markAsComplete.disabled || false,
      endPartsTrackingSession: markAsComplete.endPartsTrackingSession || false
    };
    const shipmentIssueConfig = {
      disabled: shipmentIssue.disabled || false,
      displayName: shipmentIssue.displayName || "Issue with Shipment"
    };
    const { useV2: useShipmentHistoryV2 = false } = shipmentHistory;

    const { attributeList: partAttributeList = [] } = associatedPartsTable;

    if (part.quantity === "one") {
      partAttributeList.filter((element) => !element.many);
    }
    const associatedPartsHeaders = partAttributeList
      ?.sort((a1, a2) => a1.index - a2.index)
      .map((attribute = {}) => {
        return { value: attribute.label, ...attribute };
      });

    const showShipmentData =
      solutionType.includes("shipments") && shipments.shipmentsTrackingItemClasses.includes("Traveller");
    const timeTrackingEnabled = !!timeTracking.allow;

    const additionalQueryAttributeMap = {
      item: [],
      task: []
    };
    Object.keys(customAttributeMap).forEach((element) => {
      const { propertyFor } = customAttributeMap[element];
      if (additionalQueryAttributeMap[propertyFor]) {
        additionalQueryAttributeMap[propertyFor].push(element);
      }
    });

    if (remarksEnabled) {
      additionalQueryAttributeMap.task.push("comments");
    }

    // TODO: remove 'Facility' in the second milestone
    WorkOrderClient.getWorkOrderById(
      itemId,
      trackingLocation,
      undefined,
      showShipmentData,
      useShipmentHistoryV2,
      additionalQueryAttributeMap,
      daysForHot,
      operationStatusTableEnabled
    ).then((data) => {
      const {
        orderState,
        associatedParts,
        route,
        partTypes,
        locations,
        jobHistory,
        shipmentHistory,
        operations,
        taskId,
        taskRoutingId
      } = data;

      const remarks = orderState["task-comments"] || "";

      const partInfo = associatedParts.map((eachPart) => {
        return {
          id: eachPart.id,
          number: eachPart.partNumber,
          name: eachPart.name,
          qty: eachPart.quantity,
          location: eachPart.location
        };
      });

      const associatedPartsData = this.processAssociatedPartsData(
        associatedParts,
        associatedPartsHeaders,
        availablePartFlags
      );

      const defaultStructureList = Object.keys(defaultAttributeMap)
        .filter((eachId) => {
          return !defaultAttributeMap[eachId].adminOnly || isAdmin;
        })
        .map((eachId) => {
          const { type } = defaultAttributeMap[eachId];
          return {
            index: defaultAttributeMap[eachId].index,
            id: eachId,
            key: eachId,
            name: `${defaultAttributeMap[eachId].label}`,
            type,
            value:
              orderState[eachId] !== null && orderState[eachId] !== undefined
                ? orderState[eachId]
                : defaultAttributeMap[eachId].defaultValue
                ? defaultAttributeMap[eachId].defaultValue
                : "-",
            editable: defaultAttributeMap[eachId].editable?.single,
            format: defaultAttributeMap[eachId].format,
            options: defaultAttributeMap[eachId].options
          };
        });

      if (useV2) {
        const expeditedIndex = defaultStructureList.findIndex((each) => {
          return each.id === "expedited";
        });
        if (expeditedIndex >= 0) {
          defaultStructureList[expeditedIndex].value = orderState.priority <= 9 && orderState.priority >= 1;
        }
      }

      const customStructureList = Object.keys(customAttributeMap)
        .filter((eachId) => {
          return !customAttributeMap[eachId].adminOnly || isAdmin;
        })
        .map((eachId) => {
          const { type, propertyFor } = customAttributeMap[eachId];
          const property = propertyFor !== "item" ? `${propertyFor}-${eachId}` : eachId;
          return {
            index: customAttributeMap[eachId].index,
            id: property,
            key: property,
            name: `${customAttributeMap[eachId].label}`,
            type,
            value:
              orderState[property] !== null && orderState[property] !== undefined
                ? orderState[property]
                : customAttributeMap[eachId].defaultValue
                ? customAttributeMap[eachId].defaultValue
                : "-",
            editable: customAttributeMap[eachId].editable?.single,
            format: customAttributeMap[eachId].format,
            options: customAttributeMap[eachId].options
          };
        });

      let contentStructureList = [...defaultStructureList, ...customStructureList];

      contentStructureList = contentStructureList.sort((a, b) => {
        return a.index - b.index;
      });

      const titleStructure = {
        key: contentStructureList[0].id,
        name: contentStructureList[0].name,
        value: contentStructureList[0].value,
        editable: contentStructureList[0].editable
      };
      contentStructureList = contentStructureList.slice(1);

      const { isActive } = orderState;

      if (isActive && !markAsCompleteConfig.disabled && !(markAsCompleteConfig.adminOnly && !isAdmin)) {
        contentStructureList.push({
          key: "markAsCompleted",
          name: "Mark As Completed",
          type: "button",
          onClick: () => {
            this.setState({
              showConfirmationModal: true
            });
          }
        });
      }

      if (
        isActive &&
        createAndPrintTagsConfig.locationName &&
        route?.[0]?.location === createAndPrintTagsConfig.locationName
      ) {
        contentStructureList.push({
          key: "createAndPrintTags",
          name: createAndPrintTagsConfig.displayName || "Print Tags",
          type: "button",
          light: true,
          onClick: () => {
            this.setState({
              showCreateAndPrintTagsModal: true
            });
          }
        });
      }

      let routeTitleList = [
        {
          key: "location",
          value: "Location"
        },
        {
          key: "entryTime",
          value: "Entry Time"
        },
        {
          key: "exitTime",
          value: "Exit Time"
        },
        {
          key: "timeDuration",
          value: "Time Duration"
        }
      ];
      const shipmentTitleList = useShipmentHistoryV2
        ? [
            { key: "event", value: "Event" },
            { key: "details", value: "Event Details" },
            { key: "eventTime", value: "Start Time" },
            { key: "shipmentTimeDuration", value: "Duration" }
          ]
        : [
            {
              key: "shipmentLocation",
              value: "Location"
            },
            {
              key: "eventTime",
              value: "Event Time"
            },
            { key: "eventDetails", value: "Event Details", extraData: true },
            { key: "detector", value: "Detector" },
            { key: "shipmentTimeDuration", value: "Time Duration" }
          ];

      const shipmentIssueOptions = [
        ...this.getDefaultShipmentIssueOptions(),
        ...this.getCustomerSpecificShipmentIssueOptions()
      ].sort(multipleSortComparison([{ id: "label" }]));

      const nodeStructureList = [];

      // Calculate Route Table Info for Time Tracking

      if (timeTrackingEnabled) {
        routeTitleList = [
          {
            key: "location",
            value: "Location"
          },
          {
            key: "totalJobProcessTime",
            value: "Process Time",
            type: "time"
          },
          {
            key: "totalJobQueueTime",
            value: "In Queue Time",
            type: "time"
          },
          {
            key: "totalJobTime",
            value: "Total Time",
            type: "time"
          }
        ];
        // Uses the populated routeTitleList to update the one defined above.
        // Fills in the values for each job, for each person.
        Object.keys(jobHistory).forEach((key) => {
          const PersonJobsHistory = jobHistory[key];
          PersonJobsHistory.jobs.forEach((eachJob) => {
            let i = 0;
            while (i < route.length && route[i].entryTime <= eachJob.startTime) {
              i += 1;
            }
            if (i > 0) {
              if (!route[i - 1].jobs) {
                route[i - 1].jobs = [];
              }
              route[i - 1].jobs.push({
                family_name: PersonJobsHistory.family_name,
                given_name: PersonJobsHistory.given_name,
                entry_time: route[i - 1].entryTime,
                exit_time: route[i - 1].exitTime,
                ...eachJob
              });
            }
          });
        });
      }
      // Uses the table populated above to display the details
      // And calculates the overall time in each process.
      let overallIdleTime = 0;
      let overallProcessTime = 0;
      route.forEach((each, index) => {
        nodeStructureList.push({
          route: each.location,
          sequence_number: index,
          done: true,
          processing: !each.exitTime
        });
        if (timeTrackingEnabled) {
          let totalProcessTime = 0;
          let totalIdleTime = 0;
          // Time between process entering a location and being started.
          let idlingTime = 0;
          if (each.jobs) {
            each.jobs.forEach((job) => {
              totalIdleTime += job.idleDuration;
              idlingTime = job.startTime - job.entry_time;
              if (job.endTime) {
                idlingTime += (job.exit_time || Date.now()) - job.endTime;
              }
              totalProcessTime += job.duration;
            });
            each.jobs.sort((a, b) => {
              return a.startTime - b.startTime;
            });
          }
          totalIdleTime += idlingTime || (each.exitTime || Date.now()) - each.entryTime;
          overallIdleTime += totalIdleTime;
          overallProcessTime += totalProcessTime;
          each.totalJobProcessTime = totalProcessTime;
          each.totalJobQueueTime = totalIdleTime;
          each.totalJobTime = totalProcessTime + totalIdleTime;
        }
        each.timeDuration = msToTime((each.exitTime || Date.now()) - each.entryTime);
        each.entryTime = getFormattedDate(each.entryTime, "hh:mm A MMM D");
        each.exitTime = each.exitTime ? getFormattedDate(each.exitTime, "hh:mm A MMM D") : "-";
      });

      orderState.statusFlags = getStatusFlags(orderState.statusFlags, availableOrderFlags);

      const filteredLocations = locations
        .filter((eachLocation) => eachLocation.id !== orderState.currentLocationId)
        .sort(multipleSortComparison([{ id: "name" }]));
      let newLocations = [];
      filteredLocations.map((each) => {
        return newLocations.push({
          key: each.id,
          label: each.name,
          value: each.id
        });
      });

      // Process event time and duration for each event
      if (showShipmentData) {
        shipmentHistory.forEach((row, index) => {
          const currEvent = row.eventTime;
          const prevEvent = shipmentHistory[index + 1] && shipmentHistory[index + 1].eventTime;
          row.shipmentTimeDuration = msToTime((prevEvent || Date.now()) - currEvent);
          row.eventTime = getFormattedDate(row.eventTime, "hh:mm A MMM D");

          if (useShipmentHistoryV2) {
            if (index != 0 && shipmentHistory[index - 1].identifier === row.identifier) {
              row.details = "";
            } else {
              row.details = `${
                row.priority !== null && row.priority <= hotShipmentPriorityThreshold ? "Hot" : ""
              } Shipment ID: ${row.identifier}`;
            }
          }

          if (row.eventDetails) {
            const { condition = "", comment = "", submittedBy = "" } = row.eventDetails;
            if (condition || comment || submittedBy) {
              row.extraData = [
                { key: "condition", value: "Condition", data: condition },
                { key: "comment", value: "Comment", data: comment },
                { key: "submittedBy", value: "Submitted By", data: submittedBy }
              ];
            }
            row.eventDetails = row.eventDetails.class;
          }
        });
      }

      this.setState({
        taskMetaInformation,
        partConstraint: { quantity: part.quantity, delimiter: part.delimiter },
        partTitleList: [],
        timeTrackingEnabled: !!timeTracking.allow,
        showShipmentData,
        overallIdleTime,
        overallProcessTime,
        itemId,
        orderState,
        route,
        shipmentHistory,
        partTypes,
        associatedParts,
        associatedPartsTableHeaders: associatedPartsHeaders,
        associatedPartsTableData: associatedPartsData,
        partInfo,
        routeTitleList,
        shipmentTitleList,
        titleStructure,
        contentStructureList,
        nodeStructureList,
        shipmentIssueOptions,
        useShipmentHistoryV2,
        addLocationConfig,
        markAsCompleteConfig,
        isAdmin,
        shipmentIssueConfig,
        locations: newLocations,
        partCrudTableData: partInfo,
        editPart: false,
        addLocation: false,
        locationToAdd: {},
        showConfirmationModal: false,
        showDeleteModal: false,
        showCreateAndPrintTagsModal: false,
        loading: false,
        isSubmitting: false,
        remarks,
        remarksEnabled,
        operations,
        taskId,
        taskRoutingId,
        orderRouteTableEnabled,
        operationStatusTableEnabled,
        useV2,
        taskStatusDisplayMap: newTaskStatusDisplayMap || {}
      });
    });
  };

  updateOperations = async (editedOperations) => {
    const { itemId, taskId, taskRoutingId = {}, orderState } = this.state;
    const operationPayload = editedOperations.map(
      ({ id, operation_machine, operation_operator, operation_output, start_date, completion_date }) => {
        let operation_status = "Indeterminate";
        if (start_date) {
          operation_status = "InProgress";
        }
        if (completion_date) {
          operation_status = "Completed";
        }

        return {
          id,
          fields: {
            operation_status,
            operation_machine: operation_machine || "",
            operation_operator: operation_operator || "",
            operation_output: operation_output * 1 || 0,
            start_date: start_date || undefined,
            completion_date: completion_date || undefined
          }
        };
      }
    );

    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();

    await WorkOrderClient.updateOperations(taskId, taskRoutingId, operationPayload);

    const updateStatus = await WorkOrderClient.updateTaskStatusByOperations(taskId);

    if (updateStatus === "Completed") {
      await WorkOrderClient.markWorkOrderAsComplete(itemId, orderState.sensorProfileVid !== null);
    }
    await this.onLoad(itemId);
  };

  getAdditionalMoreButtonOptions = () => {
    const { isAdmin } = this.state;
    return (
      isAdmin && (
        <DropdownItem
          className="dropdown-item"
          onClick={() => {
            this.setState({ showDeleteModal: true });
          }}
        >
          <div
            className={Style.red_text}
            data-cy-work-order-track-page-order-detail-page__delete-work-order
          >
            Delete work order
          </div>
        </DropdownItem>
      )
    );
  };

  renderConfirmationModal = () => {
    const { showConfirmationModal, orderState, disableDeleteButton } = this.state;
    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showConfirmationModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">{`Mark ${orderState.identifier} As Completed?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You're about to mark
            <b style={{ fontWeight: "bold" }}>{` ${orderState.identifier} `}</b>
            as completed, are you sure you want to do this?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({ showConfirmationModal: false });
            }}
          >
            Cancel
          </button>
          <button
            disabled={disableDeleteButton}
            className="default-button"
            onClick={this.markAsComplete}
            data-cy-work-order-track-page-order-detail-page__confirm-button
          >
            {disableDeleteButton ? <span className="spinner-border spinner-border-sm" /> : "Confirm"}
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderCreateAndPrintTagsModal = () => {
    const { showCreateAndPrintTagsModal, orderState, itemId } = this.state;

    return (
      showCreateAndPrintTagsModal && (
        <CreateAndPrintTagsModal
          order={orderState}
          orderId={itemId}
          onCloseModal={(success) => {
            this.setState({
              showCreateAndPrintTagsModal: false
            });

            if (success) {
              this.props.history.goBack();
            }
          }}
        />
      )
    );
  };

  renderDeleteModal = () => {
    const { showDeleteModal, orderState } = this.state;
    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showDeleteModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">{`Delete ${orderState.identifier}?`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You're about to delete
            <span className={Style.bold_text}>{` ${orderState.identifier}`}</span>, are you sure you want to do this?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({ showDeleteModal: false });
            }}
          >
            Cancel
          </button>
          <button
            className="confirm-delete-button"
            onClick={this.handleDelete}
            data-cy-work-order-track-page-order-detail-page__delete-button
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  markAsComplete = async () => {
    const { sendMixPanelEvent } = this.context.mixpanelContext;
    const { itemId, orderState, markAsCompleteConfig = {}, useV2 } = this.state;
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    this.setState({ disableDeleteButton: true });
    try {
      if (markAsCompleteConfig.endPartsTrackingSession) {
        // params -> (itemId:string, detachSensorProfile:boolean)
        await WorkOrderClient.markWorkOrderAndPartAsComplete(itemId, true);
      } else {
        await WorkOrderClient.markWorkOrderAsComplete(itemId, orderState.sensorProfileVid !== null);
      }

      if (useV2) {
        await WorkOrderClient.updateWorkOrder(
          itemId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          { task: { status: "Completed" } },
          undefined
        );
      }

      await this.onLoad(itemId);
      sendMixPanelEvent(WORK_ORDER_DETAIL_PAGE_EVENT, WORK_ORDER_DETAIL_PAGE_STEPS.MARK_AS_COMPLETED_SUCCESS);
    } catch (e) {
      sendMixPanelEvent(WORK_ORDER_DETAIL_PAGE_EVENT, WORK_ORDER_DETAIL_PAGE_STEPS.MARK_AS_COMPLETED_FAILED, {
        error_message: e.message
      });
      this.setState({ disableDeleteButton: false });
    }
  };

  handleDelete = async () => {
    const { itemId } = this.state;
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    try {
      await WorkOrderClient.deleteWorkOrders([itemId]);
    } catch (e) {
      console.log(e);
    } finally {
      this.props.history.goBack();
    }
  };

  handleEditPart = (data) => {
    this.setState({ partCrudTableData: data });
  };

  renderEditPartModal = (editPart) => {
    const { partInfo, partConstraint, isSubmitting } = this.state;
    return (
      <Modal
        centered
        size={partConstraint.quantity === "many" ? "xl" : "s"}
        show={editPart}
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header>
          <Modal.Title>Edit Associated Part(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={partConstraint.quantity === "many" ? Style.modal : Style.modal_small}>
            <PartCrudTable
              partConstraint={partConstraint}
              partInfo={partInfo}
              changeHandler={this.handleEditPart}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={Style.cancel_button}
            onClick={() =>
              this.setState({
                editPart: !editPart
              })
            }
          >
            Cancel
          </button>
          <button
            disabled={isSubmitting}
            type="button"
            className={Style.save_button}
            onClick={this.handleSubmitPart}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderAddLocationModal = (addLocation) => {
    const { locations, locationToAdd, isSubmitting, addLocationConfig } = this.state;
    return (
      <Modal
        centered
        show={addLocation}
        backdrop="static"
        backdropClassName={Style.backdrop}
      >
        <Modal.Header>
          <Modal.Title>{addLocationConfig.displayName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputLabel>Select a location</InputLabel>
          <SearchDropdown
            selectedItem={locationToAdd || {}}
            showIcon
            options={locations}
            onItemSelected={(event) => {
              this.setState({
                locationToAdd: event
              });
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className={Style.cancel_button}
            onClick={() =>
              this.setState({
                locationToAdd: {},
                addLocation: !addLocation
              })
            }
          >
            Cancel
          </button>
          <button
            disabled={Object.keys(locationToAdd).length === 0 || isSubmitting}
            type="button"
            className={Style.save_button}
            onClick={this.handleAddLocation}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  handleAddLocation = () => {
    const { itemId, locationToAdd } = this.state;
    this.setState({ isSubmitting: true });
    const publishClient = XemelgoService.getClient().getPublishClient();
    publishClient.publishUserEvent([itemId], locationToAdd.value).then(() => {
      this.onLoad(itemId);
    });
  };

  handleSubmitPart = () => {
    const { partCrudTableData = [], partInfo, itemId, partTypes } = this.state;
    this.setState({ isSubmitting: true });
    const quantitiesToUpdate = [];
    const partsToAssociate = {
      createAndAssociate: [],
      associate: []
    };
    const partsToDisassociate = [];
    partCrudTableData.forEach((eachPartData) => {
      let obj = partInfo.find((eachAssociatedPart) => {
        return eachAssociatedPart.id === eachPartData.id;
      });

      if (obj) {
        if (obj.quantity !== eachPartData.qty * 1) {
          quantitiesToUpdate.push({
            id: eachPartData.id,
            quantity: eachPartData.qty * 1
          });
        }
      } else {
        obj = partTypes.find((eachTypes) => {
          return eachTypes.getId() === eachPartData.id;
        });
        if (obj) {
          partsToAssociate.associate.push({
            id: eachPartData.id,
            quantity: eachPartData.qty * 1
          });
        } else {
          partsToAssociate.createAndAssociate.push({
            partNumber: eachPartData.number,
            quantity: eachPartData.qty * 1,
            name: eachPartData.name,
            imagePath: undefined
          });
        }
      }
    });
    partInfo.forEach((eachData) => {
      const obj = partCrudTableData.find((eachPartData) => {
        return eachPartData.id === eachData.id;
      });
      if (!obj) {
        partsToDisassociate.push(eachData.id);
      }
    });
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    WorkOrderClient.updateAssociatedParts(itemId, {
      quantitiesToUpdate,
      partsToAssociate,
      partsToDisassociate
    }).then(async () => {
      await this.onLoad(itemId);
    });
  };

  handleExpeditedStatusChanged = (enabled) => {
    this.setState({ showExpeditedAlert: true, expeditedToggleValue: enabled });
  };

  updateExpeditedStatus = async (enabled) => {
    const { itemId, useV2 } = this.state;
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    try {
      if (useV2) {
        const customFields = { task: { priority: enabled ? 9 : 0 } };
        await WorkOrderClient.updateWorkOrder(
          itemId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          Object.keys(customFields).length ? customFields : undefined,
          undefined
        );
      } else {
        const setUpdatePayload = { flagsToAdd: [], flagsToRemove: [] };
        if (enabled) {
          setUpdatePayload.flagsToAdd.push("Expedited");
        } else {
          setUpdatePayload.flagsToRemove.push("Expedited");
        }
        await WorkOrderClient.updateWorkOrder(
          itemId,
          undefined,
          undefined,
          undefined,
          setUpdatePayload,
          undefined,
          undefined,
          undefined
        );
      }
    } catch (err) {}

    await this.onLoad(itemId);
  };

  handleSubmitDetail = async (dataForm) => {
    this.setState({
      loading: true
    });
    const { itemId, orderState, useV2 } = this.state;
    const { identifier, dueDate, expedited } = dataForm;
    const { statusFlags } = orderState;
    const newStatusFlag = _.cloneDeep(statusFlags);
    const attributeMap = await ConfigurationService.getSolutionAttributeMap("order");
    const { customAttributeMap = {} } = attributeMap;
    const customFields = {};
    Object.keys(customAttributeMap).forEach((eachId) => {
      const { propertyFor } = customAttributeMap[eachId];
      const property = propertyFor !== "item" ? `${propertyFor}-${eachId}` : eachId;
      if (dataForm[property] !== undefined) {
        if (!customFields[propertyFor]) {
          customFields[propertyFor] = [];
        }
        customFields[propertyFor][eachId] = dataForm[property];
      }
    });
    let setUpDate;
    if (useV2) {
      customFields.task = { ...customFields.task, priority: expedited ? 9 : 0 };
    } else if (expedited !== undefined) {
      setUpDate = { flagsToAdd: [], flagsToRemove: [] };
      if (expedited) {
        setUpDate.flagsToAdd.push("Expedited");
        newStatusFlag.push("Expedited");
      } else {
        setUpDate.flagsToRemove.push("Expedited");
        const index = newStatusFlag.findIndex((a) => a === "Expedited");
        newStatusFlag.splice(index, 1);
      }
    }

    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    WorkOrderClient.updateWorkOrder(
      itemId,
      identifier || undefined,
      dueDate || undefined,
      undefined,
      setUpDate,
      undefined,
      Object.keys(customFields).length ? customFields : undefined,
      dataForm.imagePath || undefined
    )
      .then(() => {})
      .catch((err) => window.alert(err))
      .finally(async () => {
        await this.onLoad(itemId);
      });
  };

  handleSubmitRemarks = async (remarks) => {
    const { itemId } = this.state;
    const WorkOrderClient = XemelgoService.getClient().getWorkOrderClient();
    await WorkOrderClient.updateWorkOrder(itemId, undefined, undefined, undefined, undefined, undefined, {
      task: { comments: remarks }
    });
    this.setState({
      remarks
    });
  };

  reportShipmentIssue = async () => {
    const { itemId, selectedShipmentIssue, commentBoxText } = this.state;
    const context = {
      shipment: {
        value: "Shipment Issue",
        condition: selectedShipmentIssue.value,
        comment: commentBoxText || ""
      }
    };
    const publishClient = XemelgoService.getClient().getPublishClient();
    try {
      this.setState({ isSubmitting: true });
      await publishClient.publishUserEvent([itemId], null, context);
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        showShipmentIssueModal: false,
        selectedShipmentIssue: null,
        commentBoxText: ""
      });
      await this.onLoad(itemId);
    }
  };

  handleCommentBox = ({ currentTarget }) => {
    this.setState({ [currentTarget.id]: currentTarget.value });
  };

  renderExpeditedAlertModal = () => {
    const { showExpeditedAlert, expeditedToggleValue } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showExpeditedAlert}
        animation={false}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">{`${
            expeditedToggleValue ? "Expedite" : "Un-expedite"
          } Work Order`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Do you want to <b style={{ fontWeight: "bold" }}>{expeditedToggleValue ? "expedite" : "un-expedite"}</b>{" "}
            this work order?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({ showExpeditedAlert: false, expeditedToggleValue: null });
            }}
          >
            Cancel
          </button>
          <button
            className="default-button"
            onClick={() => {
              this.setState({
                showExpeditedAlert: false,
                expeditedToggleValue: null,
                loading: true
              });
              this.updateExpeditedStatus(expeditedToggleValue);
            }}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderShipmentIssueModal = () => {
    const {
      showShipmentIssueModal,
      orderState,
      shipmentIssueOptions,
      selectedShipmentIssue,
      commentBoxText,
      isSubmitting
    } = this.state;

    return (
      <Modal
        centered
        backdrop="static"
        backdropClassName={Style.backdrop}
        show={showShipmentIssueModal}
      >
        <Modal.Header className="route-modal-header">
          <Modal.Title className="route-modal-title">
            {`Report Issue With Shipment for ${orderState.identifier}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <InputLabel>What is wrong with the shipment?</InputLabel>
            <SearchDropdown
              selectedItem={selectedShipmentIssue || {}}
              showIcon
              options={shipmentIssueOptions}
              onItemSelected={(event) => {
                this.setState({
                  selectedShipmentIssue: event
                });
              }}
            />
            <div className={Style.commentBoxContainer}>
              <InputLabel>Comment (Optional)</InputLabel>
              <TextareaAutosize
                id="commentBoxText"
                className={Style.detail_value_input}
                value={commentBoxText}
                onChange={this.handleCommentBox}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="cancel-button"
            onClick={() => {
              this.setState({
                showShipmentIssueModal: false,
                selectedShipmentIssue: null,
                commentBoxText: ""
              });
            }}
          >
            Cancel
          </button>
          <button
            disabled={!selectedShipmentIssue || isSubmitting}
            className="default-button"
            onClick={this.reportShipmentIssue}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    );
  };

  getStatuses(orderState) {
    const STATUS_COLOR_MAP = {
      Healthy: "#4B9910",
      Warning: "#F6B044",
      Critical: "#FF3D46",
      Expedited: "#E56201",
      Unset: "#C6CBD4"
    };
    let hasSeverity = false;
    const statuses = [];
    if (orderState.status === "Completed" || (typeof orderState.isActive === "boolean" && !orderState.isActive)) {
      statuses.push({ displayText: "Completed", color: STATUS_COLOR_MAP.Unset });
    } else {
      if (orderState.operationStartStatus === "Delayed") {
        statuses.push({
          displayText: `Late to Start - ${orderState.operationDelayedStartSeverity}`,
          color: STATUS_COLOR_MAP[orderState.operationDelayedStartSeverity]
        });
        hasSeverity = true;
      }
      if (orderState.operationStatus === "Time Exceeded") {
        statuses.push({
          displayText: `Time Exceeded - ${orderState.operationSeverity}`,
          color: STATUS_COLOR_MAP[orderState.operationSeverity]
        });
        hasSeverity = true;
      }
      if (orderState.priority >= 1 && orderState.priority <= 9) {
        statuses.push({ displayText: "Expedited", color: STATUS_COLOR_MAP.Expedited });
      }

      if (orderState.status) {
        statuses.unshift({
          displayText: this.state.taskStatusDisplayMap[orderState.status] || orderState.status,
          color: hasSeverity ? STATUS_COLOR_MAP.Unset : STATUS_COLOR_MAP.Healthy
        });
      }
    }
    return statuses;
  }

  render() {
    const {
      hide,
      titleStructure,
      contentStructureList,
      orderState,
      showShipmentData,
      route,
      shipmentHistory,
      associatedParts,
      associatedPartsTableHeaders,
      associatedPartsTableData,
      routeTitleList,
      shipmentTitleList,
      partTitleList,
      nodeStructureList,
      editPart,
      addLocation,
      loading,
      timeTrackingEnabled,
      overallIdleTime,
      overallProcessTime,
      partConstraint,
      showShipmentIssueModal,
      addLocationConfig,
      shipmentIssueConfig,
      isAdmin,
      remarks,
      remarksEnabled,
      operations,
      orderRouteTableEnabled,
      operationStatusTableEnabled,
      useV2
    } = this.state;

    if (loading) {
      return <LoadingCircle />;
    }
    return (
      <Fragment>
        <ScreenFrame
          title="Work Order Details"
          color={xemelgoStyle.theme.ORDER_PRIMARY}
          secondaryColor={xemelgoStyle.theme.ORDER_SECONDARY}
          titleIconComponent={
            <OrderIcon
              width={25}
              height={25}
              style={{ color: xemelgoStyle.theme.ORDER_PRIMARY }}
            />
          }
        >
          <div className={Style.content_holder}>
            <div className={Style.detail_group}>
              <DetailCard
                isActive={orderState.isActive}
                statusList={useV2 ? this.getStatuses(orderState) : orderState.statusFlags}
                titleStructure={titleStructure}
                detailStructureList={contentStructureList}
                onSubmit={this.handleSubmitDetail}
                getAdditionalMoreButtonOptions={this.getAdditionalMoreButtonOptions}
                remarks={remarks}
                remarksEnabled={remarksEnabled}
                onRemarksSubmit={this.handleSubmitRemarks}
                onExpeditedStatusChange={this.handleExpeditedStatusChanged}
              />
            </div>
            <div className={Style.table_group}>
              {partConstraint.quantity !== "none" && (
                <div className={Style.top_table}>
                  <div className={Style.title_group}>
                    <div className={Style.title_label}>
                      Associated Part(s)
                      <div
                        role="button"
                        tabIndex={-1}
                        className={Style.hide_btn}
                        onClick={() => this.setState({ hide: !hide })}
                        onKeyDown={() => this.setState({ hide: !hide })}
                      >
                        {!hide ? "Hide" : "Show"}
                        {!hide ? (
                          <KeyboardArrowUpRounded className={Style.show_hide_arrow} />
                        ) : (
                          <KeyboardArrowDownRounded className={Style.show_hide_arrow} />
                        )}
                      </div>
                    </div>
                  </div>
                  {hide || (
                    <DataTable
                      titleList={associatedPartsTableHeaders}
                      dataList={associatedPartsTableData}
                    />
                  )}
                </div>
              )}
              {orderRouteTableEnabled && (
                <div className={Style.bottom_table}>
                  <div className={Style.title_group}>
                    <div className={Style.title_label}>Order Route</div>
                    {orderState.isActive && !addLocationConfig.disabled && (!addLocationConfig.adminOnly || isAdmin) ? (
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => this.setState({ addLocation: !addLocation })}
                        className={Style.part_edit_btn}
                      >
                        {addLocationConfig.displayName}
                      </div>
                    ) : null}
                  </div>
                  {nodeStructureList.length > 0 && (
                    <ProgressGraph
                      stages={nodeStructureList}
                      currStage={0}
                    />
                  )}
                  {timeTrackingEnabled ? (
                    <Fragment>
                      <div className={Style.time_tracking_card_group}>
                        <div className={Style.time_tracking_card}>
                          <div className={Style.time_tracking_card_title}>TOTAL PROCESS TIME</div>
                          <div className={Style.time_tracking_card_value}>{msToTime(overallProcessTime)}</div>
                        </div>
                        <div className={Style.time_tracking_card}>
                          <div className={Style.time_tracking_card_title}>TOTAL IN QUEUE TIME</div>
                          <div className={Style.time_tracking_card_value}>{msToTime(overallIdleTime)}</div>
                        </div>
                        <div className={Style.time_tracking_card}>
                          <div className={Style.time_tracking_card_title}>TOTAL TIME</div>
                          <div className={Style.time_tracking_card_value}>
                            {msToTime(overallIdleTime + overallProcessTime)}
                          </div>
                        </div>
                      </div>
                      <TimeTrackingDataTable
                        titleList={routeTitleList}
                        dataList={route}
                      />
                    </Fragment>
                  ) : (
                    orderRouteTableEnabled && (
                      <DataTable
                        titleList={routeTitleList}
                        dataList={route}
                      />
                    )
                  )}
                </div>
              )}
              {operationStatusTableEnabled && (
                <OperationStatusTable
                  operations={operations}
                  onSaveClick={this.updateOperations}
                />
              )}
              {showShipmentData && (
                <div className={Style.bottom_table}>
                  <div className={Style.title_group}>
                    <div className={Style.title_label}>Shipping History</div>
                    {orderState.isActive && !shipmentIssueConfig.disabled ? (
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={() => {
                          this.setState({
                            showShipmentIssueModal: !showShipmentIssueModal
                          });
                        }}
                        className={Style.part_edit_btn}
                      >
                        {shipmentIssueConfig.displayName}
                      </div>
                    ) : null}
                  </div>
                  <DataTable
                    titleList={shipmentTitleList}
                    dataList={shipmentHistory}
                  />
                </div>
              )}
            </div>
          </div>
        </ScreenFrame>
        {this.renderEditPartModal(editPart)}
        {this.renderAddLocationModal(addLocation)}
        {this.renderConfirmationModal()}
        {this.renderShipmentIssueModal()}
        {this.renderDeleteModal()}
        {this.renderExpeditedAlertModal()}
        {this.renderCreateAndPrintTagsModal()}
      </Fragment>
    );
  }
}
