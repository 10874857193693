import React from "react";
import HeatMap from "./features/heat-map";
import StrategicSourcingTable from "./features/strategic-sourcing-table";
import Insights from "./features/insights";
import LinkGroup from "./features/link-group";
import StrategicSourcingMetrics from "./features/strategic-sourcing-metrics";
import SiteMap from "./features/site-map";
import StockRecommendations from "./features/stock-recommendations";
import CrossFunctionalMetrics from "./features/cross-functional-metrics";
import HeatMapMetrics from "./features/heat-map-metrics";

export const widgetDefinitionMap = {
  siteMap: {
    label: "Site Map",
    component: <SiteMap />,
    control: { w: 5, h: 12 }
  },
  heatMap: {
    label: "Heat Map",
    component: <HeatMap />,
    control: {
      w: 10,
      h: 11
    }
  },
  table: {
    label: "Strategic Sourcing Table",
    component: <StrategicSourcingTable />,
    control: {
      w: 10,
      h: 13
    }
  },
  linkGroup: {
    label: "Vallen Line of Slght",
    component: <LinkGroup />,
    control: {
      w: 5,
      h: 12
    }
  },
  insights: {
    label: "AI Insights",
    component: <Insights />,
    control: {
      w: 5,
      h: 12
    }
  },
  metrics: {
    label: "Strategic Sourcing Metrics",
    component: <StrategicSourcingMetrics />,
    control: {
      w: 10,
      h: 6
    }
  },
  stockRecommendations: {
    label: "Stock Recommendations",
    component: <StockRecommendations />,
    control: {
      w: 5,
      h: 12
    }
  },
  CrossFunctionalMetrics: {
    label: "Cross Functional Metrics",
    component: <CrossFunctionalMetrics />,
    control: {
      w: 10,
      h: 6
    }
  },
  HeatMapMetrics: {
    label: "Heat Map Metrics",
    component: <HeatMapMetrics />,
    control: {
      w: 10,
      h: 15
    }
  }
};
