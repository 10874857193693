import React from "react";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import xemelgoStyle from "../../../../../../styles/variable";
import Style from "./CrossFunctionalMetrics.module.css";

const MOCK_METRICS = [
  {
    id: "1",
    label: "Inventory Reserve Value",
    subTitle: "$3K",
    percentChange: 7,
    status: "healthy",
    direction: "down"
  },
  {
    id: "2",
    label: "Next Recovery Billing",
    subTitle: "6/1/24"
  },
  {
    id: "3",
    label: "Aged Transfers",
    subTitle: "$600",
    percentChange: 3,
    status: "critical",
    direction: "up"
  },
  {
    id: "5",
    label: "Bad Debt",
    subTitle: "$15K",
    percentChange: 5,
    status: "healthy",
    direction: "down"
  },
  {
    id: "6",
    label: "Shipped Not Billed",
    subTitle: "$2K",
    percentChange: 3,
    status: "critical",
    direction: "up"
  }
];

const STATUS_COLORS = {
  healthy: xemelgoStyle.theme.STATUS_GREEN,
  critical: xemelgoStyle.theme.STATUS_RED
};

export const CrossFunctionalMetrics = ({}) => {
  return (
    <div className={Style.container}>
      <div className={Style.title}>Metrics</div>
      <div className={Style.metrics_group_container}>
        {(MOCK_METRICS || []).map((metric) => {
          return (
            <div
              key={metric.id}
              className={Style.metrics_container}
            >
              <div className={Style.metrics_label}>{metric.label}</div>
              <div className={Style.metrics_subTitle_container}>
                <div
                  className={`${Style.metrics_subTitle} ${
                    !metric.percentChange && Style.metrics_sub_title_with_no_percentage
                  }`}
                >
                  {metric.subTitle}
                </div>
                {metric.percentChange ? (
                  <div
                    className={Style.metrics_percentage}
                    style={{
                      color: STATUS_COLORS[metric.status],
                      borderColor: STATUS_COLORS[metric.status]
                    }}
                  >
                    {!metric.direction ? null : metric.direction === "up" ? (
                      <ArrowUpwardIcon fontSize="small" />
                    ) : (
                      <ArrowDownwardIcon fontSize="small" />
                    )}
                    {metric.percentChange ? `${metric.percentChange} %` : "-"}
                  </div>
                ) : (
                  <div />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
