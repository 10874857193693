import React from "react";
import Style from "./StockRecommendations.module.css";
import PaginatedList from "../../components/paginated-list-table";

export const StockRecommendations = () => {
  const data = [
    <p className={Style.list_item_text}>
      Due to an increase in usage, we recommend to increase the minimum stock level of part{" "}
      <span className={Style.list_item_text_clickable}>7006806-120</span> (7006806-120) to 93 to avoid potential
      stockouts
    </p>,
    <p className={Style.list_item_text}>
      Due to the reduced usage of part <span className={Style.list_item_text_clickable}>78072728821</span> (28821 BELT
      4X36 R823), it's recommended to reduce maximum stock levels to 90 to reduce costs
    </p>,
    <p className={Style.list_item_text}>
      To account for the sustained increase in usage of part{" "}
      <span className={Style.list_item_text_clickable}>66261055208</span> (55208 SERIES 1000 WHEEL), it's recommended to
      convert it to consignment
    </p>,
    <p className={Style.list_item_text}>
      In order to avoid an imminent stockout, we recommend to expedite the ordering of part{" "}
      <span className={Style.list_item_text_clickable}>S-230H</span> (S-230H STEEL GRIT 1 TON)
    </p>
  ];
  return (
    <div className={Style.list_container}>
      <PaginatedList
        data={data}
        filterDisabled
        title="Stock Recommendations"
        paginatorLocation="top"
        renderItem={(eachData) => {
          return (
            <div className={Style.list_item_container}>
              {eachData}
              <button
                type="button"
                className={Style.view_button}
              >
                View
              </button>
            </div>
          );
        }}
      />
    </div>
  );
};
