import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { withRouter } from "react-router-dom";
import Style from "./GoodsReceiptPage.module.css";
import LoadingCircle from "../../components/loading/LoadingCircle";
import DisplayBanner from "../../components/display-banner/DisplayBanner";
import AlertModal from "../../components/AlertModal/AlertModal";
import ActionButtons from "../../components/ActionButtons/ActionButtons";
import SearchDropdown from "../../components/SearchDropdown/SearchDropdown";
import ListTable from "../../components/ListTable/ListTable";
import { getFormattedDate, getStackedXemelgoLogo } from "../../common/Utilities";
import { getTagsSinceTimestamp, updateDeviceState } from "../../services/get-recent-tags-service";
import { useXemelgoClient } from "../../services/xemelgo-service";
import { useAppConfigProvider, useConfigurationProvider } from "../../services/soft-cache-service";
import { analyzeItemsForItemTypeReport } from "./utils/item-type-report/itemTypeReportHelpers";
import { queryItemsFromBasicTags } from "./utils/query-items-from-basic-tags/queryItemsFromBasicTags";
import { queryItemsFromParsedTags } from "./utils/query-items-from-parsed-tag/queryItemsFromParsedTags";
import { getLocationWithDetector } from "./utils/location-detector-mapper";
import { ClearScheduledInterval, ScheduledSyncWorkflowInterval } from "./utils/scheduled-sync-workflow-interval";
import { ReactComponent as GoodsReceiptIcon } from "../../assets/icons/goods-receipt.svg";
import xemelgoStyle from "../../styles/variable";
import ScreenFrame from "../../components/ScreenFrame/ScreenFrame";
import { SOLUTION_ID } from "constants/tabConfigConstants";
import { TITLE, TABLE_COLUMN_SIZE_MAP, ROW_COUNT_ATTRIBUTE, ROW_COUNT_HEADER_LABEL } from "./data/constants";

const mainColor = xemelgoStyle.theme.INVENTORY_PRIMARY;
const secondaryColor = xemelgoStyle.theme.INVENTORY_SECONDARY;

/**
 * React component that displays a goods receipt page with a table of items and their quantities.
 * Uses XemelgoClient to query for new discovered tags and update the table accordingly.
 * @returns {JSX.Element} The goods receipt page component.
 */
const GoodsReceiptPageFeature = () => {
  const configProvider = useAppConfigProvider("inventory");
  const solutionConfigProvider = useConfigurationProvider().config?.webClient;
  const appConfig = configProvider.getValue("goodsReceipt", "object");
  const {
    tableAttributes = [
      {
        id: "qty",
        label: "Qty"
      },
      {
        id: "identifier",
        label: "UPC"
      }
    ],
    tableSections,
    apiUrl = "https://zcb5qribob.execute-api.us-west-2.amazonaws.com/dev",
    itemTypeQueryAttributes = ["identifier"],
    locationCategories,
    queryFrequencyInSeconds = 3,
    upcNumberOfCharactersToTrim = 2,
    inactivityThresholdInMinutes = 30,
    readerPowerToSet = 25,
    hasSgtinTags = false,
    enableSimpleTagGrouping = false,
    rowBackgroundColor = "transparent",
    hideConfirmButton = false,
    hideClearButton = false,
    updateReaderStateOnClear = true,
    tableTextStyle = {
      fontFamily: `"Avenir", sans-serif`,
      fontWeight: 700,
      fontSize: 15,
      color: xemelgoStyle.theme.TEXT_PRIMARY,
      backgroundColor: xemelgoStyle.theme.APP_WHITE
    },
    itemClassFilters = []
  } = appConfig;

  const [loading, setLoading] = useState(true);
  const [lastUpdatedTime, setLastUpdatedTime] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const [filterInput, setFilterInput] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [pageTitle, setPageTitle] = useState(TITLE);

  const xemelgoClient = useXemelgoClient();
  const [locationClient] = useState(xemelgoClient.getLocationClient());
  const [detectorClient] = useState(xemelgoClient.getDetectorClient());
  const [inventoryClient] = useState(xemelgoClient.getInventoryClient());
  const [itemTypeClient] = useState(xemelgoClient.getItemTypeClient());

  const [startIntervalRequestId, setStartIntervalRequestId] = useState(Date.now());
  const [itemByTag, setItemByTag] = useState({});
  const itemByTagRef = useRef(itemByTag);
  const latestTypeTimestampRef = useRef(0);
  const listTableRef = useRef(null);
  itemByTagRef.current = itemByTag;

  const [itemTypeReports, setItemTypeReports] = useState([]);
  const [clearingInProgress, setClearingInProgress] = useState(false);

  const itemCount = useMemo(() => {
    return Object.keys(itemByTag).length;
  }, [itemByTag]);

  const headers = useMemo(() => {
    return tableSections || tableAttributes;
  }, [tableSections, tableAttributes]);

  /**
   * Callback hook that is used to query for new discovered RFID tags.
   *
   *  The getTagsSinceTimestamp function is used to retrieve the tags, while the itemByTagRef.current object
   *  is used to filter out any tags that have already been queried.
   */
  const queryForNewDiscoveredTags = useCallback(
    async (detectorSerial, since) => {
      const allTags = await getTagsSinceTimestamp(apiUrl, detectorSerial, since);
      const alreadyQueriedTags = Object.keys({ ...itemByTagRef.current });

      const newlyDiscoveredTags = allTags.filter((tagObject) => {
        return !alreadyQueriedTags.includes(tagObject.Name);
      });

      return newlyDiscoveredTags;
    },
    [itemByTag]
  );

  useEffect(() => {
    if (
      solutionConfigProvider &&
      solutionConfigProvider.solutionOptions &&
      solutionConfigProvider.solutionOptions.length > 0
    ) {
      for (const option of solutionConfigProvider.solutionOptions) {
        if (option.id === SOLUTION_ID.GOODS_RECEIPT) {
          setPageTitle(option.title);
          break;
        }
      }
    }
  }, [configProvider]);

  /**
   * Effect hook for changes in the locationClient or detectorClient
   *
   * Fetches location and detector data from the server and updates the `locationOptions` state with the
   * retrieved data. The `Promise.all` function is used to wait for both promises to resolve before
   * executing the callback function, while the `getLocationWithDetector` function is used to generate
   * the location options with detector information.
   */
  useEffect(() => {
    const newLocationsPromise = locationClient.getLocationsOfCategory(locationCategories);
    const newDetectorsPromise = detectorClient.listDetectors();

    Promise.all([newLocationsPromise, newDetectorsPromise]).then(([locations = [], detectors = []]) => {
      const options = getLocationWithDetector(locations, detectors);
      setLocationOptions(options);
      setLoading(false);
    });
  }, [locationClient, detectorClient]);

  /**
   * Function used to query for items from RFID tags.
   *
   * The function determines whether the tags are SGTIN tags, and calls the appropriate API function to retrieve the items.
   */
  const queryItemsFromTags = useCallback(
    async (discoveredTagData) => {
      if (enableSimpleTagGrouping) {
        const firstSeenTimestamp = Date.now();
        return discoveredTagData.map((tag) => {
          return {
            vid: tag.Name,
            type: { identifier: tag.Name, id: tag.Name },
            firstSeenTimestamp
          };
        });
      }
      if (hasSgtinTags) {
        return await queryItemsFromParsedTags(
          discoveredTagData,
          itemByTagRef.current,
          itemTypeClient,
          itemTypeQueryAttributes,
          upcNumberOfCharactersToTrim
        );
      }
      return await queryItemsFromBasicTags(
        discoveredTagData,
        inventoryClient,
        itemTypeQueryAttributes,
        itemClassFilters
      );
    },
    [itemTypeReports]
  );

  /**
   * Effect hook for changes in the startIntervalRequestId or selectedLocation variables.
   *
   * Sets up a recurring workflow to query for new discovered tags and items, and also sets
   * up an inactive interval to display a modal if the user is inactive for a certain amount of time.
   */
  useEffect(() => {
    let cancelled = false;
    const cancelCb = () => {
      cancelled = true;
    };

    if (!startIntervalRequestId || !selectedLocation) {
      return cancelCb;
    }

    const { detectorSerial } = selectedLocation;
    const now = Date.now();

    // schedule for next workflow
    const intervalId = ScheduledSyncWorkflowInterval(async () => {
      const discoveredTags = await queryForNewDiscoveredTags(detectorSerial, now);
      try {
        const newItemByTag = await queryItemsFromTags(discoveredTags);
        if (!cancelled) {
          const newItemByTagMap = {
            ...newItemByTag,
            ...(itemByTagRef?.current || {})
          };
          const reports = analyzeItemsForItemTypeReport(Object.values(newItemByTagMap));
          const newLatestTimestamp = reports[reports.length - 1]?.lastUpdatedTimestamp;
          setItemTypeReports(reports);
          setItemByTag(newItemByTagMap);

          if (listTableRef.current && newLatestTimestamp > latestTypeTimestampRef.current) {
            latestTypeTimestampRef.current = newLatestTimestamp;
            listTableRef.current.scrollToItem(reports.length - 1);
          }
        }
      } finally {
        if (cancelled) {
          ClearScheduledInterval(intervalId);
        }
      }
    }, 1000 * queryFrequencyInSeconds);

    if (inactivityThresholdInMinutes <= 0) {
      // completely disable the inactivity check.
      return () => {
        cancelCb();
        ClearScheduledInterval(intervalId);
      };
    }
    // inactivity check is enabled.
    const inactiveIntervalId = setInterval(() => {
      if (!cancelled) {
        setShowInactivityModal(true);
      }
    }, 60000 * inactivityThresholdInMinutes);

    return () => {
      cancelCb();
      ClearScheduledInterval(intervalId);
      clearInterval(inactiveIntervalId);
    };
  }, [startIntervalRequestId, selectedLocation]);

  /**
   * Effect hook for changes in the selectedLocation
   *
   * Sets the `startIntervalRequestId` and `showSuccessBanner` states whenever the `selectedLocation`
   * state changes. The `setShowSuccessBanner` function is used to hide the success banner.
   */
  useEffect(() => {
    if (selectedLocation) {
      setStartIntervalRequestId(Date.now());
      setShowSuccessBanner(false);
    }
  }, [selectedLocation]);

  /**
   * Effect hook for changes in the startIntervalRequestId
   *
   * Updates the reader state whenever the `startIntervalRequestId` state changes, and
   * `startIntervalRequestId` has a value.
   */
  useEffect(() => {
    if (startIntervalRequestId && updateReaderStateOnClear) {
      updateReaderState();
    }
  }, [startIntervalRequestId, updateReaderStateOnClear]);

  /**
   * Effect hook for changes in the showInactivityModal or showConfirmModal
   *
   * Clears the `startIntervalRequestId` state whenever the `showInactivityModal` or `showConfirmModal`
   * states have changed and one (or both) are true.
   */
  useEffect(() => {
    if (showInactivityModal || showConfirmModal) {
      setStartIntervalRequestId(undefined);
    }
  }, [showInactivityModal, showConfirmModal]);

  /**
   * Callback hook that is used to update the state of an RFID reader.
   *
   * The function checks the selectedLocation state to ensure that it is not null, and updates the state
   * of the RFID reader using the updateDeviceState function.
   */
  const updateReaderState = useCallback(async () => {
    if (!selectedLocation) {
      return;
    }
    const { detectorSerial, detectorVendor } = selectedLocation;
    await updateDeviceState(apiUrl, detectorSerial, detectorVendor, "restart", readerPowerToSet);
  }, [selectedLocation]);

  /**
   * Hook used to clear the state of the page.
   *
   * The `setClearingInProgress`, `setStartIntervalRequestId`, `setItemByTag`, `setItemTypeReports`,
   * `setShowInactivityModal`, and `setShowConfirmModal` functions are used to clear the various states,
   * while the `setTimeout` function is used to delay the re-initialization of the `startIntervalRequestId` state.
   */
  const onClear = useCallback(() => {
    setClearingInProgress(true);
    setStartIntervalRequestId(undefined);
    setItemByTag({});
    setItemTypeReports([]);
    setShowInactivityModal(false);
    setShowConfirmModal(false);
    latestTypeTimestampRef.current = 0;

    // TODO: potentially memory leak warning
    setTimeout(() => {
      setClearingInProgress(false);
      setStartIntervalRequestId(Date.now());
    }, 1000);
  }, [clearingInProgress]);

  /**
   * Function used to verify a goods receipt.
   *
   * The function updates the lastUpdatedTime state, shows the success banner, and
   * clears the state of the page.
   */
  const onVerify = () => {
    setLastUpdatedTime(Date.now());
    setShowSuccessBanner(true);
    onClear();
  };

  /**
   * Function used to filter rows in the table.
   *
   * The function generates an array of keys to check for each row, and returns true if
   * the row includes the search input and false otherwise.
   */
  const filterFunction = (input, each) => {
    const lowerCaseInput = input.toLowerCase();
    let match = false;
    let keysToCheckList = [];
    if (tableSections) {
      keysToCheckList = tableSections.reduce((accumulator, section) => {
        const { attributes = [] } = section;
        const currentAttributes = [];
        attributes.forEach((attribute) => {
          if (attribute.type !== "image") {
            currentAttributes.push(attribute.id);
          }
        });
        return [...accumulator, ...currentAttributes];
      }, []);
    } else {
      keysToCheckList = tableAttributes.map((attribute) => {
        return attribute.id;
      });
    }

    keysToCheckList.forEach((eachKey) => {
      let eachValue = each[eachKey];
      if (typeof eachValue !== "string") {
        eachValue = String(eachValue);
      }
      if (eachValue && eachValue.toLowerCase().includes(lowerCaseInput)) {
        match = true;
      }
    });

    return match;
  };

  const filteredReports = useMemo(() => {
    if (itemTypeReports && filterFunction) {
      return itemTypeReports.filter((row) => {
        return filterFunction(filterInput, row);
      });
    }
    return [];
  }, [filterFunction, itemTypeReports]);

  /**
   * Function used to render the header row of the items table.
   *
   * The function maps over the tableAttributes array to generate an array of TableCell components,
   * and sets the key, style, and content props of each TableCell component. The array of TableCell
   * components is then returned as the children of a TableRow component.
   */
  const renderHeader = (header) => {
    const { size } = header;
    const flexSize = TABLE_COLUMN_SIZE_MAP[size] || TABLE_COLUMN_SIZE_MAP.default;
    return (
      <li
        key={header?.label}
        style={{
          flex: header?.label === ROW_COUNT_HEADER_LABEL ? 1 : flexSize
        }}
      >
        {header?.label}
      </li>
    );
  };

  const renderRow = (row, index) => {
    return (
      <div
        key={row.id}
        className={Style.row_container}
        style={{
          backgroundColor: rowBackgroundColor
        }}
      >
        {tableSections
          ? renderSections(row, index)
          : tableAttributes.map((attribute) => {
              let value = row[attribute.id];
              if (attribute.id === ROW_COUNT_ATTRIBUTE) {
                return (
                  <div
                    style={{
                      ...tableTextStyle,
                      backgroundColor: rowBackgroundColor,
                      flex: 1
                    }}
                  >
                    {index + 1}
                  </div>
                );
              }
              if (attribute.label === "Color" && row.color_desc_ts) {
                value = `${row[attribute.id]}-${row.color_desc_ts}`;
              }
              if (attribute.label === "Qty") {
                value = `${row.epcs.length}`;
              }
              return (
                <div
                  key={`${row.id}-${attribute.id}`}
                  style={{
                    ...tableTextStyle,
                    backgroundColor: rowBackgroundColor,
                    flex: 1
                  }}
                >
                  {value || "-"}
                </div>
              );
            })}
      </div>
    );
  };

  const renderSections = (row, index) => {
    return tableSections.map((section) => {
      const { attributes = [], size } = section;
      const flexSize = TABLE_COLUMN_SIZE_MAP[size] || TABLE_COLUMN_SIZE_MAP.default;
      return (
        <div
          style={{
            ...tableTextStyle,
            backgroundColor: rowBackgroundColor,
            flex: flexSize
          }}
          className={Style.section_container}
          key={`${row.id}-${section.label}`}
        >
          <div className={Style.section_content_container}>
            {attributes.map((attribute) => {
              const { id, label, type } = attribute;
              let value = row[id];
              if (id === ROW_COUNT_ATTRIBUTE) {
                return (
                  <div
                    style={{
                      ...tableTextStyle,
                      backgroundColor: rowBackgroundColor,
                      flex: 1
                    }}
                  >
                    {index + 1}
                  </div>
                );
              }
              if (label === "Color" && row.color_desc_ts) {
                value = `${row[id]}-${row.color_desc_ts}`;
              }
              if (id === "qty") {
                value = `${row.epcs.length}`;
              }
              if (type == "image") {
                return (
                  <img
                    key={`${row.id}-${row[id]}`}
                    src={row[id] || getStackedXemelgoLogo("dark")}
                    height="100px"
                    width="100px"
                    className={Style.row_image}
                  />
                );
              }
              return (
                <div
                  style={{ padding: !label && !value ? "1em 0" : 0 }}
                  className={Style.section_text_container}
                  key={`${row.id}-${row[id]}`}
                >
                  {label && <p className={Style.section_label}>{`${label}:`}</p>}
                  <p>{value}</p>
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  };

  if (loading) {
    return <LoadingCircle />;
  }

  return (
    <>
      {showSuccessBanner && (
        <DisplayBanner
          bannerMessage="Items verified successfully"
          onCloseBanner={() => {
            setShowSuccessBanner(false);
          }}
        />
      )}
      <AlertModal
        show={showConfirmModal}
        title="Verify Items"
        message="Are you sure you would like to verify the the list of received items?"
        cancelButtonText="Cancel"
        onCancel={() => {
          setShowConfirmModal(false);
          setStartIntervalRequestId(Date.now());
        }}
        confirmButtonText="Confirm"
        onConfirm={() => {
          onVerify();
        }}
      />
      <AlertModal
        show={showInactivityModal}
        title="Still there?"
        message="We have paused scanning for tags due to inactivity, would you like to continue?"
        cancelButtonText="No"
        onCancel={() => {
          onClear();
          setSelectedLocation(null);
        }}
        confirmButtonText="Yes"
        onConfirm={() => {
          setShowInactivityModal(false);
          setStartIntervalRequestId(Date.now());
        }}
      />
      <ScreenFrame
        title={pageTitle}
        color={mainColor}
        secondaryColor={secondaryColor}
        titleIconComponent={
          <GoodsReceiptIcon
            width={25}
            height={25}
            style={{ color: mainColor }}
          />
        }
        titleRightComponent={
          <div className={Style.last_updated}>
            <p>Last Updated:</p>
            <p>{lastUpdatedTime ? getFormattedDate(lastUpdatedTime, "MMMM Do YYYY, h:mm a") : "-"}</p>
          </div>
        }
      >
        <div className={Style.main_content}>
          <div className={Style.main_content_info_and_actions}>
            <div className={Style.dropdown_section}>
              <p className={Style.dropdown_title}>Read Point: </p>
              <div>
                <SearchDropdown
                  showIcon
                  withoutOptionFilter
                  selectedItem={selectedLocation || {}}
                  options={locationOptions}
                  onItemSelected={(item) => {
                    setSelectedLocation(item);
                  }}
                  textareaClassName={Style.dropdown}
                  iconContainerClassName={Style.dropdown_icon}
                />
              </div>
            </div>
            <div className={Style.status_section}>
              {selectedLocation && (
                <div className={Style.status_section_content}>
                  <div className={Style.status_text}>Reading in Progress...</div>
                  <div className={Style.status_count_section}>
                    <p className={Style.status_count_label}>Items Read: </p>
                    <p className={Style.status_count_value}>{itemCount}</p>
                  </div>
                </div>
              )}
            </div>

            <ActionButtons
              cancelDisabled={clearingInProgress}
              cancelVisible={!hideClearButton}
              cancelButtonStyle={Style.clear_button}
              cancelButtonText="Clear"
              onCancel={() => {
                onClear();
              }}
              confirmDisabled={!itemCount}
              confirmVisible={!hideConfirmButton}
              confirmButtonStyle={Style.verify_button}
              confirmButtonText="Verify"
              onConfirm={() => {
                setShowConfirmModal(true);
              }}
            />
          </div>
          <div>
            <div className={Style.filter_bar_container}>
              <span className={`fa fa-search ${Style.filter_bar_icon}`} />
              <input
                onChange={({ currentTarget }) => {
                  setFilterInput(currentTarget.value);
                }}
                value={filterInput}
                className={Style.filter_bar}
                placeholder="Type to Filter"
              />
            </div>
            <div className={Style.table}>
              <ListTable
                header={headers}
                headerContainerClassName={Style.header}
                data={filteredReports}
                renderHeader={renderHeader}
                renderItem={renderRow}
                renderEmptyList={() => {
                  return <div className={Style.empty_items_container}>No Matching Results</div>;
                }}
                ref={listTableRef}
              />
            </div>
          </div>
        </div>
      </ScreenFrame>
    </>
  );
};

export default withRouter(GoodsReceiptPageFeature);
