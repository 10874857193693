import React, { useEffect, useState } from "react";
import { TableSortLabel } from "@material-ui/core";

const stableSort = (dataToSort, getOrder) => {
  const sortedData = [...(dataToSort || [])];
  sortedData.sort((a, b) => {
    const order = getOrder(a, b);
    // not in the same order
    if (order !== 0) {
      return order;
    }
    return 0;
  });
  return sortedData;
};

/**
 * This function compare order of the two item
 * @param {RecordType} a
 * @param {RecordType} b
 * @param {string} orderBy
 * @returns
 */
const compareOrder = (a, b, orderBy) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(a[orderBy]) || isNaN(b[orderBy])) {
    return String(a[orderBy] || "").localeCompare(b[orderBy] || "", "en", {
      numeric: true,
      sensitivity: "base"
    });
  }

  return Number(b[orderBy]) - Number(a[orderBy]);
};

const getOrder = (order, orderBy) => {
  if (order === "desc") {
    return (a, b) => {
      return compareOrder(a, b, orderBy);
    };
  }
  return (a, b) => {
    return compareOrder(b, a, orderBy);
  };
};

export const useSortableHeader = (defaultOrder, defaultOrderProperty) => {
  const [order, setOrder] = useState(defaultOrder || "asc");
  const [orderBy, setOrderBy] = useState(defaultOrderProperty || "");

  useEffect(() => {
    setOrder(defaultOrder);
    setOrderBy(defaultOrderProperty);
  }, [defaultOrder, defaultOrderProperty]);

  const handleRequestSort = (property) => {
    return () => {
      const isDesc = orderBy === property && order === "desc";
      setOrder(isDesc ? "asc" : "desc");
      setOrderBy(property);
    };
  };

  /**
   *
   * @param {RecordType[]} records
   * @param {'asc' | 'desc'} order
   * @param {string} orderBy
   * @returns {RecordType[]}
   */
  const getSortedData = (records, replacedOrderBy) => {
    return stableSort(records, getOrder(order, replacedOrderBy || orderBy)).slice(0, records.length);
  };

  const getSortableHeader = (headerInfo, elementProps) => {
    const headerContainerClass = elementProps?.headerContainerClass;
    // eslint-disable-next-line no-param-reassign
    delete elementProps?.headerContainerClass;
    return (
      <div
        key={headerInfo.id}
        style={{ flex: 1 }}
        className={headerContainerClass}
      >
        <TableSortLabel
          active={orderBy === headerInfo.id}
          direction={order}
          onClick={handleRequestSort(headerInfo.id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...elementProps}
        >
          {headerInfo.label}
        </TableSortLabel>
      </div>
    );
  };

  return {
    order,
    orderBy,
    getSortedData,
    getSortableHeader
  };
};
