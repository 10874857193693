import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { getFormattedDate } from "../../../../common/utils";
import { exportCsv } from "../../../../common/Utilities";
import Style from "./OrderTrackPageListTable.module.css";
import useSortableHeader from "../../../../hooks/use-sortable-header";
import PaginatedList from "../../../../components/PaginatedList/PaginatedList";
import OptionalLink from "../../../../components/optional-link/OptionalLink";
import { SORT_DIRECTION } from "../../data/constants";
import { VALUE_TYPE_MAP } from "./data/constants";

const getStatusRenderComponent = (statusList) => {
  return (
    <div className={`${Style.flex_row} ${Style.status_container}`}>
      {statusList.map((eachStatus) => {
        return (
          <div
            key={eachStatus.title}
            className={Style.status}
            style={{ backgroundColor: eachStatus.color }}
          >
            {eachStatus.title}
          </div>
        );
      })}
    </div>
  );
};

export const getValueByType = (value, type, timeFormat, csvMode) => {
  switch (type) {
    case VALUE_TYPE_MAP.boolean:
      return typeof value !== "undefined" ? (value ? "Yes" : "No") : "-";
    case VALUE_TYPE_MAP.dateTime:
      return value ? getFormattedDate(value, timeFormat || "hh:mm A MMM Do") : "-";
    case VALUE_TYPE_MAP.date:
      return value ? getFormattedDate(value, timeFormat || "MMM Do") : "-";
    case VALUE_TYPE_MAP.status:
      return csvMode
        ? value.reduce((accumulator, eachStatus) => {
            return `${accumulator && `${accumulator}, `}${eachStatus.title}`;
          }, "")
        : getStatusRenderComponent(value);
    default:
      return value || "-";
  }
};

export const OrderTrackPageListTable = ({
  headers,
  dataList,
  getTitleURLCallbackFn,
  isLoading,
  sortIdReplacementMap,
  getExportCsvFn,
  csvFileName,
  columnSortDisabled,
  renderHeader,
  renderItem,
  headerRowContainerClassName,
  headerContainerClassName,
  itemContainerClassName,
  ...restProps
}) => {
  const { defaultOrderBy, defaultOrderDirection } = useMemo(() => {
    if (columnSortDisabled) {
      return {};
    }

    const defaultHeader =
      headers.find((eachHeader) => {
        return eachHeader.defaultSort;
      }) ||
      headers[0] ||
      {};

    return {
      defaultOrderBy: defaultHeader.id,
      defaultOrderDirection: defaultHeader.defaultDirection || SORT_DIRECTION.ascending
    };
  }, [headers, columnSortDisabled]);

  const { order, orderBy, getSortedData, getSortableHeader } = useSortableHeader(defaultOrderDirection, defaultOrderBy);

  const sortedDataList = useMemo(() => {
    return columnSortDisabled ? dataList : getSortedData(dataList, sortIdReplacementMap[orderBy]);
  }, [dataList, order, orderBy, sortIdReplacementMap, columnSortDisabled]);

  const buildTrackPageListTableCSVDataFn = (dataArray, headerArray) => {
    return dataArray.map((eachData) => {
      return {
        ...headerArray.reduce((accumulator, { id, label, type, timeFormat }) => {
          accumulator[label] = getValueByType(eachData[id], type, timeFormat, true);
          return accumulator;
        }, {})
      };
    });
  };

  useEffect(() => {
    const csvData = buildTrackPageListTableCSVDataFn(sortedDataList, headers);
    getExportCsvFn(() => {
      return exportCsv(
        csvData,
        {
          header: headers.map((eachHeader) => {
            return eachHeader.label;
          }),
          cellDates: true
        },
        `${csvFileName}.csv`
      );
    });
  }, [headers, sortedDataList]);

  const LoadingComponent = () => {
    return (
      <div className={`${Style.flex_row} ${Style.table_item}`}>
        <div className={`${Style.flex_row} ${Style.table_column}`}>
          <Skeleton
            containerClassName={Style.loading_icon}
            height={24}
          />
        </div>
        <div className={`${Style.flex_row} ${Style.table_column}`}>
          <Skeleton
            containerClassName={Style.loading_icon}
            height={24}
          />
        </div>
        <div className={`${Style.flex_row} ${Style.table_column}`}>
          <Skeleton
            containerClassName={Style.loading_icon}
            height={24}
          />
        </div>
      </div>
    );
  };

  return (
    <PaginatedList
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      data={sortedDataList}
      header={headers}
      headerContainerClassName={`${Style.table_header} ${headerRowContainerClassName}`}
      renderEmptyList={() => {
        if (isLoading) {
          return [...Array(10)].map((_, index) => {
            return <LoadingComponent key={index} />;
          });
        }
        return (
          <div className={`${Style.flex_row} ${Style.empty_list_container}`}>
            <p className={Style.empty_list_text}>No Data Available</p>
          </div>
        );
      }}
      renderHeader={
        renderHeader ||
        ((eachHeader) => {
          return getSortableHeader(eachHeader, {
            headerContainerClass: `${Style.flex_row} ${Style.table_column} ${headerContainerClassName}`,
            className: Style.table_header_text,
            disabled: columnSortDisabled
          });
        })
      }
      renderItem={(eachItem) => {
        if (isLoading) {
          return <LoadingComponent />;
        }

        if (renderItem) {
          return renderItem(eachItem);
        }

        return (
          <div
            key={eachItem.identifier}
            className={`${Style.flex_row} ${Style.table_item}`}
          >
            {headers.map((eachHeader, subIndex) => {
              return (
                <OptionalLink
                  key={`${eachItem[eachHeader.id]}${subIndex}`}
                  className={`${Style.flex_row} ${Style.table_column} ${itemContainerClassName}`}
                  active={!!eachHeader.isNavLink}
                  route={getTitleURLCallbackFn(eachItem)}
                >
                  <div
                    className={`${Style.table_item_text} ${eachHeader.isNavLink && Style.table_item_text_clickable}`}
                  >
                    {getValueByType(eachItem[eachHeader.id], eachHeader.type, eachHeader.timeFormat)}
                  </div>
                </OptionalLink>
              );
            })}
          </div>
        );
      }}
    />
  );
};

OrderTrackPageListTable.defaultProps = {
  dataList: [],
  headers: [],
  getTitleURLCallbackFn: () => {
    return "";
  },
  isLoading: false,
  sortIdReplacementMap: {},
  getExportCsvFn: () => {},
  csvFileName: "",
  columnSortDisabled: false,
  renderHeader: null,
  renderItem: null,
  headerRowContainerClassName: "",
  headerContainerClassName: "",
  itemContainerClassName: ""
};

OrderTrackPageListTable.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  headers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string.isRequired })),
  getTitleURLCallbackFn: PropTypes.func,
  isLoading: PropTypes.bool,
  sortIdReplacementMap: PropTypes.object,
  getExportCsvFn: PropTypes.func,
  csvFileName: PropTypes.string,
  columnSortDisabled: PropTypes.bool,
  renderHeader: PropTypes.func,
  renderItem: PropTypes.func,
  headerRowContainerClassName: PropTypes.string,
  headerContainerClassName: PropTypes.string,
  itemContainerClassName: PropTypes.string
};
