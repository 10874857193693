import React from "react";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import PropTypes from "prop-types";
import Style from "./AddInventoryV2Components.module.css";

const InventoryModalHeader = ({ onClose, error, headerMessage, titleText }) => {
  return (
    <div className={Style.title_container}>
      <div className={Style.inv_title_container}>
        <div className={Style.header_title_icon_container}>
          <img
            src={require("../../img/inventory_icon_blue.png")}
            width="50px"
            height="40px"
          />
        </div>
        <p className={Style.header_title}>{titleText}</p>
        <p className={`${Style.header_additional_message} ${error ? Style.error : Style.success}`}>{headerMessage}</p>
      </div>
      <div className={Style.close_button}>
        <div
          onClick={onClose}
          className={Style.close_icon}
        >
          <CloseRoundedIcon />
        </div>
      </div>
    </div>
  );
};

InventoryModalHeader.defaultProps = {
  error: false,
  headerMessage: "",
  titleText: "Add Item"
};

InventoryModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  error: PropTypes.bool,
  headerMessage: PropTypes.string,
  titleText: PropTypes.string
};

export default InventoryModalHeader;
